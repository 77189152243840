*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

:root{
  --primary-color:#e1777d;
  --button-back-color:#e1777d;
  --nav-footer-bcolor:#1c4d6b;
  --secondary-color:#f3f5f8;
  --view-all-color:#535f8d;
  --crd-head-font:16px;
  --para-font:12px;
  --explore-font: 14px;
}


/* Upload doc img hight */
.up-doc-img{
  height: 340px !important;
}
/* search filter */

.top-filter-item{
  background-color: white;
}

.fullscreen-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 600px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000; /* Optional: Add a background color for the surrounding area */
}

.fullscreen-image {
  max-width: 100%;
  max-height: 100%;
}

/* search filter end*/
/* login css */
.login-container {
margin-top: 50px;
}
.login-container .row {
justify-content: center;
}
.login-form {
background-color: #f3f5f8;
padding: 40px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.login-form h3 {
text-align: center;
margin-bottom: 30px;
color:dimgrey
}
.form-control {
border-radius: 7px;
}
.btn {
border-radius: 5px;
/* background-color: var(--button-back-color); */
}
.log-btn:hover{
border-radius: 20px;
padding: 7px 60px;
background-color: var(--primary-color);
}
.sign-btn {
border-radius: 20px;
border: 2px solid var(--button-back-color);
padding: 7px 60px;
color: var(--button-back-color);

}
.sign-btn:hover {
color:white;
background-color: var(--button-back-color);
}
/* login css end */


/* Choose-category css */
.choose-category-container {
margin-top: 100px;
}
.choose-category-container .row {
justify-content: center;
}
.choose-category {
background-color: #f3f5f8;
padding: 40px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.choose-category h3 {
text-align: center;
margin-bottom: 30px;
color:dimgrey
}
/* .form-control {
border-radius: 5px;
} */
.btn {
/* border-radius: 20px; */
/* padding: 7px 60px; */
background-color: var(--button-back-color);
}
.log-btn:hover{
border-radius: 20px;
padding: 7px 60px;
background-color: var(--primary-color);
}
.sign-btn {
border-radius: 20px;
border: 2px solid var(--button-back-color);
padding: 7px 60px;
color: var(--button-back-color);

}
.sign-btn:hover {
color:white;
}

/* Choose-category css end */

/* main navbar home */
.navbar-expand-lg{
  height: 76px;
  background-color: var(--nav-footer-bcolor);
}
.left-navigation img{
  height: 60px;
  width: 142px;
 
}
.nav-buy:hover{
color:white !important;
}
.buy-li a:hover{
color: black !important;
}
.navbar-toggler{
color: var(--primary-color);
}
.nav-link{
  color: black;
}
.nav-item a{
  padding: 8px 15px !important;
}
.nav-item a:hover{
color: white;
}
.profile-img{
height: 30px;
margin-right: 7px;
}
/* Custom CSS for dropdown hover effect */
.profile-drop:hover .profile-drop-menu {
display: block;
position: absolute;
  left: -160px;
  top: 36px;
}
.prof-img{
height: 70px;
margin-right: 7px;
}
.pro-details p{
margin-bottom: 0px !important;
}



.post-prop-btn{
  background-color: var(--button-back-color);
  color: white;
  font-weight: bold;
}
.post-prop-btn:hover{
background-color: var(--button-back-color);
color: white;
font-weight: bold;
}
/* .login-btn{
  padding: 5px 20px;
}
.login-btn:hover{
color: white;
} */
/* live work */

.live-search{
background-color: var(--primary-color);
color: white;
font-weight: bold;
padding: 11px 7px;
/* margin-left: 10px; */
}
.live-search:hover{
background-color: var(--primary-color);
color: white;
}

.live-img img{
  width: 100%;
  margin-top: 45px;
}
.live-text h5{
  font-weight: bold;
  padding-top: 40px;
 text-align: center;
}
.buy-prop-type{
padding: 10px;
/* margin-left: 10px; */
}
/* Custom styles */
#propertyTabsContent {
  padding: 20px;
  margin-left: 22%;
  width: 60%;
  background-color: rgba(255, 255, 255, 0.303);
}
.lifestyle-text h5{
  font-weight: bold;
  margin-top: 90px;
  margin-bottom: 40px;
}
.lifestyle-crd{
  border: none;
}
.lifestyle-crd-bd{
  border: none;
  /* margin-right: 20px; */
box-shadow: 0px 0px 7px -3px rgba(0,0,0,0.75);
}
.lifestyle-crd-bd:hover{
  /* margin-right: 20px; */
box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.75);
}
.lifestyle-crd-heading{
  font-size: var(--crd-head-font);
  font-weight: bold;
  padding-top: 20px;
}
.lifestyle-para{
  font-size: var(--para-font);
}
.exp-all{
  font-size: var(--explore-font);
}
/* popular owner proerties css */
.pop-owner-prop{
  margin-bottom: 30px;
}
/* .pop-owner-prop-crd{
  padding-right: 0px !important;
} */
.bhk{
  font-size: var(--para-font);
  color: gray;
}
.view-btn-pop{
  font-size: var(--para-font);
 background-color: var(--button-back-color);
 color: white;
}
.view-btn-pop:hover{
 background-color: var(--nav-footer-bcolor);
 color: white;
}
.pop-detail-info{
  font-size: var(--para-font);
  color: gray;
}
.Featured-body{
 padding: 0px  !important;
  box-shadow: 0px 0px 7px -3px rgba(0,0,0,0.75);
}
.featured-img img{
 
  height: 200px;
}
.Sell-badge{
  position: absolute;
  left: 20px;
  top: -76px;
  padding: 6px  14px;
}
.Featured-info span{
  font-size: 13px !important;
  margin-right:6% !important;
}
.featured-right{
  margin-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}
/* insights css */
.insights-tool-main{
  background-color: var(--secondary-color);
  height: 600px;
}
.insight-bd{
  background-color: #6f9abd;
  padding: 15px !important;
  border-radius: 10px;
}
.insight-bi-bd{
  background-color: #d4cdc5;
  padding: 0px !important;
  border-radius: 10px;
  
}
.insight-left{
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
}
.insight-crd{
  border: none;
  background-color: var(--secondary-color);
  
}
.insight-ai-img{
 
}
.insight-bi-img{
  height: 150px;
  
}
@media(min-width:992px){
  .insight-ai-img{
    height: 234px;
    position: absolute;
    left: 43%;
    bottom: -7%;
  }
  .insight-bi-img{
    position: absolute;
    left: 52%;
    height: 229px;
    bottom: -11px;
  }
  .insight-left{
    padding-top: 29px;
    padding-left: 45px;
    padding-bottom: 45px;
  }
  .insights-tool-main{
    background-color: var(--secondary-color);
    height: 300px;
  }
}
/* featured css */
.featured-info{
  font-size: var(--para-font);
  color: gray;
}
.featured-body{
  border: none;
  box-shadow: 0px 0px 7px -3px rgba(0,0,0,0.75);
}
/* people living css */

.main-people-living {
  height: 434px;
}
.people-living{
  background-color: var(--secondary-color);
  height: 218px;
  margin-bottom: 50px;
}
.people-living-text h5{

  font-weight: bold;
}
.people-living-text p{
  font-size: var(--explore-font);
}
.people-living .card{
  border: none;
  --bs-card-bg: none !important;
}
.people-living .card-body{
  box-shadow: 0px 0px 7px -3px rgba(0,0,0,0.75);
  background-color: white;
  border-radius: 10px;
}
.people-living-text a{
  text-decoration: none;
  color: var(--view-all-color);
  font-size: var(--explore-font);
}
.local-society{
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}
.testimonial-img{
  height: 60px;
  margin-right: 20px;
}
.card-text-testimonial{
  font-style: italic;
  font-size: var(--explore-font);
}
/* real estate guide */
.lifestyle-text h5{
  font-weight: bold;
  margin-top: 90px;
  margin-bottom: 40px;
}
.lifestyle-crd{
  border: none;
}
.lifestyle-crd-bd{
  border: none;
  /* margin-right: 20px; */
box-shadow: 0px 0px 7px -3px rgba(0,0,0,0.75);
}
.lifestyle-crd-bd:hover{
  /* margin-right: 20px; */
box-shadow: 0px 0px 7px -1px rgba(0,0,0,0.75);
}
.lifestyle-crd-heading{
  font-size: var(--crd-head-font);
  font-weight: bold;
  padding-top: 20px;
}
.lifestyle-para{
  font-size: var(--para-font);
}
.guide-img{
  background-color: #edf3fb;
  border-radius:50%;
  padding: 13px;
  height:70px; 
  width:70px;
}
/* download app css */
.left-download h5{
font-weight: bold;

}
.left-download p{

font-size: var(--explore-font);
}
.left-download h5 span{
color: var(--primary-color);
}
.google-play-img{
  height: 75px;
  width: 139px;
}
.app-play-img{
 
  height: 50px;
  width: 142px;
  margin-top: 0px;
}
.scan-main span{
  font-size: var(--explore-font);
  width: 30%;
  margin-top: 10px;
  margin-left: 10px;
}
.scan-img{
  height: 159px;
}
/* footer css */
footer{
  background-color: var(--nav-footer-bcolor);
  padding-bottom: 15px;
  color: white;
}
.logo-side{
  height: 90px;
 margin-bottom: 20px;
  padding: 5px;
 
}
.foot-ul li a{
  text-decoration: none;
  color: gray;
  font-size: var(--explore-font);
}
.foot-ul li{
  list-style: none;
}
.para-text{
  color: gray;
  font-size: var(--explore-font);
}
.head-text{
  font-size: var(--explore-font);
}
.hr{
  color: rgb(165, 164, 164);
  margin-top: 30px;
  padding-bottom: 15px;
}
.social-media span{
  font-size: var(--explore-font);
  margin-right: 70px;
}
.social-media a{
  color: white;
  font-size: var(--explore-font);
  margin-right: 20px;
}
.copy{
  font-size: var(--explore-font);
}


/* profile  page css */
.left-profile{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.20);
  height: 330px;
  
}
.right-profile{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.20);
  padding: 20px;
  border: 1px solid var(--primary-color);
}
.left-profile p{
  margin-bottom: 5px ;
}
.profile-name{
  font-size: var(--explore-font);
  font-weight: bold;
}
.fr-member{
  color: white;
  background-color: var(--button-back-color);
  width: 96px;
  margin: auto;
  font-size: 12px;
  border-radius: 13px;
  font-weight: bold;
  padding: 4px;

}
.profile-buyer{
  font-size: var(--explore-font);
}
.profile-join-dt{
  font-size: var(--explore-font);
}
.profile-email-name{
  font-size: var(--explore-font);
 margin-bottom: 0px !important;
}
.profile-email{
  font-size: var(--explore-font);
  font-weight: bold;
  margin-bottom: 8px !important;
}
.profile-phone-name{
  font-size: var(--explore-font);
 margin-bottom: 0px !important;
}
.profile-phone{
  font-size: var(--explore-font);
  font-weight: bold;
  margin-bottom: 8px !important;
}
.profile-address-name{
  font-size: var(--explore-font);
 margin-bottom: 0px !important;
}
.profile-address{
  font-size: var(--explore-font);
  font-weight: bold;
  margin-bottom: 8px !important;
}
.profile-nav-tab{
  background-color: #e9c681;
}
.adhar-img img{
  border: 3px solid var(--button-back-color);
  padding: 10px;
  width: 270px;
}

.upload-adhar:hover{
  background-color: var(--button-back-color);
}

/* preview invoice btn css */
.btn-send:hover{
 background-color: var(--button-back-color);
 color: white;
}
.btn-print:hover{
  background-color: var(--button-back-color);
  color: white;
 }
 .btn-download:hover{
  background-color: var(--button-back-color);
  color: white;
 }
 .btn-edit:hover{
  background-color: var(--button-back-color);
  color: white;
 }
 .list-invoice-delete{
  color: white;
  background-color: var(--button-back-color);
 }
 .list-invoice-delete:hover{
  color: white;
  background-color: var(--button-back-color);
 }
 .list-invoice-add{
  color: white;
  background-color: var(--button-back-color);
 }
 .list-invoice-add:hover{
  color: white;
  background-color: var(--button-back-color);
 }

@media(min-width:576px){
  .live-search{
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;
    padding: 11px 28px;
    margin-left: 10px;
  }
  .buy-prop-type{
    padding: 14px;
    margin-left: 10px;
  }
}
@media(min-width:992px){
  .nav-link{
      color: White;
  }
  .left-navigation img{
    height: 70px;
    width: 142px;
}

.live-text h5{
  padding-top: 100px;
}

.live-work-nav{
width:86%;
}

.live-work-nav .nav-link{
border-radius: 0px  !important;
background-color: white;
color: black;
}

.live-work-nav .buy-btn-head{
color: black;
background-color: #d9e0eb;
margin-right: 10px;
}

.main-people-living{
height: 380px;
}

.local-society{
width: 50%;
padding: 10px;
}

.nav-pills .nav-link.active{
background-color: #f0b6b4 !important;
color: white;
}
/* download app css */
.left-download h5{
font-weight: bold;
width: 50%;
}
.left-download p{
width: 60%;
font-size: var(--explore-font);
}
.left-download h5 span{
color: var(--primary-color);
}
.google-play-img{
  height: 75px;
  width: 160px;
}
.app-play-img{
  height: 52px;
  width: 150px;
  margin-top: 12px;
}
.scan-main span{
  font-size: var(--explore-font);
  width: 30%;
  margin-top: 10px;
  margin-left: 10px;
}
.scan-img{
  height: 150px;
}
}
/*!* Bootstrap v4.5.0 (https://getbootstrap.com/)
* Copyright 2011-2020 The Bootstrap Authors
* Copyright 2011-2020 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)*/

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #1c4d6b !important;
    font-size:xx-large !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #28a745 !important;
    font-size:xx-large !important;
}


:root {
    --blue: #007bff;
    --indigo: #6969eb;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #ff6935;
    --yellow: #f6b500;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --accent: #eff6f7;
    --primary: #e1777d;
    --secondary: #1e1d85;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #f6b500;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #252525;
    --accent: #eff6f7;
    --orange: #ff6935;
    --white: #fff;
    --indigo: #6969eb;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1600px;
    --font-family-sans-serif: "Poppins", sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --primary-color:#e1777d;
    --button-back-color:#e1777d;
    --nav-footer-bcolor:#1c4d6b;
    --secondary-color:#f3f5f8;
    --view-all-color:#535f8d;
}

/* #e1777d */
/* #e1777d */
/* #cfb068 */

/* .bg-secondary  #1c4d6b */

*,
*::before,
*::after {
    box-sizing: border-box
}

/* range slider */
.range-wrapper{
    width: 400px;
    background-color: white;
    border-radius: 10px;
    padding: 20px 25px 40px;
    box-shadow: 0 12px 35px rgba(0, 0, 0, .01);
}
.range-price-input{
    width: 100%;
    display: flex;
    margin: 30px 0 35px;
}
.range-price-input .range-field{
    display: flex;
    width: 100%;
    height: 35px;
    align-items: center;
}
.range-field input{
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 17px;
    margin-left: 12px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #999;
    -moz-appearance: textfield;
}
.range-price-input input[type="number"]::-webkit-outer-spin-button,
.range-price-input input[type="number"]::-webkit-inner-spin-button{
    -webkit-appearance: none;
}
.range-price-input .range-seperator{
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.range-slider{
    height: 5px;
    position: relative;
    background-color: gray;
    border-radius: 5px;

}
.range-slider .range-progress{
    height: 100%;
    left: 0%;
    right: 0%;
    position: absolute;
    border-radius: 5px;
    background-color: #007bff;

}
.range-input{
   position: relative; 
}
.range-input input{
    position: absolute;
    width: 100%;
    height: 5px;
    top: -5px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.range-input input[type="range"]::-webkit-slider-thumb{
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background-color: #007bff;
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, .05);
}
.range-input input[type="range"]::-moz-range-thumb{
    height: 17px;
    width: 17px;
    border: none;
    border-radius: 50%;
    background-color: #007bff;
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, .05);
}


.slick-slide img {
    display: block;
    /* height: 340px; */
}

.datefull-width {
    width: 100%;
  }

/* range slider end */


/* advertise card start */
.property-card {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .image-wrapper {
    position: relative;
    
  }
  
  .property-image {
    width: 100%;
    object-fit:contain;
    aspect-ratio: 3/2;
  }
  
  .content-overlay {
    position: absolute;
    top: 5%;
    bottom: 5%; /* Aligns the content towards the bottom of the image */
    right: 1%; /* Adjust horizontal padding */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white;
    padding: 1.5rem;
    border-radius: 10px;
    max-width: 70%; /* Ensure the text doesn't overflow */
    /* margin-top: 10; */
  }
  
  .explore-button {
    border: 1px solid white;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    transition: all 0.3s ease;
    color: white;
    text-decoration: none;
  }
  .explore-button:hover {
    background-color:#e1777d ;
    color: white;
  }
 

  .explore-button1 {
    border: 1px solid black;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    transition: all 0.3s ease;
    color: black;
    text-decoration: none;
  }
  
  .explore-button1:hover {
    background-color:#e1777d ;
    color: white;
    border: 1px solid #e1777d;
  }
  

/* advertise card end */

/* advertise middle card  start */
.carousel-container {
    margin: 2rem auto;
  
  }
  
  .card-wrapper {
    padding: 0 10px;
    height: 50%;
  }
  
  .property-card {
    position: relative;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    transition: box-shadow 0.3s ease;
  }
  
  .property-card:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  .property-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    

  }
  
  .property-content {
    padding: 1rem;
  }
  
  .property-content h5 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  
  .property-content p {
    margin: 0.2rem 0;
  }
  
  .property-price {
    font-weight: bold;
    color: #ff5722;
  }
  
  .property-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1rem;
    background-color: #001f3f;
    color: white;
  }
  
  .property-footer span {
    font-size: 0.9rem;
  }
  
  .view-number-btn {
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-number-btn:hover {
    background-color: #003f82;
  }
  
  /* advertise middle card end*/

.bhk{
    color: gray;
    font-size: 12px ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px !important                                                                                                                             
}


.dropdown-list-image {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
}

.dropdown-list-image img {
    height: 2.5rem;
    width: 2.5rem;
}
.btn-light {
    color: #2cdd9b;
    background-color: #e5f7f0;
    border-color: #d8f7eb;
}

/* html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
} */

/* navbar mobile background */
.mobile-togle-click{
    background-color: var(--nav-footer-bcolor);
    
}

/* step progress css */
.step-wizard {
    /* background-color: #21d4fd;
    background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%); */
    /* height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.step-wizard-list{
    /* background: #fff;
    box-shadow: 0 15px 25px rgba(0,0,0,0.1);
    color: #333; */
    list-style-type: none;
    border-radius: 10px;
    display: flex;
    padding: 0px 10px;
    position: relative;
    z-index: 10;
}

.step-wizard-item{
    padding: 0 20px;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive:1;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 170px;
    position: relative;
}
.step-wizard-item + .step-wizard-item:after{
    content: "";
    position: absolute;
    left: 0;
    top: 19px;
    background: var(--primary-color);
    width: 100%;
    height: 2px;
    transform: translateX(-50%);
    z-index: -10;
}
.progress-count{
    height: 40px;
    width:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    z-index:10;
    color: transparent;
}
.progress-count:after{
    content: "";
    height: 40px;
    width: 40px;
    background: var(--primary-color);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -10;
}
.progress-count:before{
    content: "";
    height: 10px;
    width: 20px;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%) rotate(-45deg);
    transform-origin: center center;
}
.progress-label{
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
}
.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before{
    display: none;
}
.current-item ~ .step-wizard-item .progress-count:after{
    height:10px;
    width:10px;
}
.current-item ~ .step-wizard-item .progress-label{
    opacity: 0.5;
}
.current-item .progress-count:after{
    background: #fff;
    border: 2px solid var(--primary-color);
}
.current-item .progress-count{
    color: var(--primary-color);
}
/* step progress css end */

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

* {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    /* font-size: .875rem;
    font-weight: 400; */
    line-height: 1.86;
    /* color: #696969; */
    /* text-align: left; */
    /* background-color: #fff */
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    /* touch-action: pan-y; */
    -webkit-tap-highlight-color: transparent;
    /* width: 200px !important;
    height: 150px !important;
    margin-bottom: -5; */
}


[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}


/* / chatbox css / */
 


.chat-card {
	height: 500px;
	border-radius: 15px !important;
	/* / background-color: rgba(0, 0, 0, 0.4) !important; / */
}
.msg_card_body {
	overflow-y: auto;
}

.chat-card-header {
	border-radius: 15px 15px 0 0 !important;
	border-bottom: 0 !important;
    background-color: var(--primary-color) !important;
}

.chat-card-footer {
	border-radius: 0 0 15px 15px !important;
	border-top: 0 !important;
}

.search {
	border-radius: 15px 0 0 15px !important;
	background-color: rgba(0, 0, 0, 0.3) !important;
	border: 0 !important;
	color: white !important;
}

.search:focus {
	box-shadow: none !important;
	outline: 0px !important;
}

.type_msg {
	background-color: rgba(0, 0, 0, 0.3) !important;
	border: 0 !important;
	color: white !important;
	height: 60px !important;
	overflow-y: auto;
}

.type_msg:focus {
	box-shadow: none !important;
	outline: 0px !important;
}

.send_btn {
	border-radius: 0 15px 15px 0 !important;
	background-color: rgba(0, 0, 0, 0.3) !important;
	border: 0 !important;
	color: white !important;
	cursor: pointer;
}

.search_btn {
	border-radius: 0 15px 15px 0 !important;
	background-color: rgba(0, 0, 0, 0.3) !important;
	border: 0 !important;
	color: white !important;
	cursor: pointer;
}

.user_img {
	height: 60px;
	width: 60px;
	border: 1.5px solid #f5f6fa;
}

.user_img_msg {
	height: 40px;
	width: 40px;
	border: 1.5px solid #f5f6fa;
}

.img_cont {
	position: relative;
	height: 70px;
	width: 70px;
}

.img_cont_msg {
	height: 40px;
	width: 40px;
}

.online_icon {
	position: absolute;
	height: 15px;
	width: 15px;
	background-color: #4cd137;
	border-radius: 50%;
	bottom: 13px;
	right: 13px;
	border: 1.5px solid white;
}

.offline {
	background-color: #c23616 !important;
}

.user_info {
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 15px;
}

.user_info span {
	font-size: 20px;
	color: white;
}

.user_info p {
	font-size: 10px;
	color: rgba(255, 255, 255, 0.6);
}

.video_cam {
	margin-left: 50px;
	margin-top: 5px;
}

.msg_cotainer {
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 10px;
	border-radius: 25px;
	background-color: #82ccdd;
	padding: 10px;
	
}

.msg_cotainer_send {
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 10px;
	border-radius: 25px;
	background-color: #78e08f;
	padding: 10px;
	
}

.msg_time {
	
	left: 0;
	bottom: -15px;
	color: rgba(255, 255, 255, 0.5);
	font-size: 10px;
}

.msg_time_send {
	
	right: 0;
	bottom: -15px;
	color: rgba(255, 255, 255, 0.5);
	font-size: 10px;
}



#action_menu_btn {
	
	right: 10px;
	top: 10px;
	color: white;
	cursor: pointer;
	font-size: 20px;
}

.action_menu {
	z-index: 1;
	
	padding: 15px 0;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	border-radius: 15px;
	top: 30px;
	right: 15px;
	display: none;
}

.action_menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.action_menu ul li {
	width: 100%;
	padding: 10px 15px;
	margin-bottom: 5px;
}

.action_menu ul li i {
	padding-right: 10px;
}

.action_menu ul li:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.2);
}



  /* / end of chatbox css / */
/* mobile sidebar*/

.sidebar {
    height: 100vh;
    position: fixed;
    top: 100px;
    
    transition: 0.5s;
    overflow-x: hidden;
    padding-top: 20px;
  }
  
  .sidebar.open {
    width: 100%;
  }
  
  .sidebar button {
    display: none;
  }
  
  /* Media query for medium-sized screens */
  @media screen and (max-width: 992px) {
    .sidebar {
      width: 0;
    }
  
    .sidebar.open {
      width: 70%;
    }
  
    .sidebar button {
      display: block;
      position: fixed;
      /* width: 40px;
      top: 90px;
      left: 20px; */
      width: 15px;
      top: 76px;
      left: 0px;
      font-size: 20px;
      background: var(--primary);
      
      border: none;
      cursor: pointer;
    }
  }

.dashboard-wrapper{
    position: relative;
  width: 100%;
  }
  /* .db-sidebar {
    position: fixed;
    width: 300px;
    height: 100%;
    transition: 0.5s;
    overflow: hidden;
    
  }
   .db-sidebar.active {
    width: 80px;
  }
  
  .page-content {
    position: absolute;
    width: calc(100% - 300px);
    left: 300px;
    min-height: 100vh;
    background: var(--white);
    transition: 0.5s;
  } */
  .db-sidebar {
    position: fixed;
    width: 100%;
    height: 80px;
    z-index: 1000;
    transition: 0.5s;
    overflow: hidden;
    
  }
  @media(min-width:992px){
    .db-sidebar {
        position: fixed;
        width: 300px;
        height: 100%;
        transition: 0.5s;
        overflow: hidden;
        
      }
  }
   .db-sidebar.active {
    width: 80px;
  }
  
  .page-content {
    position: absolute;
    width: 100%;
    left: 0px;
    min-height: 100vh;
    background: var(--white);
    transition: 0.5s;
  }
  @media(min-width:992px){
    .page-content {
        position: absolute;
        width: calc(100% - 300px);
        left: 300px;
        min-height: 100vh;
        background: var(--white);
        transition: 0.5s;
      }
  }
  .page-content.active {
    width: calc(100% );
    left: 0px;
  }
  .toggle {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    cursor: pointer;
  }



hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #252525 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


   .column-content {
  height: 400px;
  width: 115%;
  overflow: hidden;
}

.column-content:hover {
  overflow: auto;
}

.column-content::-webkit-scrollbar {
  width: 8px;
}

.column-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.column-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

.column-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6);
}


abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: var(--primary);
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: var(--primary);
    text-decoration: underline
}

a:not([href]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none
}

pre,
code,
kbd,
samp {
    /* font-family: SFMono-Regular, Menlo, Monaco, Consolas, liberation mono, courier new, monospace; */
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg {
    overflow: hidden;
    vertical-align: middle
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #ababab;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button
}

/* button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer
} */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=radio],
input[type=checkbox] {
    box-sizing: border-box;
    padding: 0
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #333
}

h1,
.h1 {
    font-size: 2.5rem
}

h2,
.h2 {
    font-size: 2rem
}

h3,
.h3 {
    font-size: 1.75rem
}

h4,
.h4 {
    font-size: 1.5rem
}

h5,
.h5 {
    font-size: 1.25rem
}

h6,
.h6 {
    font-size: 1rem
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300
}

.display-1 {
    font-size: 6.8125rem;
    font-weight: 500;
    line-height: 1.2
}

.display-2 {
    font-size: 4.0625rem;
    font-weight: 500;
    line-height: 1.2
}

.display-3 {
    font-size: 3.1875rem;
    font-weight: 500;
    line-height: 1.2
}

.display-4 {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 1.2
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

small,
.small {
    font-size: 80%;
    font-weight: 400
}

mark,
.mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d
}

.blockquote-footer::before {
    content: "\2014\00A0"
}


.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #6c757d
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: .2rem;
    box-shadow: inset 0 -.1rem 0 rgba(0, 0, 0, .25)
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media(min-width:576px) {
    .container {
        max-width: 770px
    }
}

@media(min-width:768px) {
    .container {
        max-width: 992px
    }
}

@media(min-width:992px) {
    .container {
        max-width: 1200px
    }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media(min-width:576px) {

    .container,
    .container-sm {
        max-width: 770px
    }
}

@media(min-width:768px) {

    .container,
    .container-sm,
    .container-md {
        max-width: 992px
    }
}

@media(min-width:992px) {

    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 1200px
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%
}

.row-cols-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.row-cols-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.row-cols-3>* {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.row-cols-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.row-cols-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
}

.row-cols-6>* {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    -ms-flex-order: -1;
    order: -1
}

.order-last {
    -ms-flex-order: 13;
    order: 13
}

.order-0 {
    -ms-flex-order: 0;
    order: 0
}

.order-1 {
    -ms-flex-order: 1;
    order: 1
}

.order-2 {
    -ms-flex-order: 2;
    order: 2
}

.order-3 {
    -ms-flex-order: 3;
    order: 3
}

.order-4 {
    -ms-flex-order: 4;
    order: 4
}

.order-5 {
    -ms-flex-order: 5;
    order: 5
}

.order-6 {
    -ms-flex-order: 6;
    order: 6
}

.order-7 {
    -ms-flex-order: 7;
    order: 7
}

.order-8 {
    -ms-flex-order: 8;
    order: 8
}

.order-9 {
    -ms-flex-order: 9;
    order: 9
}

.order-10 {
    -ms-flex-order: 10;
    order: 10
}

.order-11 {
    -ms-flex-order: 11;
    order: 11
}

.order-12 {
    -ms-flex-order: 12;
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media(min-width:576px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%
    }

    .row-cols-sm-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-sm-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-sm-3>* {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-sm-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-sm-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-sm-6>* {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-sm-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-sm-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-sm-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-sm-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-sm-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        -ms-flex-order: -1;
        order: -1
    }

    .order-sm-last {
        -ms-flex-order: 13;
        order: 13
    }

    .order-sm-0 {
        -ms-flex-order: 0;
        order: 0
    }

    .order-sm-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-sm-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-sm-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-sm-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-sm-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-sm-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-sm-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-sm-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-sm-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-sm-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-sm-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-sm-12 {
        -ms-flex-order: 12;
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media(min-width:768px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%
    }

    .row-cols-md-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-md-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-md-3>* {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-md-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-md-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-md-6>* {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-md-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-md-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-md-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        -ms-flex-order: -1;
        order: -1
    }

    .order-md-last {
        -ms-flex-order: 13;
        order: 13
    }

    .order-md-0 {
        -ms-flex-order: 0;
        order: 0
    }

    .order-md-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-md-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-md-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-md-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-md-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-md-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-md-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-md-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-md-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-md-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-md-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-md-12 {
        -ms-flex-order: 12;
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media(min-width:992px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%
    }

    .row-cols-lg-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-lg-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-lg-3>* {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-lg-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-lg-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-lg-6>* {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-lg-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-lg-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-lg-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        -ms-flex-order: -1;
        order: -1
    }

    .order-lg-last {
        -ms-flex-order: 13;
        order: 13
    }

    .order-lg-0 {
        -ms-flex-order: 0;
        order: 0
    }

    .order-lg-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-lg-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-lg-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-lg-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-lg-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-lg-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-lg-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-lg-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-lg-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-lg-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-lg-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-lg-12 {
        -ms-flex-order: 12;
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media(min-width:1200px) {
    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%
    }

    .row-cols-xl-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xl-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xl-3>* {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-xl-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xl-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xl-6>* {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xl-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xl-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-3 {
        -ms-flex: 0 0 25%;
        flex: 1 0 25%;
        max-width: 26%
    }

    .col-xl-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xl-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xl-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xl-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        -ms-flex-order: -1;
        order: -1
    }

    .order-xl-last {
        -ms-flex-order: 13;
        order: 13
    }

    .order-xl-0 {
        -ms-flex-order: 0;
        order: 0
    }

    .order-xl-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-xl-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-xl-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-xl-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-xl-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-xl-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-xl-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-xl-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-xl-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-xl-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-xl-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-xl-12 {
        -ms-flex-order: 12;
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }
}

@media(min-width:1600px) {
    .col-xxl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%
    }

    .row-cols-xxl-1>* {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xxl-2>* {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xxl-3>* {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-xxl-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xxl-5>* {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xxl-6>* {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xxl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xxl-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xxl-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xxl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xxl-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xxl-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xxl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xxl-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xxl-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xxl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xxl-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xxl-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xxl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xxl-first {
        -ms-flex-order: -1;
        order: -1
    }

    .order-xxl-last {
        -ms-flex-order: 13;
        order: 13
    }

    .order-xxl-0 {
        -ms-flex-order: 0;
        order: 0
    }

    .order-xxl-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-xxl-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-xxl-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-xxl-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-xxl-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-xxl-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-xxl-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-xxl-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-xxl-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-xxl-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-xxl-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-xxl-12 {
        -ms-flex-order: 12;
        order: 12
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333%
    }

    .offset-xxl-2 {
        margin-left: 16.66667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333%
    }

    .offset-xxl-5 {
        margin-left: 41.66667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333%
    }

    .offset-xxl-8 {
        margin-left: 66.66667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333%
    }

    .offset-xxl-11 {
        margin-left: 91.66667%
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #696969
}

.table th,
.table td {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eee
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eee
}

.table tbody+tbody {
    border-top: 2px solid #eee
}

.table-sm th,
.table-sm td {
    padding: .3rem
}

.table-bordered {
    border: 1px solid #eee
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #eee
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
    color: #696969;
    background-color: rgba(0, 0, 0, .075)
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #bceff3
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #82e1e9
}

.table-hover .table-primary:hover {
    background-color: #a6eaef
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #a6eaef
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #c0c0dd
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #8a89c0
}

.table-hover .table-secondary:hover {
    background-color: #afafd4
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #afafd4
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #c3e6cb
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #8fd19e
}

.table-hover .table-success:hover {
    background-color: #b1dfbb
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #bee5eb
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #86cfda
}

.table-hover .table-info:hover {
    background-color: #abdde5
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #fceab8
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #fad97a
}

.table-hover .table-warning:hover {
    background-color: #fbe3a0
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #fbe3a0
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #ed969e
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #fbfcfc
}

.table-hover .table-light:hover {
    background-color: #ececf6
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #c2c2c2
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #8e8e8e
}

.table-hover .table-dark:hover {
    background-color: #b5b5b5
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b5b5b5
}

.table-accent,
.table-accent>th,
.table-accent>td {
    background-color: #fbfcfd
}

.table-accent th,
.table-accent td,
.table-accent thead th,
.table-accent tbody+tbody {
    border-color: #f7fafb
}

.table-hover .table-accent:hover {
    background-color: #eaeff5
}

.table-hover .table-accent:hover>td,
.table-hover .table-accent:hover>th {
    background-color: #eaeff5
}

.table-orange,
.table-orange>th,
.table-orange>td {
    background-color: #ffd5c6
}

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody+tbody {
    border-color: #ffb196
}

.table-hover .table-orange:hover {
    background-color: #ffc2ad
}

.table-hover .table-orange:hover>td,
.table-hover .table-orange:hover>th {
    background-color: #ffc2ad
}

.table-white,
.table-white>th,
.table-white>td {
    background-color: #fff
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody+tbody {
    border-color: #fff
}

.table-hover .table-white:hover {
    background-color: #f2f2f2
}

.table-hover .table-white:hover>td,
.table-hover .table-white:hover>th {
    background-color: #f2f2f2
}

.table-indigo,
.table-indigo>th,
.table-indigo>td {
    background-color: #d5d5f9
}

.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody+tbody {
    border-color: #b1b1f5
}

.table-hover .table-indigo:hover {
    background-color: #bfbff6
}

.table-hover .table-indigo:hover>td,
.table-hover .table-indigo:hover>th {
    background-color: #bfbff6
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, .075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55
}

.table .thead-light th {
    color: #333;
    background-color: #e9ecef;
    border-color: #eee
}

.table-dark {
    color: #fff;
    background-color: #343a40
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #454d55
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .075)
}

@media(max-width:575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media(max-width:767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media(max-width:991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media(max-width:1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

@media(max-width:1599.98px) {
    .table-responsive-xxl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-xxl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.86em + 0.75rem + 4px);
    padding: .375rem .75rem;
    font-size: .8125rem;
    font-weight: 100;
    line-height: 1.86;
    color: #696969;
    background-color: #f8f8f8;
    background-clip: padding-box;
    border: 2px solid #ededed;
    border-radius: 3px;
    box-shadow: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #696969
}

.form-control:focus {
    color: #696969;
    background-color: #f8f8f8;
    /* border-color: #6debf5; */
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(14, 198, 213, .25)
}

.form-control::-webkit-input-placeholder {
    color: #ababab;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #ababab;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #ababab;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #ababab;
    opacity: 1
}

.form-control::placeholder {
    color: #ababab;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

select.form-control:focus::-ms-value {
    color: #696969;
    background-color: #f8f8f8
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(0.375rem + 2px);
    padding-bottom: calc(0.375rem + 2px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.86
}

.col-form-label-lg {
    padding-top: calc(0.625rem + 2px);
    padding-bottom: calc(0.625rem + 2px);
    font-size: .875rem;
    line-height: 2
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 2px);
    padding-bottom: calc(0.25rem + 2px);
    font-size: .75rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    font-size: .8125rem;
    line-height: 1.86;
    color: #696969;
    background-color: transparent;
    border: solid transparent;
    border-width: 2px 0
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 4px);
    padding: .25rem .5rem;
    font-size: .75rem;
    line-height: 1.5;
    border-radius: .2rem
}

.form-control-lg {
    height: calc(2em + 1.25rem + 4px);
    padding: .625rem 1rem;
    font-size: .875rem;
    line-height: 2;
    border-radius: 3px
}

select.form-control[size],
select.form-control[multiple] {
    height: auto
}

textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}


.form-check-input:checked {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}


.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
    border-color: black;
}


.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    color: #ababab
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #28a745
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.86;
    color: #fff;
    background-color: rgba(40, 167, 69, .9);
    border-radius: 3px
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
    display: block
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #28a745;
    padding-right: calc(1.86em + 0.75rem);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMyOGE3NDUnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: right calc(0.465em + 0.1875rem) center;
    background-size: calc(0.93em + 0.375rem) calc(0.93em + 0.375rem)
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.86em + 0.75rem);
    background-position: top calc(0.465em + 0.1875rem) right calc(0.465em + 0.1875rem)
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #28a745;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #f8f8f8 no-repeat center right 1.75rem/calc(0.93em + 0.375rem) calc(0.93em + 0.375rem)
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #28a745
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
    display: block
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
    color: #28a745
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
    border-color: #28a745
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #28a745
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
    border-color: #28a745
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    line-height: 1.86;
    color: #fff;
    background-color: rgba(220, 53, 69, .9);
    border-radius: 3px
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
    display: block
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.86em + 0.75rem);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgZmlsbD0nbm9uZScgc3Ryb2tlPScjZGMzNTQ1JyB2aWV3Qm94PScwIDAgMTIgMTInPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: right calc(0.465em + 0.1875rem) center;
    background-size: calc(0.93em + 0.375rem) calc(0.93em + 0.375rem)
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.86em + 0.75rem);
    background-position: top calc(0.465em + 0.1875rem) right calc(0.465em + 0.1875rem)
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #f8f8f8 no-repeat center right 1.75rem/calc(0.93em + 0.375rem) calc(0.93em + 0.375rem)
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #dc3545
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
    display: block
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
    color: #dc3545
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
    border-color: #dc3545
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #dc3545
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
    border-color: #dc3545
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

.form-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media(min-width:576px) {
    .form-inline label {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto
    }

    .form-inline .form-check {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    display: inline-block;
    font-weight: 500;
    color: #696969;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .4375rem 1.5rem;
    font-size: .8125rem;
    line-height: 1.86;
    border-radius: 1px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #696969;
    text-decoration: none
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(14, 198, 213, .25)
}

.btn.disabled,
.btn:disabled {
    opacity: .65;
    box-shadow: none
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 .2rem rgba(14, 198, 213, .25), inset 0 3px 5px rgba(0, 0, 0, .125)
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: none
}

.btn-primary:hover {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: none
}
/* 
.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #0ca5b1;
    border-color: #0b9aa5;
    box-shadow: 0 0 0 .2rem rgba(50, 207, 219, .5)
} */

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary)
}

/* .btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0b9aa5;
    border-color: #0a8e99
} */

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(50, 207, 219, .5)
}

.btn-secondary {
    color: #fff;
    background-color: #1e1d85;
    border-color: #1e1d85;
    box-shadow: none
}

.btn-secondary:hover {
    color: #fff;
    background-color: #171666;
    border-color: #15145b
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: #fff;
    background-color: #171666;
    border-color: #15145b;
    box-shadow: 0 0 0 .2rem rgba(64, 63, 151, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #1e1d85;
    border-color: #1e1d85
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #15145b;
    border-color: #121251
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(64, 63, 151, .5)
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    box-shadow: none
}

.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34
}

.btn-success:focus,
.btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430
}

/* .btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(72, 180, 97, .5)
} */

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    box-shadow: none
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b
}

.btn-info:focus,
.btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(58, 176, 195, .5)
}

.btn-warning {
    color: #212529;
    background-color: #f6b500;
    border-color: #f6b500;
    box-shadow: none
}

.btn-warning:hover {
    color: #212529;
    background-color: #d09900;
    border-color: #c38f00
}

.btn-warning:focus,
.btn-warning.focus {
    color: #212529;
    background-color: #d09900;
    border-color: #c38f00;
    box-shadow: 0 0 0 .2rem rgba(214, 159, 6, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #f6b500;
    border-color: #f6b500
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #c38f00;
    border-color: #b68600
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(214, 159, 6, .5)
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    box-shadow: none
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130
}

.btn-danger:focus,
.btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(225, 83, 97, .5)
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: none
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5
}

.btn-light:focus,
.btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(216, 217, 219, .5)
}

.btn-dark {
    color: #fff;
    background-color: #252525;
    border-color: #252525;
    box-shadow: none
}

.btn-dark:hover {
    color: #fff;
    background-color: #121212;
    border-color: #0c0c0c
}

.btn-dark:focus,
.btn-dark.focus {
    color: #fff;
    background-color: #121212;
    border-color: #0c0c0c;
    box-shadow: 0 0 0 .2rem rgba(70, 70, 70, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #252525;
    border-color: #252525
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0c0c0c;
    border-color: #050505
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(70, 70, 70, .5)
}

.btn-accent {
    color: #212529;
    background-color: #eff6f7;
    border-color: #eff6f7;
    box-shadow: none
}

.btn-accent:hover {
    color: #212529;
    background-color: #d6e8ea;
    border-color: #cde3e6
}

.btn-accent:focus,
.btn-accent.focus {
    color: #212529;
    background-color: #d6e8ea;
    border-color: #cde3e6;
    box-shadow: 0 0 0 .2rem rgba(208, 215, 216, .5)
}

.btn-accent.disabled,
.btn-accent:disabled {
    color: #212529;
    background-color: #eff6f7;
    border-color: #eff6f7
}

.btn-accent:not(:disabled):not(.disabled):active,
.btn-accent:not(:disabled):not(.disabled).active,
.show>.btn-accent.dropdown-toggle {
    color: #212529;
    background-color: #cde3e6;
    border-color: #c5dee2
}

.btn-accent:not(:disabled):not(.disabled):active:focus,
.btn-accent:not(:disabled):not(.disabled).active:focus,
.show>.btn-accent.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(208, 215, 216, .5)
}

.btn-orange {
    color: #fff;
    background-color: #ff6935;
    border-color: #ff6935;
    box-shadow: none
}

.btn-orange:hover {
    color: #fff;
    background-color: #ff4d0f;
    border-color: #ff4302
}

.btn-orange:focus,
.btn-orange.focus {
    color: #fff;
    background-color: #ff4d0f;
    border-color: #ff4302;
    box-shadow: 0 0 0 .2rem rgba(255, 128, 83, .5)
}

.btn-orange.disabled,
.btn-orange:disabled {
    color: #fff;
    background-color: #ff6935;
    border-color: #ff6935
}

.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show>.btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #ff4302;
    border-color: #f43f00
}

.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show>.btn-orange.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(255, 128, 83, .5)
}

.btn-white {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none
}

.btn-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6
}

.btn-white:focus,
.btn-white.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 .2rem rgba(222, 222, 223, .5)
}

.btn-white.disabled,
.btn-white:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show>.btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf
}

.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-white.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(222, 222, 223, .5)
}

.btn-indigo {
    color: #fff;
    background-color: #6969eb;
    border-color: #6969eb;
    box-shadow: none
}

.btn-indigo:hover {
    color: #fff;
    background-color: #4747e7;
    border-color: #3c3ce5
}

.btn-indigo:focus,
.btn-indigo.focus {
    color: #fff;
    background-color: #4747e7;
    border-color: #3c3ce5;
    box-shadow: 0 0 0 .2rem rgba(128, 128, 238, .5)
}

.btn-indigo.disabled,
.btn-indigo:disabled {
    color: #fff;
    background-color: #6969eb;
    border-color: #6969eb
}

.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active,
.show>.btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #3c3ce5;
    border-color: #3131e4
}

.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus,
.show>.btn-indigo.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(128, 128, 238, .5)
}

.btn-outline {
    color:"black";
    padding-left: 8px;
    padding-right: 8px;
    margin: 3px;
    border:1px solid black;
    border-color:var(--primary) ;
}

.btn-outline:hover {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary)
}

.btn-outline-primary {
    /* color: var(--primary); */
    color:"black";
    padding-left: 8px;
    padding-right: 8px;
    margin: 3px;
    border:1px;
    border-color: var(--primary)
    /* justify-content: space-between   */
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary)
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 .2rem rgba(14, 198, 213, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: var(--primary);
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary)
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(14, 198, 213, .5)
}

.btn-outline-secondary {
    color: #1e1d85;
    border-color: #1e1d85
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #1e1d85;
    border-color: #1e1d85
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 .2rem rgba(30, 29, 133, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #1e1d85;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1e1d85;
    border-color: #1e1d85
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(30, 29, 133, .5)
}

.btn-outline-success {
    color: #28a745;
    border-color: #28a745
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.btn-outline-warning {
    color: #f6b500;
    border-color: #f6b500
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #f6b500;
    border-color: #f6b500
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 .2rem rgba(246, 181, 0, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #f6b500;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f6b500;
    border-color: #f6b500
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(246, 181, 0, .5)
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.btn-outline-light {
    color: #f8f9fa;
    /* border-color: #f8f9fa */
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
    color: #252525;
    border-color: #252525
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #252525;
    border-color: #252525
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 .2rem rgba(37, 37, 37, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #252525;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #252525;
    border-color: #252525
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(37, 37, 37, .5)
}

.btn-outline-accent {
    color: #eff6f7;
    border-color: #eff6f7
}

.btn-outline-accent:hover {
    color: #212529;
    background-color: #eff6f7;
    border-color: #eff6f7
}

.btn-outline-accent:focus,
.btn-outline-accent.focus {
    box-shadow: 0 0 0 .2rem rgba(239, 246, 247, .5)
}

.btn-outline-accent.disabled,
.btn-outline-accent:disabled {
    color: #eff6f7;
    background-color: transparent
}

.btn-outline-accent:not(:disabled):not(.disabled):active,
.btn-outline-accent:not(:disabled):not(.disabled).active,
.show>.btn-outline-accent.dropdown-toggle {
    color: #212529;
    background-color: #eff6f7;
    border-color: #eff6f7
}

.btn-outline-accent:not(:disabled):not(.disabled):active:focus,
.btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-accent.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(239, 246, 247, .5)
}

.btn-outline-orange {
    color: #ff6935;
    border-color: #ff6935
}

.btn-outline-orange:hover {
    color: #fff;
    background-color: #ff6935;
    border-color: #ff6935
}

.btn-outline-orange:focus,
.btn-outline-orange.focus {
    box-shadow: 0 0 0 .2rem rgba(255, 105, 53, .5)
}

.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
    color: #ff6935;
    background-color: transparent
}

.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled).active,
.show>.btn-outline-orange.dropdown-toggle {
    color: #fff;
    background-color: #ff6935;
    border-color: #ff6935
}

.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-orange.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(255, 105, 53, .5)
}

.btn-outline-white {
    color: #fff;
    border-color: #fff
}

.btn-outline-white:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff
}

.btn-outline-white:focus,
.btn-outline-white.focus {
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
    color: #fff;
    background-color: transparent
}

.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show>.btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-white.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.btn-outline-indigo {
    color: #6969eb;
    border-color: #6969eb
}

.btn-outline-indigo:hover {
    color: #fff;
    background-color: #6969eb;
    border-color: #6969eb
}

.btn-outline-indigo:focus,
.btn-outline-indigo.focus {
    box-shadow: 0 0 0 .2rem rgba(105, 105, 235, .5)
}

.btn-outline-indigo.disabled,
.btn-outline-indigo:disabled {
    color: #6969eb;
    /* background-color: transparent */
}

.btn-outline-indigo:not(:disabled):not(.disabled):active,
.btn-outline-indigo:not(:disabled):not(.disabled).active,
.show>.btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #6969eb;
    border-color: #6969eb
}

.btn-outline-indigo:not(:disabled):not(.disabled):active:focus,
.btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-indigo.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .2rem rgba(105, 105, 235, .5)
}

.btn-link {
    font-weight: 400;
    color: var(--primary);
    text-decoration: none
}

.btn-link:hover {
    color: #09838d;
    text-decoration: underline
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
    pointer-events: none
}

.btn-lg,
.btn-group-lg>.btn {
    /* padding: .6875rem 2.1875rem; */
    font-size: .875rem;
    line-height: 2;
    border-radius: 5px
}

.btn-sm,
.btn-group-sm>.btn {
    padding: .3125rem .5rem;
    font-size: .75rem;
    line-height: 1.5;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
    width: 100%
}

.fade {
    transition: opacity .15s linear
}

@media(prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media(prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .25rem;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    width: auto;
    min-width: 15rem;
    /* width:30rem; */
    padding: 1rem 0;
    margin: 0;
    font-size: .875rem;
    color: #696969;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, .15);
    border-radius: 5px;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .15)
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

@media(min-width:576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto
    }
}

@media(min-width:768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto
    }
}

@media(min-width:992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto
    }
}

@media(min-width:1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto
    }
}

@media(min-width:1600px) {
    .dropdown-menu-xxl-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-right {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .25rem;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: .25rem;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: .25rem;
    vertical-align: .255em;
    content: ""
}

.dropleft .dropdown-toggle::after {
    display: none
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: .25rem;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left] {
    right: auto;
    bottom: auto
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}


.dropdown-item.hover,
.dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: var(--primary)
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--primary)
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: 1rem 1.5rem;
    margin-bottom: 0;
    font-size: .75rem;
    color: #252525;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1.5rem;
    color: #212529
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
    z-index: 1
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 1
}

.btn-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
    padding-right: 1.64063rem;
    padding-left: 1.64063rem
}

.btn-group.show .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none
}

.btn-group-vertical {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -2px
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-prepend,
.input-group-append {
    display: -ms-flexbox;
    display: flex
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
    z-index: 3
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
    margin-left: -2px
}

.input-group-prepend {
    margin-right: -2px
}

.input-group-append {
    margin-left: -2px
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.86;
    color: #696969;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 2px solid #ededed;
    border-radius: 3px
}

.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
    margin-top: 0
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
    height: calc(2em + 1.25rem + 4px)
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
    padding: .625rem 1rem;
    font-size: .875rem;
    line-height: 2;
    border-radius: 3px;
    
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
    height: calc(1.5em + 0.5rem + 4px)
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
    padding: .25rem .5rem;
    font-size: .75rem;
    line-height: 1.5;
    border-radius: .2rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.75rem
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.6275rem;
    padding-left: 1.875rem
}

.custom-control-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem
}
/* 
.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.125rem;
    height: 1.37625rem;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: var(--primary);
    background-color: var(--primary);
    box-shadow: none
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none, 0 0 0 .2rem rgba(14, 198, 213, .25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: transparent
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #9df2f9;
    border-color: #9df2f9;
    box-shadow: none
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
    color: #6c757d
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef
} */

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top
}

.custom-control-label::before {
    position: absolute;
    top: .25125rem;
    left: -1.875rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    pointer-events: none;
    content: "";
    background-color: #eee;
    border: transparent solid 0;
    box-shadow: none
}

.custom-control-label::after {
    position: absolute;
    top: .25125rem;
    left: -1.875rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    content: "";
    background: no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-label::before {
    border-radius: 3px
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J002LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNmwyLjk3NCAyLjk5TDggMi4xOTN6Jy8+PC9zdmc+)
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: var(--primary);
    background-color: var(--primary);
    box-shadow: none
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0JyBoZWlnaHQ9JzQnIHZpZXdCb3g9JzAgMCA0IDQnPjxwYXRoIHN0cm9rZT0nI2ZmZicgZD0nTTAgMmg0Jy8+PC9zdmc+)
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(14, 198, 213, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(14, 198, 213, .5)
}

.custom-radio .custom-control-label::before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgdmlld0JveD0nLTQgLTQgOCA4Jz48Y2lyY2xlIHI9JzMnIGZpbGw9JyNmZmYnLz48L3N2Zz4=)
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(14, 198, 213, .5)
}

.custom-switch {
    padding-left: 2.71875rem
}

.custom-switch .custom-control-label::before {
    left: -2.71875rem;
    width: 1.96875rem;
    pointer-events: all;
    border-radius: .5625rem
}

.custom-switch .custom-control-label::after {
    top: .25125rem;
    left: -2.71875rem;
    width: 1.125rem;
    height: 1.125rem;
    background-color: transparent;
    border-radius: .5625rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .custom-switch .custom-control-label::after {
        transition: none
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #eee;
    -webkit-transform: translateX(0.84375rem);
    transform: translateX(0.84375rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(14, 198, 213, .5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.86em + 0.75rem + 4px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: .8125rem;
    font-weight: 600;
    line-height: 1.86;
    color: #696969;
    vertical-align: middle;
    background: #f8f8f8 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    border: 2px solid #ededed;
    border-radius: 3px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select:focus {
    border-color: #6debf5;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 0 .2rem rgba(14, 198, 213, .25)
}

.custom-select:focus::-ms-value {
    color: #696969;
    background-color: #f8f8f8
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: .75rem;
    background-image: none
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef
}

.custom-select::-ms-expand {
    display: none
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #696969
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 4px);
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .75rem
}

.custom-select-lg {
    height: calc(2em + 1.25rem + 4px);
    padding-top: .625rem;
    padding-bottom: .625rem;
    padding-left: 1rem;
    font-size: .875rem
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.86em + 0.75rem + 4px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.86em + 0.75rem + 4px);
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-label {
    border-color: #6debf5;
    box-shadow: 0 0 0 .2rem rgba(14, 198, 213, .25)
}

.custom-file-input[disabled]~.custom-file-label,
.custom-file-input:disabled~.custom-file-label {
    background-color: #e9ecef
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse)
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.86em + 0.75rem + 4px);
    padding: .375rem .75rem;
    font-weight: 600;
    line-height: 1.86;
    color: #696969;
    background-color: #f8f8f8;
    border: 2px solid #ededed;
    border-radius: 3px;
    box-shadow: none
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.86em + 0.75rem);
    padding: .375rem .75rem;
    line-height: 1.86;
    color: #696969;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 3px 3px 0
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-range:focus {
    outline: none
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(14, 198, 213, .25)
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(14, 198, 213, .25)
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(14, 198, 213, .25)
}

.custom-range::-moz-focus-outer {
    border: 0
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #9df2f9
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 .25rem .25rem rgba(0, 0, 0, .1)
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #9df2f9
}

.custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 .25rem .25rem rgba(0, 0, 0, .1)
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: .2rem;
    margin-left: .2rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
    -ms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media(prefers-reduced-motion:reduce) {
    .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none
    }
}

.custom-range::-ms-thumb:active {
    background-color: #9df2f9
}

.custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem;
    box-shadow: inset 0 .25rem .25rem rgba(0, 0, 0, .1)
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
}

.custom-range:disabled::-moz-range-track {
    cursor: default
}

.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none
    }
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 0 solid #dee2e6
}

.nav-tabs .nav-item {
    margin-bottom: 0
}

.nav-tabs .nav-link {
    border: 0 solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: var(--primary);
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: 3px
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: var(--primary)
}


.nav-fill .nav-item {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .38375rem;
    padding-bottom: .38375rem;
    /* margin-right: 2rem; */
    font-size: 1rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
}

@media(max-width:575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg {
        padding-right: 0;
        padding-left: 0
    }
}

@media(min-width:576px) {
    .navbar-expand-sm {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media(max-width:767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-lg {
        padding-right: 0;
        padding-left: 0
    }
}

@media(min-width:768px) {
    .navbar-expand-md {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-lg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media(max-width:991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-lg {
        padding-right: 0;
        padding-left: 0
    }
}

@media(min-width:992px) {
    .navbar-expand-lg {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-lg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media(max-width:1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-lg {
        padding-right: 0;
        padding-left: 0
    }
}

@media(min-width:1200px) {
    .navbar-expand-xl {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-lg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

@media(max-width:1599.98px) {

    .navbar-expand-xxl>.container,
    .navbar-expand-xxl>.container-fluid,
    .navbar-expand-xxl>.container-sm,
    .navbar-expand-xxl>.container-md,
    .navbar-expand-xxl>.container-lg {
        padding-right: 0;
        padding-left: 0
    }
}

@media(min-width:1600px) {
    .navbar-expand-xxl {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xxl>.container,
    .navbar-expand-xxl>.container-fluid,
    .navbar-expand-xxl>.container-sm,
    .navbar-expand-xxl>.container-md,
    .navbar-expand-xxl>.container-lg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-xxl .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
   color: white;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    /* color: rgba(0, 0, 0, .7) */
    color: white;
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: #333;
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSczMCcgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDMwIDMwJz48cGF0aCBzdHJva2U9JyMzMzMnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBzdHJva2Utd2lkdGg9JzInIGQ9J000IDdoMjJNNCAxNWgyMk00IDIzaDIyJy8+PC9zdmc+)
}

.navbar-light .navbar-text {
    color: #333
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: #f5f5f5
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: #fff;
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSczMCcgaGVpZ2h0PSczMCcgdmlld0JveD0nMCAwIDMwIDMwJz48cGF0aCBzdHJva2U9JyNmZmYnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBzdHJva2Utd2lkdGg9JzInIGQ9J000IDdoMjJNNCAxNWgyMk00IDIzaDIyJy8+PC9zdmc+)
}

.navbar-dark .navbar-text {
    color: #fff
}

.navbar-dark .navbar-text a {
    color: #fff
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
     /* background-color: #000;  */
    background-clip: border-box;
    border: 1px solid #eee;
    border-radius: 5px
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid #eee
}

.card-header:first-child {
    border-radius: 4px 4px 0 0
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid #eee
}

.card-footer:last-child {
    border-radius: 0 0 4px 4px
}

.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: .625rem
}

.card-img,
.card-img-top,
.card-img-bottom {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.card-deck .card {
    margin-bottom: 15px
}

@media(min-width:576px) {
    .card-deck {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }

    .card-deck .card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.card-group>.card {
    margin-bottom: 15px
}

@media(min-width:576px) {
    .card-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-group>.card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media(min-width:576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.accordion>.card {
    overflow: hidden
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px
}

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .75rem 0;
    margin-bottom: 0;
    font-size: .875rem;
    list-style: none;
    background-color: transparent;
    border-radius: 3px
}

.breadcrumb-item {
    display: -ms-flexbox;
    display: flex
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: currentColor;
    content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none
}

.breadcrumb-item.active {
    color: #333
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 3px
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: 0;
    line-height: 1.25;
    color: #333;
    background-color: transparent;
    border: 0 solid #dee2e6
}

.page-link:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: var(--primary);
    border-color: var(--primary)
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(14, 198, 213, .25)
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

.page-item:last-child .page-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary)
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1rem;
    line-height: 2
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .75rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: 5px 8px;
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .badge {
        transition: none
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: var(--primary)
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #fff;
    background-color: #0b9aa5
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(14, 198, 213, .5)
}

.badge-secondary {
    color: #fff;
    background-color: #1e1d85
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #fff;
    background-color: #15145b
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(30, 29, 133, .5)
}

.badge-success {
    color: #fff;
    background-color: #28a745
}

a.badge-success:hover,
a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34
}

a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5)
}

.badge-info {
    color: #fff;
    background-color: #17a2b8
}

a.badge-info:hover,
a.badge-info:focus {
    color: #fff;
    background-color: #117a8b
}

a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5)
}

.badge-warning {
    color: #212529;
    background-color: #f6b500
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #212529;
    background-color: #c38f00
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(246, 181, 0, .5)
}

.badge-danger {
    color: #fff;
    background-color: #dc3545
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa
}

a.badge-light:hover,
a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.badge-dark {
    color: #fff;
    background-color: #252525
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #fff;
    background-color: #0c0c0c
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(37, 37, 37, .5)
}

.badge-accent {
    color: #212529;
    background-color: #eff6f7
}

a.badge-accent:hover,
a.badge-accent:focus {
    color: #212529;
    background-color: #cde3e6
}

a.badge-accent:focus,
a.badge-accent.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(239, 246, 247, .5)
}

.badge-orange {
    color: #fff;
    background-color: #ff6935
}

a.badge-orange:hover,
a.badge-orange:focus {
    color: #fff;
    background-color: #ff4302
}

a.badge-orange:focus,
a.badge-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(255, 105, 53, .5)
}

.badge-white {
    color: #212529;
    background-color: #fff
}

a.badge-white:hover,
a.badge-white:focus {
    color: #212529;
    background-color: #e6e6e6
}

a.badge-white:focus,
a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5)
}

.badge-indigo {
    color: #fff;
    background-color: #6969eb
}

a.badge-indigo:hover,
a.badge-indigo:focus {
    color: #fff;
    background-color: #3c3ce5
}

a.badge-indigo:focus,
a.badge-indigo.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(105, 105, 235, .5)
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 5px
}

@media(min-width:576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 3px
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3.8125rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #07676f;
    background-color: #cff4f7;
    border-color: #bceff3
}

.alert-primary hr {
    border-top-color: #a6eaef
}

.alert-primary .alert-link {
    color: #043a3f
}

.alert-secondary {
    color: #100f45;
    background-color: #d2d2e7;
    border-color: #c0c0dd
}

.alert-secondary hr {
    border-top-color: #afafd4
}

.alert-secondary .alert-link {
    color: #06061b
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb
}

.alert-success hr {
    border-top-color: #b1dfbb
}

.alert-success .alert-link {
    color: #0b2e13
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb
}

.alert-info hr {
    border-top-color: #abdde5
}

.alert-info .alert-link {
    color: #062c33
}

.alert-warning {
    color: #805e00;
    background-color: #fdf0cc;
    border-color: #fceab8
}

.alert-warning hr {
    border-top-color: #fbe3a0
}

.alert-warning .alert-link {
    color: #4d3900
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb
}

.alert-danger hr {
    border-top-color: #f1b0b7
}

.alert-danger .alert-link {
    color: #491217
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe
}

.alert-light hr {
    border-top-color: #ececf6
}

.alert-light .alert-link {
    color: #686868
}

.alert-dark {
    color: #131313;
    background-color: #d3d3d3;
    border-color: #c2c2c2
}

.alert-dark hr {
    border-top-color: #b5b5b5
}

.alert-dark .alert-link {
    color: #000
}

.alert-accent {
    color: #7c8080;
    background-color: #fcfdfd;
    border-color: #fbfcfd
}

.alert-accent hr {
    border-top-color: #eaeff5
}

.alert-accent .alert-link {
    color: #636666
}

.alert-orange {
    color: #85371c;
    background-color: #ffe1d7;
    border-color: #ffd5c6
}

.alert-orange hr {
    border-top-color: #ffc2ad
}

.alert-orange .alert-link {
    color: #5b2613
}

.alert-white {
    color: #858585;
    background-color: #fff;
    border-color: #fff
}

.alert-white hr {
    border-top-color: #f2f2f2
}

.alert-white .alert-link {
    color: #6c6c6c
}

.alert-indigo {
    color: #37377a;
    background-color: #e1e1fb;
    border-color: #d5d5f9
}

.alert-indigo hr {
    border-top-color: #bfbff6
}

.alert-indigo .alert-link {
    color: #272757
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: .65625rem;
    background-color: #e9ecef;
    border-radius: 3px;
    box-shadow: inset 0 .1rem .1rem rgba(0, 0, 0, .1)
}

.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: var(--primary);
    transition: width .6s ease
}

@media(prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite
}

@media(prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start
}

.media-body {
    -ms-flex: 1;
    flex: 1
}

.list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 3px
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f8f8
}

.list-group-item-action:active {
    color: #696969;
    background-color: #e9ecef
}

.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    background-color: #fff;
    border: 0px solid #eee
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary)
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    -ms-flex-direction: row;
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media(min-width:576px) {
    .list-group-horizontal-sm {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width:768px) {
    .list-group-horizontal-md {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width:992px) {
    .list-group-horizontal-lg {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width:1200px) {
    .list-group-horizontal-xl {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media(min-width:1600px) {
    .list-group-horizontal-xxl {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #07676f;
    background-color: #bceff3
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #07676f;
    background-color: #a6eaef
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #07676f;
    border-color: #07676f
}

.list-group-item-secondary {
    color: #100f45;
    background-color: #c0c0dd
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #100f45;
    background-color: #afafd4
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #100f45;
    border-color: #100f45
}

.list-group-item-success {
    color: #155724;
    background-color: #c3e6cb
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724
}

.list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460
}

.list-group-item-warning {
    color: #805e00;
    background-color: #fceab8
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #805e00;
    background-color: #fbe3a0
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #805e00;
    border-color: #805e00
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182
}

.list-group-item-dark {
    color: #131313;
    background-color: #c2c2c2
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #131313;
    background-color: #b5b5b5
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #131313;
    border-color: #131313
}

.list-group-item-accent {
    color: #7c8080;
    background-color: #fbfcfd
}

.list-group-item-accent.list-group-item-action:hover,
.list-group-item-accent.list-group-item-action:focus {
    color: #7c8080;
    background-color: #eaeff5
}

.list-group-item-accent.list-group-item-action.active {
    color: #fff;
    background-color: #7c8080;
    border-color: #7c8080
}

.list-group-item-orange {
    color: #85371c;
    background-color: #ffd5c6
}

.list-group-item-orange.list-group-item-action:hover,
.list-group-item-orange.list-group-item-action:focus {
    color: #85371c;
    background-color: #ffc2ad
}

.list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #85371c;
    border-color: #85371c
}

.list-group-item-white {
    color: #858585;
    background-color: #fff
}

.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2
}

.list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585
}

.list-group-item-indigo {
    color: #37377a;
    background-color: #d5d5f9
}

.list-group-item-indigo.list-group-item-action:hover,
.list-group-item-indigo.list-group-item-action:focus {
    color: #37377a;
    background-color: #bfbff6
}

.list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #37377a;
    border-color: #37377a
}

.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:hover {
    color: #000;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0
}

a.close.disabled {
    pointer-events: none
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: .25rem
}

.toast:not(:last-child) {
    margin-bottom: .75rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .25rem .75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.toast-body {
    padding: .75rem
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px)
}

@media(prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .5);
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #eee;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.86
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #eee;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.modal-footer>* {
    margin: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media(min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content
    }

    .modal-content {
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media(min-width:992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media(min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.86;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .75rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^=top] {
    padding: .4rem 0
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^=top] .arrow {
    bottom: 0
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^=right] {
    padding: 0 .4rem
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^=right] .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^=bottom] {
    padding: .4rem 0
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^=bottom] .arrow {
    top: 0
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^=left] {
    padding: 0 .4rem
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^=left] .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 3px
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.86;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .75rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, .2);
    border-radius: 3px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .06)
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 3px
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-top,
.bs-popover-auto[x-placement^=top] {
    margin-bottom: .5rem
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^=top]>.arrow {
    bottom: -.5rem
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^=top]>.arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^=top]>.arrow::after {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-right,
.bs-popover-auto[x-placement^=right] {
    margin-left: .5rem
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^=right]>.arrow {
    left: -.5rem;
    width: .5rem;
    height: 1rem;
    margin: 3px 0
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^=right]>.arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^=right]>.arrow::after {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^=bottom] {
    margin-top: .5rem
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^=bottom]>.arrow {
    top: -.5rem
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^=bottom]>.arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^=bottom]>.arrow::after {
    top: 0;
    border-width: 0 .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^=bottom] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 0 solid #f7f7f7
}

.bs-popover-left,
.bs-popover-auto[x-placement^=left] {
    margin-right: .5rem
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^=left]>.arrow {
    right: -.5rem;
    width: .5rem;
    height: 1rem;
    margin: 3px 0
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^=left]>.arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^=left]>.arrow::after {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #333;
    background-color: #f7f7f7;
    border-bottom: 0 solid #ebebeb;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: 15px 10px;
    color: #696969
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media(prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    transition: opacity .15s ease
}

@media(prefers-reduced-motion:reduce) {

    .carousel-control-prev,
    .carousel-control-next {
        transition: none
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%
}

.carousel-control-prev-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNmZmYnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGQ9J001LjI1IDBsLTQgNCA0IDQgMS41LTEuNUw0LjI1IDRsMi41LTIuNUw1LjI1IDB6Jy8+PC9zdmc+)
}

.carousel-control-next-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNmZmYnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGQ9J00yLjc1IDBsLTEuNSAxLjVMMy43NSA0bC0yLjUgMi41TDIuNzUgOGw0LTQtNC00eicvPjwvc3ZnPg==)
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media(prefers-reduced-motion:reduce) {
    .carousel-indicators li {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: var(--primary) !important
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #0b9aa5 !important
}

.bg-secondary {
    background-color: #e1777d !important
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #15145b !important
}

.bg-success {
    background-color: #28a745 !important
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #1e7e34 !important
}

.bg-info {
    background-color: #17a2b8 !important
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #117a8b !important
}

.bg-warning {
    background-color: #f6b500 !important
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #c38f00 !important
}

.bg-danger {
    background-color: #dc3545 !important
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #bd2130 !important
}

.bg-light {
    background-color: #f8f9fa !important
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #dae0e5 !important
}

.bg-dark {
    background-color: #252525 !important
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #0c0c0c !important
}

.bg-accent {
    background-color: #eff6f7 !important
}

a.bg-accent:hover,
a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
    background-color: #cde3e6 !important
}

.bg-orange {
    background-color: #ff6935 !important
}

a.bg-orange:hover,
a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
    background-color: #ff4302 !important
}

.bg-white {
    background-color: #fff !important
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
    background-color: #e6e6e6 !important
}

.bg-indigo {
    background-color: #6969eb !important
}

a.bg-indigo:hover,
a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
    background-color: #3c3ce5 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid #eee !important
}

.border-top {
    border-top: 1px solid #eee !important
}

.border-right {
    border-right: 1px solid #eee !important
}

.border-bottom {
    border-bottom: 1px solid #eee !important
}

.border-left {
    border-left: 1px solid #eee !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: var(--primary) !important
}

.border-secondary {
    border-color: #1e1d85 !important
}

.border-success {
    border-color: #28a745 !important
}

.border-info {
    border-color: #17a2b8 !important
}

.border-warning {
    border-color: #f6b500 !important
}

.border-danger {
    border-color: #dc3545 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #252525 !important
}

.border-accent {
    border-color: #eff6f7 !important
}

.border-orange {
    border-color: #ff6935 !important
}

.border-white {
    border-color: #fff !important
}

.border-indigo {
    border-color: #6969eb !important
}

.border-white {
    border-color: #fff !important
}

.rounded-sm {
    border-radius: .2rem !important
}

.rounded {
    border-radius: 3px !important
}

.rounded-top {
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important
}

.rounded-right {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important
}

.rounded-bottom {
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important
}

.rounded-left {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important
}

.rounded-lg {
    border-radius: 5px !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
}

@media(min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-sm-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media(min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media(min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-lg-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media(min-width:1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media(min-width:1600px) {
    .d-xxl-none {
        display: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-print-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
}

.flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
}

.flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
}

.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
}

.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
}

.align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important
}

.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
}

@media(min-width:576px) {
    .flex-sm-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-sm-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-sm-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-sm-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-sm-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-sm-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-sm-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-sm-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media(min-width:768px) {
    .flex-md-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-md-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-md-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-md-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-md-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-md-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-md-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-md-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-md-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media(min-width:992px) {
    .flex-lg-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-lg-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-lg-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-lg-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-lg-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-lg-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-lg-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-lg-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media(min-width:1200px) {
    .flex-xl-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xl-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-xl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-xl-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media(min-width:1600px) {
    .flex-xxl-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xxl-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xxl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-xxl-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-xxl-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-xxl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xxl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-xxl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xxl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xxl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xxl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xxl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xxl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xxl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media(min-width:576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media(min-width:768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media(min-width:992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media(min-width:1200px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

@media(min-width:1600px) {
    .float-xxl-left {
        float: left !important
    }

    .float-xxl-right {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

@supports((position:-webkit-sticky) or (position:sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
}

.shadow {
    box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
}

.shadow-lg {
    box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
}

.shadow-none {
    box-shadow: none !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.vh-100 {
    height: 100vh !important
}

.m-0 {
    margin: 0 !important
}

.mt-0,
.my-0 {
    margin-top: 0 !important
}

.mr-0,
.mx-0 {
    margin-right: 0 !important
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
    margin-left: 0 !important
}

.m-1 {
    margin: .3125rem !important
}

.mt-1,
.my-1 {
    margin-top: .3125rem !important
}

.mr-1,
.mx-1 {
    margin-right: .3125rem !important
}

.mb-1,
.my-1 {
    margin-bottom: .3125rem !important
}

.ml-1,
.mx-1 {
    margin-left: .3125rem !important
}

.m-2 {
    margin: .625rem !important
}

.mt-2,
.my-2 {
    margin-top: .625rem !important
}

.mr-2,
.mx-2 {
    margin-right: .625rem !important
}

.mb-2,
.my-2 {
    margin-bottom: .625rem !important
}

.ml-2,
.mx-2 {
    margin-left: .625rem !important
}

.m-3 {
    margin: 1rem !important
}

.mt-3,
.my-3 {
    margin-top: 1rem !important
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important
}

.m-4 {
    margin: 1.25rem !important
}

.mt-4,
.my-4 {
    margin-top: 1.25rem !important
}

.mr-4,
.mx-4 {
    margin-right: 1.25rem !important
}

.mb-4,
.my-4 {
    margin-bottom: 1.25rem !important
}

.ml-4,
.mx-4 {
    margin-left: 1.25rem !important
}

.m-5 {
    margin: 1.5rem !important
}

.mt-5,
.my-5 {
    margin-top: 1.5rem !important
}

.mr-5,
.mx-5 {
    margin-right: 1.5rem !important
}

.mb-5,
.my-5 {
    margin-bottom: 1.5rem !important
}

.ml-5,
.mx-5 {
    margin-left: 1.5rem !important
}

.m-6 {
    margin: 1.875rem !important
}

.mt-6,
.my-6 {
    margin-top: 1.875rem !important
}

.mr-6,
.mx-6 {
    margin-right: 1.875rem !important
}

.mb-6,
.my-6 {
    margin-bottom: 1.875rem !important
}

.ml-6,
.mx-6 {
    margin-left: 1.875rem !important
}

.m-7 {
    margin: 2.5rem !important
}

.mt-7,
.my-7 {
    margin-top: 2.5rem !important
}

.mr-7,
.mx-7 {
    margin-right: 2.5rem !important
}

.mb-7,
.my-7 {
    margin-bottom: 2.5rem !important
}

.ml-7,
.mx-7 {
    margin-left: 2.5rem !important
}

.m-8 {
    margin: 3.125rem !important
}

.mt-8,
.my-8 {
    margin-top: 3.125rem !important
}

.mr-8,
.mx-8 {
    margin-right: 3.125rem !important
}

.mb-8,
.my-8 {
    margin-bottom: 3.125rem !important
}

.ml-8,
.mx-8 {
    margin-left: 3.125rem !important
}

.m-9 {
    margin: 3.75rem !important
}

.mt-9,
.my-9 {
    margin-top: 3.75rem !important
}

.mr-9,
.mx-9 {
    margin-right: 3.75rem !important
}

.mb-9,
.my-9 {
    margin-bottom: 3.75rem !important
}

.ml-9,
.mx-9 {
    margin-left: 3.75rem !important
}

.m-10 {
    margin: 4.375rem !important
}

.mt-10,
.my-10 {
    margin-top: 4.375rem !important
}

.mr-10,
.mx-10 {
    margin-right: 4.375rem !important
}

.mb-10,
.my-10 {
    margin-bottom: 4.375rem !important
}

.ml-10,
.mx-10 {
    margin-left: 4.375rem !important
}

.m-11 {
    margin: 5rem !important
}

.mt-11,
.my-11 {
    margin-top: 5rem !important
}

.mr-11,
.mx-11 {
    margin-right: 5rem !important
}

.mb-11,
.my-11 {
    margin-bottom: 5rem !important
}

.ml-11,
.mx-11 {
    margin-left: 5rem !important
}

.m-12 {
    margin: 5.625rem !important
}

.mt-12,
.my-12 {
    margin-top: 5.625rem !important
}

.mr-12,
.mx-12 {
    margin-right: 5.625rem !important
}

.mb-12,
.my-12 {
    margin-bottom: 5.625rem !important
}

.ml-12,
.mx-12 {
    margin-left: 5.625rem !important
}

.m-13 {
    margin: 6.25rem !important
}

.mt-13,
.my-13 {
    margin-top: 6.25rem !important
}

.mr-13,
.mx-13 {
    margin-right: 6.25rem !important
}

.mb-13,
.my-13 {
    margin-bottom: 6.25rem !important
}

.ml-13,
.mx-13 {
    margin-left: 6.25rem !important
}

.m-14 {
    margin: 6.875rem !important
}

.mt-14,
.my-14 {
    margin-top: 6.875rem !important
}

.mr-14,
.mx-14 {
    margin-right: 6.875rem !important
}

.mb-14,
.my-14 {
    margin-bottom: 6.875rem !important
}

.ml-14,
.mx-14 {
    margin-left: 6.875rem !important
}

.m-15 {
    margin: 7.5rem !important
}

.mt-15,
.my-15 {
    margin-top: 7.5rem !important
}

.mr-15,
.mx-15 {
    margin-right: 7.5rem !important
}

.mb-15,
.my-15 {
    margin-bottom: 7.5rem !important
}

.ml-15,
.mx-15 {
    margin-left: 7.5rem !important
}

.m-16 {
    margin: 9.375rem !important
}

.mt-16,
.my-16 {
    margin-top: 9.375rem !important
}

.mr-16,
.mx-16 {
    margin-right: 9.375rem !important
}

.mb-16,
.my-16 {
    margin-bottom: 9.375rem !important
}

.ml-16,
.mx-16 {
    margin-left: 9.375rem !important
}

.m-17 {
    margin: 10.625rem !important
}

.mt-17,
.my-17 {
    margin-top: 10.625rem !important
}

.mr-17,
.mx-17 {
    margin-right: 10.625rem !important
}

.mb-17,
.my-17 {
    margin-bottom: 10.625rem !important
}

.ml-17,
.mx-17 {
    margin-left: 10.625rem !important
}

.m-18 {
    margin: 13.75rem !important
}

.mt-18,
.my-18 {
    margin-top: 13.75rem !important
}

.mr-18,
.mx-18 {
    margin-right: 13.75rem !important
}

.mb-18,
.my-18 {
    margin-bottom: 13.75rem !important
}

.ml-18,
.mx-18 {
    margin-left: 13.75rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0,
.py-0 {
    padding-top: 0 !important
}

.pr-0,
.px-0 {
    padding-right: 0 !important
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important
}

.pl-0,
.px-0 {
    padding-left: 1.25 !important
}

.p-1 {
    padding: .3125rem !important
}

.pt-1,
.py-1 {
    padding-top: .3125rem !important
}

.pr-1,
.px-1 {
    padding-right: .3125rem !important
}

.pb-1,
.py-1 {
    padding-bottom: .3125rem !important
}

.pl-1,
.px-1 {
    padding-left: .3125rem !important
}

.p-2 {
    padding: .625rem !important
}

.pt-2,
.py-2 {
    padding-top: .625rem !important
}

.pr-2,
.px-2 {
    padding-right: .625rem !important
}

.pb-2,
.py-2 {
    padding-bottom: .625rem !important
}

.pl-2,
.px-2 {
    padding-left: .625rem !important
}

.p-3 {
    padding: 1rem !important
}

.pt-3,
.py-3 {
    padding-top: 1rem !important
}

.pr-3,
.px-3 {
    padding-right: 1rem !important
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
    padding-left: 1rem !important
}

.p-4 {
    padding: 1.25rem !important
}

.pt-4,
.py-4 {
    padding-top: 1.25rem !important
}

.pr-4,
.px-4 {
    padding-right: 1.25rem !important
}

.pb-4,
.py-4 {
    padding-bottom: 1.25rem !important
}

.pl-4,
.px-4 {
    padding-left: 1.25rem !important
}

.p-5 {
    padding: 1.5rem !important
}

.pt-5,
.py-5 {
    padding-top: 1.5rem !important
}

.pr-5,
.px-5 {
    padding-right: 1.5rem !important
}

.pb-5,
.py-5 {
    padding-bottom: 1.5rem !important
}

.pl-5,
.px-5 {
    padding-left: 1.5rem !important
}

.p-6 {
    padding: 1.875rem !important
}

.pt-6,
.py-6 {
    padding-top: 1.875rem !important
}

.pr-6,
.px-6 {
    padding-right: 1.875rem !important
}

.pb-6,
.py-6 {
    padding-bottom: 1.875rem !important
}

.pl-6,
.px-6 {
    padding-left: 1.875rem !important
}

.p-7 {
    padding: 2.5rem !important
}

.pt-7,
.py-7 {
    padding-top: 2.5rem !important
}

.pr-7,
.px-7 {
    padding-right: 2.5rem !important
}

.pb-7,
.py-7 {
    padding-bottom: 2.5rem !important
}

.pl-7,
.px-7 {
    padding-left: 2.5rem !important
}

.p-8 {
    padding: 3.125rem !important
}

.pt-8,
.py-8 {
    padding-top: 3.125rem !important
}

.pr-8,
.px-8 {
    padding-right: 3.125rem !important
}

.pb-8,
.py-8 {
    padding-bottom: 3.125rem !important
}

.pl-8,
.px-8 {
    padding-left: 3.125rem !important
}

.p-9 {
    padding: 3.75rem !important
}

.pt-9,
.py-9 {
    padding-top: 3.75rem !important
}

.pr-9,
.px-9 {
    padding-right: 3.75rem !important
}

.pb-9,
.py-9 {
    padding-bottom: 3.75rem !important
}

.pl-9,
.px-9 {
    padding-left: 3.75rem !important
}

.p-10 {
    padding: 4.375rem !important
}

.pt-10,
.py-10 {
    padding-top: 4.375rem !important
}

.pr-10,
.px-10 {
    padding-right: 4.375rem !important
}

.pb-10,
.py-10 {
    padding-bottom: 4.375rem !important
}

.pl-10,
.px-10 {
    padding-left: 4.375rem !important
}

.p-11 {
    padding: 5rem !important
}

.pt-11,
.py-11 {
    padding-top: 5rem !important
}

.pr-11,
.px-11 {
    padding-right: 5rem !important
}

.pb-11,
.py-11 {
    padding-bottom: 5rem !important
}

.pl-11,
.px-11 {
    padding-left: 5rem !important
}

.p-12 {
    padding: 5.625rem !important
}

.pt-12,
.py-12 {
    padding-top: 5.625rem !important
}

.pr-12,
.px-12 {
    padding-right: 5.625rem !important
}

.pb-12,
.py-12 {
    padding-bottom: 5.625rem !important
}

.pl-12,
.px-12 {
    padding-left: 5.625rem !important
}

.p-13 {
    padding: 6.25rem !important
}

.pt-13,
.py-13 {
    padding-top: 6.25rem !important
}

.pr-13,
.px-13 {
    padding-right: 6.25rem !important
}

.pb-13,
.py-13 {
    padding-bottom: 6.25rem !important
}

.pl-13,
.px-13 {
    padding-left: 6.25rem !important
}

.p-14 {
    padding: 6.875rem !important
}

.pt-14,
.py-14 {
    padding-top: 6.875rem !important
}

.pr-14,
.px-14 {
    padding-right: 6.875rem !important
}

.pb-14,
.py-14 {
    padding-bottom: 6.875rem !important
}

.pl-14,
.px-14 {
    padding-left: 6.875rem !important
}

.p-15 {
    padding: 7.5rem !important
}

.pt-15,
.py-15 {
    padding-top: 7.5rem !important
}

.pr-15,
.px-15 {
    padding-right: 7.5rem !important
}

.pb-15,
.py-15 {
    padding-bottom: 7.5rem !important
}

.pl-15,
.px-15 {
    padding-left: 7.5rem !important
}

.p-16 {
    padding: 9.375rem !important
}

.pt-16,
.py-16 {
    padding-top: 9.375rem !important
}

.pr-16,
.px-16 {
    padding-right: 9.375rem !important
}

.pb-16,
.py-16 {
    padding-bottom: 9.375rem !important
}

.pl-16,
.px-16 {
    padding-left: 9.375rem !important
}

.p-17 {
    padding: 10.625rem !important
}

.pt-17,
.py-17 {
    padding-top: 10.625rem !important
}

.pr-17,
.px-17 {
    padding-right: 10.625rem !important
}

.pb-17,
.py-17 {
    padding-bottom: 10.625rem !important
}

.pl-17,
.px-17 {
    padding-left: 10.625rem !important
}

.p-18 {
    padding: 13.75rem !important
}

.pt-18,
.py-18 {
    padding-top: 13.75rem !important
}

.pr-18,
.px-18 {
    padding-right: 13.75rem !important
}

.pb-18,
.py-18 {
    padding-bottom: 13.75rem !important
}

.pl-18,
.px-18 {
    padding-left: 13.75rem !important
}

.m-n1 {
    margin: -.3125rem !important
}

.mt-n1,
.my-n1 {
    margin-top: -.3125rem !important
}

.mr-n1,
.mx-n1 {
    margin-right: -.3125rem !important
}

.mb-n1,
.my-n1 {
    margin-bottom: -.3125rem !important
}

.ml-n1,
.mx-n1 {
    margin-left: -.3125rem !important
}

.m-n2 {
    margin: -.625rem !important
}

.mt-n2,
.my-n2 {
    margin-top: -.625rem !important
}

.mr-n2,
.mx-n2 {
    margin-right: -.625rem !important
}

.mb-n2,
.my-n2 {
    margin-bottom: -.625rem !important
}

.ml-n2,
.mx-n2 {
    margin-left: -.625rem !important
}

.m-n3 {
    margin: -1rem !important
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important
}

.m-n4 {
    margin: -1.25rem !important
}

.mt-n4,
.my-n4 {
    margin-top: -1.25rem !important
}

.mr-n4,
.mx-n4 {
    margin-right: -1.25rem !important
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.25rem !important
}

.ml-n4,
.mx-n4 {
    margin-left: -1.25rem !important
}

.m-n5 {
    margin: -1.5rem !important
}

.mt-n5,
.my-n5 {
    margin-top: -1.5rem !important
}

.mr-n5,
.mx-n5 {
    margin-right: -1.5rem !important
}

.mb-n5,
.my-n5 {
    margin-bottom: -1.5rem !important
}

.ml-n5,
.mx-n5 {
    margin-left: -1.5rem !important
}

.m-n6 {
    margin: -1.875rem !important
}

.mt-n6,
.my-n6 {
    margin-top: -1.875rem !important
}

.mr-n6,
.mx-n6 {
    margin-right: -1.875rem !important
}

.mb-n6,
.my-n6 {
    margin-bottom: -1.875rem !important
}

.ml-n6,
.mx-n6 {
    margin-left: -1.875rem !important
}

.m-n7 {
    margin: -2.5rem !important
}

.mt-n7,
.my-n7 {
    margin-top: -2.5rem !important
}

.mr-n7,
.mx-n7 {
    margin-right: -2.5rem !important
}

.mb-n7,
.my-n7 {
    margin-bottom: -2.5rem !important
}

.ml-n7,
.mx-n7 {
    margin-left: -2.5rem !important
}

.m-n8 {
    margin: -3.125rem !important
}

.mt-n8,
.my-n8 {
    margin-top: -3.125rem !important
}

.mr-n8,
.mx-n8 {
    margin-right: -3.125rem !important
}

.mb-n8,
.my-n8 {
    margin-bottom: -3.125rem !important
}

.ml-n8,
.mx-n8 {
    margin-left: -3.125rem !important
}

.m-n9 {
    margin: -3.75rem !important
}

.mt-n9,
.my-n9 {
    margin-top: -3.75rem !important
}

.mr-n9,
.mx-n9 {
    margin-right: -3.75rem !important
}

.mb-n9,
.my-n9 {
    margin-bottom: -3.75rem !important
}

.ml-n9,
.mx-n9 {
    margin-left: -3.75rem !important
}

.m-n10 {
    margin: -4.375rem !important
}

.mt-n10,
.my-n10 {
    margin-top: -4.375rem !important
}

.mr-n10,
.mx-n10 {
    margin-right: -4.375rem !important
}

.mb-n10,
.my-n10 {
    margin-bottom: -4.375rem !important
}

.ml-n10,
.mx-n10 {
    margin-left: -4.375rem !important
}

.m-n11 {
    margin: -5rem !important
}

.mt-n11,
.my-n11 {
    margin-top: -5rem !important
}

.mr-n11,
.mx-n11 {
    margin-right: -5rem !important
}

.mb-n11,
.my-n11 {
    margin-bottom: -5rem !important
}

.ml-n11,
.mx-n11 {
    margin-left: -5rem !important
}

.m-n12 {
    margin: -5.625rem !important
}

.mt-n12,
.my-n12 {
    margin-top: -5.625rem !important
}

.mr-n12,
.mx-n12 {
    margin-right: -5.625rem !important
}

.mb-n12,
.my-n12 {
    margin-bottom: -5.625rem !important
}

.ml-n12,
.mx-n12 {
    margin-left: -5.625rem !important
}

.m-n13 {
    margin: -6.25rem !important
}

.mt-n13,
.my-n13 {
    margin-top: -6.25rem !important
}

.mr-n13,
.mx-n13 {
    margin-right: -6.25rem !important
}

.mb-n13,
.my-n13 {
    margin-bottom: -6.25rem !important
}

.ml-n13,
.mx-n13 {
    margin-left: -6.25rem !important
}

.m-n14 {
    margin: -6.875rem !important
}

.mt-n14,
.my-n14 {
    margin-top: -6.875rem !important
}

.mr-n14,
.mx-n14 {
    margin-right: -6.875rem !important
}

.mb-n14,
.my-n14 {
    margin-bottom: -6.875rem !important
}

.ml-n14,
.mx-n14 {
    margin-left: -6.875rem !important
}

.m-n15 {
    margin: -7.5rem !important
}

.mt-n15,
.my-n15 {
    margin-top: -7.5rem !important
}

.mr-n15,
.mx-n15 {
    margin-right: -7.5rem !important
}

.mb-n15,
.my-n15 {
    margin-bottom: -7.5rem !important
}

.ml-n15,
.mx-n15 {
    margin-left: -7.5rem !important
}

.m-n16 {
    margin: -9.375rem !important
}

.mt-n16,
.my-n16 {
    margin-top: -9.375rem !important
}

.mr-n16,
.mx-n16 {
    margin-right: -9.375rem !important
}

.mb-n16,
.my-n16 {
    margin-bottom: -9.375rem !important
}

.ml-n16,
.mx-n16 {
    margin-left: -9.375rem !important
}

.m-n17 {
    margin: -10.625rem !important
}

.mt-n17,
.my-n17 {
    margin-top: -10.625rem !important
}

.mr-n17,
.mx-n17 {
    margin-right: -10.625rem !important
}

.mb-n17,
.my-n17 {
    margin-bottom: -10.625rem !important
}

.ml-n17,
.mx-n17 {
    margin-left: -10.625rem !important
}

.m-n18 {
    margin: -13.75rem !important
}

.mt-n18,
.my-n18 {
    margin-top: -13.75rem !important
}

.mr-n18,
.mx-n18 {
    margin-right: -13.75rem !important
}

.mb-n18,
.my-n18 {
    margin-bottom: -13.75rem !important
}

.ml-n18,
.mx-n18 {
    margin-left: -13.75rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto,
.my-auto {
    margin-top: auto !important
}

.mr-auto,
.mx-auto {
    margin-right: auto !important
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
    margin-left: auto !important
}

@media(min-width:576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important
    }

    .m-sm-1 {
        margin: .3125rem !important
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: .3125rem !important
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .3125rem !important
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .3125rem !important
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .3125rem !important
    }

    .m-sm-2 {
        margin: .625rem !important
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: .625rem !important
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .625rem !important
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .625rem !important
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .625rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important
    }

    .m-sm-4 {
        margin: 1.25rem !important
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.25rem !important
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.25rem !important
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.25rem !important
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.25rem !important
    }

    .m-sm-5 {
        margin: 1.5rem !important
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 1.5rem !important
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 1.5rem !important
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 1.5rem !important
    }

    .m-sm-6 {
        margin: 1.875rem !important
    }

    .mt-sm-6,
    .my-sm-6 {
        margin-top: 1.875rem !important
    }

    .mr-sm-6,
    .mx-sm-6 {
        margin-right: 1.875rem !important
    }

    .mb-sm-6,
    .my-sm-6 {
        margin-bottom: 1.875rem !important
    }

    .ml-sm-6,
    .mx-sm-6 {
        margin-left: 1.875rem !important
    }

    .m-sm-7 {
        margin: 2.5rem !important
    }

    .mt-sm-7,
    .my-sm-7 {
        margin-top: 2.5rem !important
    }

    .mr-sm-7,
    .mx-sm-7 {
        margin-right: 2.5rem !important
    }

    .mb-sm-7,
    .my-sm-7 {
        margin-bottom: 2.5rem !important
    }

    .ml-sm-7,
    .mx-sm-7 {
        margin-left: 2.5rem !important
    }

    .m-sm-8 {
        margin: 3.125rem !important
    }

    .mt-sm-8,
    .my-sm-8 {
        margin-top: 3.125rem !important
    }

    .mr-sm-8,
    .mx-sm-8 {
        margin-right: 3.125rem !important
    }

    .mb-sm-8,
    .my-sm-8 {
        margin-bottom: 3.125rem !important
    }

    .ml-sm-8,
    .mx-sm-8 {
        margin-left: 3.125rem !important
    }

    .m-sm-9 {
        margin: 3.75rem !important
    }

    .mt-sm-9,
    .my-sm-9 {
        margin-top: 3.75rem !important
    }

    .mr-sm-9,
    .mx-sm-9 {
        margin-right: 3.75rem !important
    }

    .mb-sm-9,
    .my-sm-9 {
        margin-bottom: 3.75rem !important
    }

    .ml-sm-9,
    .mx-sm-9 {
        margin-left: 3.75rem !important
    }

    .m-sm-10 {
        margin: 4.375rem !important
    }

    .mt-sm-10,
    .my-sm-10 {
        margin-top: 4.375rem !important
    }

    .mr-sm-10,
    .mx-sm-10 {
        margin-right: 4.375rem !important
    }

    .mb-sm-10,
    .my-sm-10 {
        margin-bottom: 4.375rem !important
    }

    .ml-sm-10,
    .mx-sm-10 {
        margin-left: 4.375rem !important
    }

    .m-sm-11 {
        margin: 5rem !important
    }

    .mt-sm-11,
    .my-sm-11 {
        margin-top: 5rem !important
    }

    .mr-sm-11,
    .mx-sm-11 {
        margin-right: 5rem !important
    }

    .mb-sm-11,
    .my-sm-11 {
        margin-bottom: 5rem !important
    }

    .ml-sm-11,
    .mx-sm-11 {
        margin-left: 5rem !important
    }

    .m-sm-12 {
        margin: 5.625rem !important
    }

    .mt-sm-12,
    .my-sm-12 {
        margin-top: 5.625rem !important
    }

    .mr-sm-12,
    .mx-sm-12 {
        margin-right: 5.625rem !important
    }

    .mb-sm-12,
    .my-sm-12 {
        margin-bottom: 5.625rem !important
    }

    .ml-sm-12,
    .mx-sm-12 {
        margin-left: 5.625rem !important
    }

    .m-sm-13 {
        margin: 6.25rem !important
    }

    .mt-sm-13,
    .my-sm-13 {
        margin-top: 6.25rem !important
    }

    .mr-sm-13,
    .mx-sm-13 {
        margin-right: 6.25rem !important
    }

    .mb-sm-13,
    .my-sm-13 {
        margin-bottom: 6.25rem !important
    }

    .ml-sm-13,
    .mx-sm-13 {
        margin-left: 6.25rem !important
    }

    .m-sm-14 {
        margin: 6.875rem !important
    }

    .mt-sm-14,
    .my-sm-14 {
        margin-top: 6.875rem !important
    }

    .mr-sm-14,
    .mx-sm-14 {
        margin-right: 6.875rem !important
    }

    .mb-sm-14,
    .my-sm-14 {
        margin-bottom: 6.875rem !important
    }

    .ml-sm-14,
    .mx-sm-14 {
        margin-left: 6.875rem !important
    }

    .m-sm-15 {
        margin: 7.5rem !important
    }

    .mt-sm-15,
    .my-sm-15 {
        margin-top: 7.5rem !important
    }

    .mr-sm-15,
    .mx-sm-15 {
        margin-right: 7.5rem !important
    }

    .mb-sm-15,
    .my-sm-15 {
        margin-bottom: 7.5rem !important
    }

    .ml-sm-15,
    .mx-sm-15 {
        margin-left: 7.5rem !important
    }

    .m-sm-16 {
        margin: 9.375rem !important
    }

    .mt-sm-16,
    .my-sm-16 {
        margin-top: 9.375rem !important
    }

    .mr-sm-16,
    .mx-sm-16 {
        margin-right: 9.375rem !important
    }

    .mb-sm-16,
    .my-sm-16 {
        margin-bottom: 9.375rem !important
    }

    .ml-sm-16,
    .mx-sm-16 {
        margin-left: 9.375rem !important
    }

    .m-sm-17 {
        margin: 10.625rem !important
    }

    .mt-sm-17,
    .my-sm-17 {
        margin-top: 10.625rem !important
    }

    .mr-sm-17,
    .mx-sm-17 {
        margin-right: 10.625rem !important
    }

    .mb-sm-17,
    .my-sm-17 {
        margin-bottom: 10.625rem !important
    }

    .ml-sm-17,
    .mx-sm-17 {
        margin-left: 10.625rem !important
    }

    .m-sm-18 {
        margin: 13.75rem !important
    }

    .mt-sm-18,
    .my-sm-18 {
        margin-top: 13.75rem !important
    }

    .mr-sm-18,
    .mx-sm-18 {
        margin-right: 13.75rem !important
    }

    .mb-sm-18,
    .my-sm-18 {
        margin-bottom: 13.75rem !important
    }

    .ml-sm-18,
    .mx-sm-18 {
        margin-left: 13.75rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important
    }

    .p-sm-1 {
        padding: .3125rem !important
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: .3125rem !important
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: .3125rem !important
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .3125rem !important
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: .3125rem !important
    }

    .p-sm-2 {
        padding: .625rem !important
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: .625rem !important
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: .625rem !important
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .625rem !important
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: .625rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important
    }

    .p-sm-4 {
        padding: 1.25rem !important
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.25rem !important
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.25rem !important
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.25rem !important
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.25rem !important
    }

    .p-sm-5 {
        padding: 1.5rem !important
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 1.5rem !important
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 1.5rem !important
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 1.5rem !important
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 1.5rem !important
    }

    .p-sm-6 {
        padding: 1.875rem !important
    }

    .pt-sm-6,
    .py-sm-6 {
        padding-top: 1.875rem !important
    }

    .pr-sm-6,
    .px-sm-6 {
        padding-right: 1.875rem !important
    }

    .pb-sm-6,
    .py-sm-6 {
        padding-bottom: 1.875rem !important
    }

    .pl-sm-6,
    .px-sm-6 {
        padding-left: 1.875rem !important
    }

    .p-sm-7 {
        padding: 2.5rem !important
    }

    .pt-sm-7,
    .py-sm-7 {
        padding-top: 2.5rem !important
    }

    .pr-sm-7,
    .px-sm-7 {
        padding-right: 2.5rem !important
    }

    .pb-sm-7,
    .py-sm-7 {
        padding-bottom: 2.5rem !important
    }

    .pl-sm-7,
    .px-sm-7 {
        padding-left: 2.5rem !important
    }

    .p-sm-8 {
        padding: 3.125rem !important
    }

    .pt-sm-8,
    .py-sm-8 {
        padding-top: 3.125rem !important
    }

    .pr-sm-8,
    .px-sm-8 {
        padding-right: 3.125rem !important
    }

    .pb-sm-8,
    .py-sm-8 {
        padding-bottom: 3.125rem !important
    }

    .pl-sm-8,
    .px-sm-8 {
        padding-left: 3.125rem !important
    }

    .p-sm-9 {
        padding: 3.75rem !important
    }

    .pt-sm-9,
    .py-sm-9 {
        padding-top: 3.75rem !important
    }

    .pr-sm-9,
    .px-sm-9 {
        padding-right: 3.75rem !important
    }

    .pb-sm-9,
    .py-sm-9 {
        padding-bottom: 3.75rem !important
    }

    .pl-sm-9,
    .px-sm-9 {
        padding-left: 3.75rem !important
    }

    .p-sm-10 {
        padding: 4.375rem !important
    }

    .pt-sm-10,
    .py-sm-10 {
        padding-top: 4.375rem !important
    }

    .pr-sm-10,
    .px-sm-10 {
        padding-right: 4.375rem !important
    }

    .pb-sm-10,
    .py-sm-10 {
        padding-bottom: 4.375rem !important
    }

    .pl-sm-10,
    .px-sm-10 {
        padding-left: 4.375rem !important
    }

    .p-sm-11 {
        padding: 5rem !important
    }

    .pt-sm-11,
    .py-sm-11 {
        padding-top: 5rem !important
    }

    .pr-sm-11,
    .px-sm-11 {
        padding-right: 5rem !important
    }

    .pb-sm-11,
    .py-sm-11 {
        padding-bottom: 5rem !important
    }

    .pl-sm-11,
    .px-sm-11 {
        padding-left: 5rem !important
    }

    .p-sm-12 {
        padding: 5.625rem !important
    }

    .pt-sm-12,
    .py-sm-12 {
        padding-top: 5.625rem !important
    }

    .pr-sm-12,
    .px-sm-12 {
        padding-right: 5.625rem !important
    }

    .pb-sm-12,
    .py-sm-12 {
        padding-bottom: 5.625rem !important
    }

    .pl-sm-12,
    .px-sm-12 {
        padding-left: 5.625rem !important
    }

    .p-sm-13 {
        padding: 6.25rem !important
    }

    .pt-sm-13,
    .py-sm-13 {
        padding-top: 6.25rem !important
    }

    .pr-sm-13,
    .px-sm-13 {
        padding-right: 6.25rem !important
    }

    .pb-sm-13,
    .py-sm-13 {
        padding-bottom: 6.25rem !important
    }

    .pl-sm-13,
    .px-sm-13 {
        padding-left: 6.25rem !important
    }

    .p-sm-14 {
        padding: 6.875rem !important
    }

    .pt-sm-14,
    .py-sm-14 {
        padding-top: 6.875rem !important
    }

    .pr-sm-14,
    .px-sm-14 {
        padding-right: 6.875rem !important
    }

    .pb-sm-14,
    .py-sm-14 {
        padding-bottom: 6.875rem !important
    }

    .pl-sm-14,
    .px-sm-14 {
        padding-left: 6.875rem !important
    }

    .p-sm-15 {
        padding: 7.5rem !important
    }

    .pt-sm-15,
    .py-sm-15 {
        padding-top: 7.5rem !important
    }

    .pr-sm-15,
    .px-sm-15 {
        padding-right: 7.5rem !important
    }

    .pb-sm-15,
    .py-sm-15 {
        padding-bottom: 7.5rem !important
    }

    .pl-sm-15,
    .px-sm-15 {
        padding-left: 7.5rem !important
    }

    .p-sm-16 {
        padding: 9.375rem !important
    }

    .pt-sm-16,
    .py-sm-16 {
        padding-top: 9.375rem !important
    }

    .pr-sm-16,
    .px-sm-16 {
        padding-right: 9.375rem !important
    }

    .pb-sm-16,
    .py-sm-16 {
        padding-bottom: 9.375rem !important
    }

    .pl-sm-16,
    .px-sm-16 {
        padding-left: 9.375rem !important
    }

    .p-sm-17 {
        padding: 10.625rem !important
    }

    .pt-sm-17,
    .py-sm-17 {
        padding-top: 10.625rem !important
    }

    .pr-sm-17,
    .px-sm-17 {
        padding-right: 10.625rem !important
    }

    .pb-sm-17,
    .py-sm-17 {
        padding-bottom: 10.625rem !important
    }

    .pl-sm-17,
    .px-sm-17 {
        padding-left: 10.625rem !important
    }

    .p-sm-18 {
        padding: 13.75rem !important
    }

    .pt-sm-18,
    .py-sm-18 {
        padding-top: 13.75rem !important
    }

    .pr-sm-18,
    .px-sm-18 {
        padding-right: 13.75rem !important
    }

    .pb-sm-18,
    .py-sm-18 {
        padding-bottom: 13.75rem !important
    }

    .pl-sm-18,
    .px-sm-18 {
        padding-left: 13.75rem !important
    }

    .m-sm-n1 {
        margin: -.3125rem !important
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -.3125rem !important
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -.3125rem !important
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -.3125rem !important
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -.3125rem !important
    }

    .m-sm-n2 {
        margin: -.625rem !important
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -.625rem !important
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -.625rem !important
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -.625rem !important
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -.625rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.25rem !important
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.25rem !important
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.25rem !important
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.25rem !important
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.25rem !important
    }

    .m-sm-n5 {
        margin: -1.5rem !important
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -1.5rem !important
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -1.5rem !important
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -1.5rem !important
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -1.5rem !important
    }

    .m-sm-n6 {
        margin: -1.875rem !important
    }

    .mt-sm-n6,
    .my-sm-n6 {
        margin-top: -1.875rem !important
    }

    .mr-sm-n6,
    .mx-sm-n6 {
        margin-right: -1.875rem !important
    }

    .mb-sm-n6,
    .my-sm-n6 {
        margin-bottom: -1.875rem !important
    }

    .ml-sm-n6,
    .mx-sm-n6 {
        margin-left: -1.875rem !important
    }

    .m-sm-n7 {
        margin: -2.5rem !important
    }

    .mt-sm-n7,
    .my-sm-n7 {
        margin-top: -2.5rem !important
    }

    .mr-sm-n7,
    .mx-sm-n7 {
        margin-right: -2.5rem !important
    }

    .mb-sm-n7,
    .my-sm-n7 {
        margin-bottom: -2.5rem !important
    }

    .ml-sm-n7,
    .mx-sm-n7 {
        margin-left: -2.5rem !important
    }

    .m-sm-n8 {
        margin: -3.125rem !important
    }

    .mt-sm-n8,
    .my-sm-n8 {
        margin-top: -3.125rem !important
    }

    .mr-sm-n8,
    .mx-sm-n8 {
        margin-right: -3.125rem !important
    }

    .mb-sm-n8,
    .my-sm-n8 {
        margin-bottom: -3.125rem !important
    }

    .ml-sm-n8,
    .mx-sm-n8 {
        margin-left: -3.125rem !important
    }

    .m-sm-n9 {
        margin: -3.75rem !important
    }

    .mt-sm-n9,
    .my-sm-n9 {
        margin-top: -3.75rem !important
    }

    .mr-sm-n9,
    .mx-sm-n9 {
        margin-right: -3.75rem !important
    }

    .mb-sm-n9,
    .my-sm-n9 {
        margin-bottom: -3.75rem !important
    }

    .ml-sm-n9,
    .mx-sm-n9 {
        margin-left: -3.75rem !important
    }

    .m-sm-n10 {
        margin: -4.375rem !important
    }

    .mt-sm-n10,
    .my-sm-n10 {
        margin-top: -4.375rem !important
    }

    .mr-sm-n10,
    .mx-sm-n10 {
        margin-right: -4.375rem !important
    }

    .mb-sm-n10,
    .my-sm-n10 {
        margin-bottom: -4.375rem !important
    }

    .ml-sm-n10,
    .mx-sm-n10 {
        margin-left: -4.375rem !important
    }

    .m-sm-n11 {
        margin: -5rem !important
    }

    .mt-sm-n11,
    .my-sm-n11 {
        margin-top: -5rem !important
    }

    .mr-sm-n11,
    .mx-sm-n11 {
        margin-right: -5rem !important
    }

    .mb-sm-n11,
    .my-sm-n11 {
        margin-bottom: -5rem !important
    }

    .ml-sm-n11,
    .mx-sm-n11 {
        margin-left: -5rem !important
    }

    .m-sm-n12 {
        margin: -5.625rem !important
    }

    .mt-sm-n12,
    .my-sm-n12 {
        margin-top: -5.625rem !important
    }

    .mr-sm-n12,
    .mx-sm-n12 {
        margin-right: -5.625rem !important
    }

    .mb-sm-n12,
    .my-sm-n12 {
        margin-bottom: -5.625rem !important
    }

    .ml-sm-n12,
    .mx-sm-n12 {
        margin-left: -5.625rem !important
    }

    .m-sm-n13 {
        margin: -6.25rem !important
    }

    .mt-sm-n13,
    .my-sm-n13 {
        margin-top: -6.25rem !important
    }

    .mr-sm-n13,
    .mx-sm-n13 {
        margin-right: -6.25rem !important
    }

    .mb-sm-n13,
    .my-sm-n13 {
        margin-bottom: -6.25rem !important
    }

    .ml-sm-n13,
    .mx-sm-n13 {
        margin-left: -6.25rem !important
    }

    .m-sm-n14 {
        margin: -6.875rem !important
    }

    .mt-sm-n14,
    .my-sm-n14 {
        margin-top: -6.875rem !important
    }

    .mr-sm-n14,
    .mx-sm-n14 {
        margin-right: -6.875rem !important
    }

    .mb-sm-n14,
    .my-sm-n14 {
        margin-bottom: -6.875rem !important
    }

    .ml-sm-n14,
    .mx-sm-n14 {
        margin-left: -6.875rem !important
    }

    .m-sm-n15 {
        margin: -7.5rem !important
    }

    .mt-sm-n15,
    .my-sm-n15 {
        margin-top: -7.5rem !important
    }

    .mr-sm-n15,
    .mx-sm-n15 {
        margin-right: -7.5rem !important
    }

    .mb-sm-n15,
    .my-sm-n15 {
        margin-bottom: -7.5rem !important
    }

    .ml-sm-n15,
    .mx-sm-n15 {
        margin-left: -7.5rem !important
    }

    .m-sm-n16 {
        margin: -9.375rem !important
    }

    .mt-sm-n16,
    .my-sm-n16 {
        margin-top: -9.375rem !important
    }

    .mr-sm-n16,
    .mx-sm-n16 {
        margin-right: -9.375rem !important
    }

    .mb-sm-n16,
    .my-sm-n16 {
        margin-bottom: -9.375rem !important
    }

    .ml-sm-n16,
    .mx-sm-n16 {
        margin-left: -9.375rem !important
    }

    .m-sm-n17 {
        margin: -10.625rem !important
    }

    .mt-sm-n17,
    .my-sm-n17 {
        margin-top: -10.625rem !important
    }

    .mr-sm-n17,
    .mx-sm-n17 {
        margin-right: -10.625rem !important
    }

    .mb-sm-n17,
    .my-sm-n17 {
        margin-bottom: -10.625rem !important
    }

    .ml-sm-n17,
    .mx-sm-n17 {
        margin-left: -10.625rem !important
    }

    .m-sm-n18 {
        margin: -13.75rem !important
    }

    .mt-sm-n18,
    .my-sm-n18 {
        margin-top: -13.75rem !important
    }

    .mr-sm-n18,
    .mx-sm-n18 {
        margin-right: -13.75rem !important
    }

    .mb-sm-n18,
    .my-sm-n18 {
        margin-bottom: -13.75rem !important
    }

    .ml-sm-n18,
    .mx-sm-n18 {
        margin-left: -13.75rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important
    }
}

@media(min-width:768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important
    }

    .m-md-1 {
        margin: .3125rem !important
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: .3125rem !important
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: .3125rem !important
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: .3125rem !important
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: .3125rem !important
    }

    .m-md-2 {
        margin: .625rem !important
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: .625rem !important
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: .625rem !important
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: .625rem !important
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: .625rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important
    }

    .m-md-4 {
        margin: 1.25rem !important
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.25rem !important
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.25rem !important
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.25rem !important
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.25rem !important
    }

    .m-md-5 {
        margin: 1.5rem !important
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 1.5rem !important
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 1.5rem !important
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 1.5rem !important
    }

    .m-md-6 {
        margin: 1.875rem !important
    }

    .mt-md-6,
    .my-md-6 {
        margin-top: 1.875rem !important
    }

    .mr-md-6,
    .mx-md-6 {
        margin-right: 1.875rem !important
    }

    .mb-md-6,
    .my-md-6 {
        margin-bottom: 1.875rem !important
    }

    .ml-md-6,
    .mx-md-6 {
        margin-left: 1.875rem !important
    }

    .m-md-7 {
        margin: 2.5rem !important
    }

    .mt-md-7,
    .my-md-7 {
        margin-top: 2.5rem !important
    }

    .mr-md-7,
    .mx-md-7 {
        margin-right: 2.5rem !important
    }

    .mb-md-7,
    .my-md-7 {
        margin-bottom: 2.5rem !important
    }

    .ml-md-7,
    .mx-md-7 {
        margin-left: 2.5rem !important
    }

    .m-md-8 {
        margin: 3.125rem !important
    }

    .mt-md-8,
    .my-md-8 {
        margin-top: 3.125rem !important
    }

    .mr-md-8,
    .mx-md-8 {
        margin-right: 3.125rem !important
    }

    .mb-md-8,
    .my-md-8 {
        margin-bottom: 3.125rem !important
    }

    .ml-md-8,
    .mx-md-8 {
        margin-left: 3.125rem !important
    }

    .m-md-9 {
        margin: 3.75rem !important
    }

    .mt-md-9,
    .my-md-9 {
        margin-top: 3.75rem !important
    }

    .mr-md-9,
    .mx-md-9 {
        margin-right: 3.75rem !important
    }

    .mb-md-9,
    .my-md-9 {
        margin-bottom: 3.75rem !important
    }

    .ml-md-9,
    .mx-md-9 {
        margin-left: 3.75rem !important
    }

    .m-md-10 {
        margin: 4.375rem !important
    }

    .mt-md-10,
    .my-md-10 {
        margin-top: 4.375rem !important
    }

    .mr-md-10,
    .mx-md-10 {
        margin-right: 4.375rem !important
    }

    .mb-md-10,
    .my-md-10 {
        margin-bottom: 4.375rem !important
    }

    .ml-md-10,
    .mx-md-10 {
        margin-left: 4.375rem !important
    }

    .m-md-11 {
        margin: 5rem !important
    }

    .mt-md-11,
    .my-md-11 {
        margin-top: 5rem !important
    }

    .mr-md-11,
    .mx-md-11 {
        margin-right: 5rem !important
    }

    .mb-md-11,
    .my-md-11 {
        margin-bottom: 5rem !important
    }

    .ml-md-11,
    .mx-md-11 {
        margin-left: 5rem !important
    }

    .m-md-12 {
        margin: 5.625rem !important
    }

    .mt-md-12,
    .my-md-12 {
        margin-top: 5.625rem !important
    }

    .mr-md-12,
    .mx-md-12 {
        margin-right: 5.625rem !important
    }

    .mb-md-12,
    .my-md-12 {
        margin-bottom: 5.625rem !important
    }

    .ml-md-12,
    .mx-md-12 {
        margin-left: 5.625rem !important
    }

    .m-md-13 {
        margin: 6.25rem !important
    }

    .mt-md-13,
    .my-md-13 {
        margin-top: 6.25rem !important
    }

    .mr-md-13,
    .mx-md-13 {
        margin-right: 6.25rem !important
    }

    .mb-md-13,
    .my-md-13 {
        margin-bottom: 6.25rem !important
    }

    .ml-md-13,
    .mx-md-13 {
        margin-left: 6.25rem !important
    }

    .m-md-14 {
        margin: 6.875rem !important
    }

    .mt-md-14,
    .my-md-14 {
        margin-top: 6.875rem !important
    }

    .mr-md-14,
    .mx-md-14 {
        margin-right: 6.875rem !important
    }

    .mb-md-14,
    .my-md-14 {
        margin-bottom: 6.875rem !important
    }

    .ml-md-14,
    .mx-md-14 {
        margin-left: 6.875rem !important
    }

    .m-md-15 {
        margin: 7.5rem !important
    }

    .mt-md-15,
    .my-md-15 {
        margin-top: 7.5rem !important
    }

    .mr-md-15,
    .mx-md-15 {
        margin-right: 7.5rem !important
    }

    .mb-md-15,
    .my-md-15 {
        margin-bottom: 7.5rem !important
    }

    .ml-md-15,
    .mx-md-15 {
        margin-left: 7.5rem !important
    }

    .m-md-16 {
        margin: 9.375rem !important
    }

    .mt-md-16,
    .my-md-16 {
        margin-top: 9.375rem !important
    }

    .mr-md-16,
    .mx-md-16 {
        margin-right: 9.375rem !important
    }

    .mb-md-16,
    .my-md-16 {
        margin-bottom: 9.375rem !important
    }

    .ml-md-16,
    .mx-md-16 {
        margin-left: 9.375rem !important
    }

    .m-md-17 {
        margin: 10.625rem !important
    }

    .mt-md-17,
    .my-md-17 {
        margin-top: 10.625rem !important
    }

    .mr-md-17,
    .mx-md-17 {
        margin-right: 10.625rem !important
    }

    .mb-md-17,
    .my-md-17 {
        margin-bottom: 10.625rem !important
    }

    .ml-md-17,
    .mx-md-17 {
        margin-left: 10.625rem !important
    }

    .m-md-18 {
        margin: 13.75rem !important
    }

    .mt-md-18,
    .my-md-18 {
        margin-top: 13.75rem !important
    }

    .mr-md-18,
    .mx-md-18 {
        margin-right: 13.75rem !important
    }

    .mb-md-18,
    .my-md-18 {
        margin-bottom: 13.75rem !important
    }

    .ml-md-18,
    .mx-md-18 {
        margin-left: 13.75rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important
    }

    .p-md-1 {
        padding: .3125rem !important
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: .3125rem !important
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: .3125rem !important
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: .3125rem !important
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: .3125rem !important
    }

    .p-md-2 {
        padding: .625rem !important
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: .625rem !important
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: .625rem !important
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: .625rem !important
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: .625rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important
    }

    .p-md-4 {
        padding: 1.25rem !important
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.25rem !important
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.25rem !important
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.25rem !important
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.25rem !important
    }

    .p-md-5 {
        padding: 1.5rem !important
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 1.5rem !important
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 1.5rem !important
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 1.5rem !important
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 1.5rem !important
    }

    .p-md-6 {
        padding: 1.875rem !important
    }

    .pt-md-6,
    .py-md-6 {
        padding-top: 1.875rem !important
    }

    .pr-md-6,
    .px-md-6 {
        padding-right: 1.875rem !important
    }

    .pb-md-6,
    .py-md-6 {
        padding-bottom: 1.875rem !important
    }

    .pl-md-6,
    .px-md-6 {
        padding-left: 1.875rem !important
    }

    .p-md-7 {
        padding: 2.5rem !important
    }

    .pt-md-7,
    .py-md-7 {
        padding-top: 2.5rem !important
    }

    .pr-md-7,
    .px-md-7 {
        padding-right: 2.5rem !important
    }

    .pb-md-7,
    .py-md-7 {
        padding-bottom: 2.5rem !important
    }

    .pl-md-7,
    .px-md-7 {
        padding-left: 2.5rem !important
    }

    .p-md-8 {
        padding: 3.125rem !important
    }

    .pt-md-8,
    .py-md-8 {
        padding-top: 3.125rem !important
    }

    .pr-md-8,
    .px-md-8 {
        padding-right: 3.125rem !important
    }

    .pb-md-8,
    .py-md-8 {
        padding-bottom: 3.125rem !important
    }

    .pl-md-8,
    .px-md-8 {
        padding-left: 3.125rem !important
    }

    .p-md-9 {
        padding: 3.75rem !important
    }

    .pt-md-9,
    .py-md-9 {
        padding-top: 3.75rem !important
    }

    .pr-md-9,
    .px-md-9 {
        padding-right: 3.75rem !important
    }

    .pb-md-9,
    .py-md-9 {
        padding-bottom: 3.75rem !important
    }

    .pl-md-9,
    .px-md-9 {
        padding-left: 3.75rem !important
    }

    .p-md-10 {
        padding: 4.375rem !important
    }

    .pt-md-10,
    .py-md-10 {
        padding-top: 4.375rem !important
    }

    .pr-md-10,
    .px-md-10 {
        padding-right: 4.375rem !important
    }

    .pb-md-10,
    .py-md-10 {
        padding-bottom: 4.375rem !important
    }

    .pl-md-10,
    .px-md-10 {
        padding-left: 4.375rem !important
    }

    .p-md-11 {
        padding: 5rem !important
    }

    .pt-md-11,
    .py-md-11 {
        padding-top: 5rem !important
    }

    .pr-md-11,
    .px-md-11 {
        padding-right: 5rem !important
    }

    .pb-md-11,
    .py-md-11 {
        padding-bottom: 5rem !important
    }

    .pl-md-11,
    .px-md-11 {
        padding-left: 5rem !important
    }

    .p-md-12 {
        padding: 5.625rem !important
    }

    .pt-md-12,
    .py-md-12 {
        padding-top: 5.625rem !important
    }

    .pr-md-12,
    .px-md-12 {
        padding-right: 5.625rem !important
    }

    .pb-md-12,
    .py-md-12 {
        padding-bottom: 5.625rem !important
    }

    .pl-md-12,
    .px-md-12 {
        padding-left: 5.625rem !important
    }

    .p-md-13 {
        padding: 6.25rem !important
    }

    .pt-md-13,
    .py-md-13 {
        padding-top: 6.25rem !important
    }

    .pr-md-13,
    .px-md-13 {
        padding-right: 6.25rem !important
    }

    .pb-md-13,
    .py-md-13 {
        padding-bottom: 6.25rem !important
    }

    .pl-md-13,
    .px-md-13 {
        padding-left: 6.25rem !important
    }

    .p-md-14 {
        padding: 6.875rem !important
    }

    .pt-md-14,
    .py-md-14 {
        padding-top: 6.875rem !important
    }

    .pr-md-14,
    .px-md-14 {
        padding-right: 6.875rem !important
    }

    .pb-md-14,
    .py-md-14 {
        padding-bottom: 6.875rem !important
    }

    .pl-md-14,
    .px-md-14 {
        padding-left: 6.875rem !important
    }

    .p-md-15 {
        padding: 7.5rem !important
    }

    .pt-md-15,
    .py-md-15 {
        padding-top: 7.5rem !important
    }

    .pr-md-15,
    .px-md-15 {
        padding-right: 7.5rem !important
    }

    .pb-md-15,
    .py-md-15 {
        padding-bottom: 7.5rem !important
    }

    .pl-md-15,
    .px-md-15 {
        padding-left: 7.5rem !important
    }

    .p-md-16 {
        padding: 9.375rem !important
    }

    .pt-md-16,
    .py-md-16 {
        padding-top: 9.375rem !important
    }

    .pr-md-16,
    .px-md-16 {
        padding-right: 9.375rem !important
    }

    .pb-md-16,
    .py-md-16 {
        padding-bottom: 9.375rem !important
    }

    .pl-md-16,
    .px-md-16 {
        padding-left: 9.375rem !important
    }

    .p-md-17 {
        padding: 10.625rem !important
    }

    .pt-md-17,
    .py-md-17 {
        padding-top: 10.625rem !important
    }

    .pr-md-17,
    .px-md-17 {
        padding-right: 10.625rem !important
    }

    .pb-md-17,
    .py-md-17 {
        padding-bottom: 10.625rem !important
    }

    .pl-md-17,
    .px-md-17 {
        padding-left: 10.625rem !important
    }

    .p-md-18 {
        padding: 13.75rem !important
    }

    .pt-md-18,
    .py-md-18 {
        padding-top: 13.75rem !important
    }

    .pr-md-18,
    .px-md-18 {
        padding-right: 13.75rem !important
    }

    .pb-md-18,
    .py-md-18 {
        padding-bottom: 13.75rem !important
    }

    .pl-md-18,
    .px-md-18 {
        padding-left: 13.75rem !important
    }

    .m-md-n1 {
        margin: -.3125rem !important
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -.3125rem !important
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -.3125rem !important
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -.3125rem !important
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -.3125rem !important
    }

    .m-md-n2 {
        margin: -.625rem !important
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -.625rem !important
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -.625rem !important
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -.625rem !important
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -.625rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important
    }

    .m-md-n4 {
        margin: -1.25rem !important
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.25rem !important
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.25rem !important
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.25rem !important
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.25rem !important
    }

    .m-md-n5 {
        margin: -1.5rem !important
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -1.5rem !important
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -1.5rem !important
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -1.5rem !important
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -1.5rem !important
    }

    .m-md-n6 {
        margin: -1.875rem !important
    }

    .mt-md-n6,
    .my-md-n6 {
        margin-top: -1.875rem !important
    }

    .mr-md-n6,
    .mx-md-n6 {
        margin-right: -1.875rem !important
    }

    .mb-md-n6,
    .my-md-n6 {
        margin-bottom: -1.875rem !important
    }

    .ml-md-n6,
    .mx-md-n6 {
        margin-left: -1.875rem !important
    }

    .m-md-n7 {
        margin: -2.5rem !important
    }

    .mt-md-n7,
    .my-md-n7 {
        margin-top: -2.5rem !important
    }

    .mr-md-n7,
    .mx-md-n7 {
        margin-right: -2.5rem !important
    }

    .mb-md-n7,
    .my-md-n7 {
        margin-bottom: -2.5rem !important
    }

    .ml-md-n7,
    .mx-md-n7 {
        margin-left: -2.5rem !important
    }

    .m-md-n8 {
        margin: -3.125rem !important
    }

    .mt-md-n8,
    .my-md-n8 {
        margin-top: -3.125rem !important
    }

    .mr-md-n8,
    .mx-md-n8 {
        margin-right: -3.125rem !important
    }

    .mb-md-n8,
    .my-md-n8 {
        margin-bottom: -3.125rem !important
    }

    .ml-md-n8,
    .mx-md-n8 {
        margin-left: -3.125rem !important
    }

    .m-md-n9 {
        margin: -3.75rem !important
    }

    .mt-md-n9,
    .my-md-n9 {
        margin-top: -3.75rem !important
    }

    .mr-md-n9,
    .mx-md-n9 {
        margin-right: -3.75rem !important
    }

    .mb-md-n9,
    .my-md-n9 {
        margin-bottom: -3.75rem !important
    }

    .ml-md-n9,
    .mx-md-n9 {
        margin-left: -3.75rem !important
    }

    .m-md-n10 {
        margin: -4.375rem !important
    }

    .mt-md-n10,
    .my-md-n10 {
        margin-top: -4.375rem !important
    }

    .mr-md-n10,
    .mx-md-n10 {
        margin-right: -4.375rem !important
    }

    .mb-md-n10,
    .my-md-n10 {
        margin-bottom: -4.375rem !important
    }

    .ml-md-n10,
    .mx-md-n10 {
        margin-left: -4.375rem !important
    }

    .m-md-n11 {
        margin: -5rem !important
    }

    .mt-md-n11,
    .my-md-n11 {
        margin-top: -5rem !important
    }

    .mr-md-n11,
    .mx-md-n11 {
        margin-right: -5rem !important
    }

    .mb-md-n11,
    .my-md-n11 {
        margin-bottom: -5rem !important
    }

    .ml-md-n11,
    .mx-md-n11 {
        margin-left: -5rem !important
    }

    .m-md-n12 {
        margin: -5.625rem !important
    }

    .mt-md-n12,
    .my-md-n12 {
        margin-top: -5.625rem !important
    }

    .mr-md-n12,
    .mx-md-n12 {
        margin-right: -5.625rem !important
    }

    .mb-md-n12,
    .my-md-n12 {
        margin-bottom: -5.625rem !important
    }

    .ml-md-n12,
    .mx-md-n12 {
        margin-left: -5.625rem !important
    }

    .m-md-n13 {
        margin: -6.25rem !important
    }

    .mt-md-n13,
    .my-md-n13 {
        margin-top: -6.25rem !important
    }

    .mr-md-n13,
    .mx-md-n13 {
        margin-right: -6.25rem !important
    }

    .mb-md-n13,
    .my-md-n13 {
        margin-bottom: -6.25rem !important
    }

    .ml-md-n13,
    .mx-md-n13 {
        margin-left: -6.25rem !important
    }

    .m-md-n14 {
        margin: -6.875rem !important
    }

    .mt-md-n14,
    .my-md-n14 {
        margin-top: -6.875rem !important
    }

    .mr-md-n14,
    .mx-md-n14 {
        margin-right: -6.875rem !important
    }

    .mb-md-n14,
    .my-md-n14 {
        margin-bottom: -6.875rem !important
    }

    .ml-md-n14,
    .mx-md-n14 {
        margin-left: -6.875rem !important
    }

    .m-md-n15 {
        margin: -7.5rem !important
    }

    .mt-md-n15,
    .my-md-n15 {
        margin-top: -7.5rem !important
    }

    .mr-md-n15,
    .mx-md-n15 {
        margin-right: -7.5rem !important
    }

    .mb-md-n15,
    .my-md-n15 {
        margin-bottom: -7.5rem !important
    }

    .ml-md-n15,
    .mx-md-n15 {
        margin-left: -7.5rem !important
    }

    .m-md-n16 {
        margin: -9.375rem !important
    }

    .mt-md-n16,
    .my-md-n16 {
        margin-top: -9.375rem !important
    }

    .mr-md-n16,
    .mx-md-n16 {
        margin-right: -9.375rem !important
    }

    .mb-md-n16,
    .my-md-n16 {
        margin-bottom: -9.375rem !important
    }

    .ml-md-n16,
    .mx-md-n16 {
        margin-left: -9.375rem !important
    }

    .m-md-n17 {
        margin: -10.625rem !important
    }

    .mt-md-n17,
    .my-md-n17 {
        margin-top: -10.625rem !important
    }

    .mr-md-n17,
    .mx-md-n17 {
        margin-right: -10.625rem !important
    }

    .mb-md-n17,
    .my-md-n17 {
        margin-bottom: -10.625rem !important
    }

    .ml-md-n17,
    .mx-md-n17 {
        margin-left: -10.625rem !important
    }

    .m-md-n18 {
        margin: -13.75rem !important
    }

    .mt-md-n18,
    .my-md-n18 {
        margin-top: -13.75rem !important
    }

    .mr-md-n18,
    .mx-md-n18 {
        margin-right: -13.75rem !important
    }

    .mb-md-n18,
    .my-md-n18 {
        margin-bottom: -13.75rem !important
    }

    .ml-md-n18,
    .mx-md-n18 {
        margin-left: -13.75rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important
    }
}

@media(min-width:992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important
    }

    .m-lg-1 {
        margin: .3125rem !important
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: .3125rem !important
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .3125rem !important
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .3125rem !important
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .3125rem !important
    }

    .m-lg-2 {
        margin: .625rem !important
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: .625rem !important
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .625rem !important
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .625rem !important
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .625rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important
    }

    .m-lg-4 {
        margin: 1.25rem !important
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.25rem !important
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.25rem !important
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.25rem !important
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.25rem !important
    }

    .m-lg-5 {
        margin: 1.5rem !important
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 1.5rem !important
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 1.5rem !important
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 1.5rem !important
    }

    .m-lg-6 {
        margin: 1.875rem !important
    }

    .mt-lg-6,
    .my-lg-6 {
        margin-top: 1.875rem !important
    }

    .mr-lg-6,
    .mx-lg-6 {
        margin-right: 1.875rem !important
    }

    .mb-lg-6,
    .my-lg-6 {
        margin-bottom: 1.875rem !important
    }

    .ml-lg-6,
    .mx-lg-6 {
        margin-left: 1.875rem !important
    }

    .m-lg-7 {
        margin: 2.5rem !important
    }

    .mt-lg-7,
    .my-lg-7 {
        margin-top: 2.5rem !important
    }

    .mr-lg-7,
    .mx-lg-7 {
        margin-right: 2.5rem !important
    }

    .mb-lg-7,
    .my-lg-7 {
        margin-bottom: 2.5rem !important
    }

    .ml-lg-7,
    .mx-lg-7 {
        margin-left: 2.5rem !important
    }

    .m-lg-8 {
        margin: 3.125rem !important
    }

    .mt-lg-8,
    .my-lg-8 {
        margin-top: 3.125rem !important
    }

    .mr-lg-8,
    .mx-lg-8 {
        margin-right: 3.125rem !important
    }

    .mb-lg-8,
    .my-lg-8 {
        margin-bottom: 3.125rem !important
    }

    .ml-lg-8,
    .mx-lg-8 {
        margin-left: 3.125rem !important
    }

    .m-lg-9 {
        margin: 3.75rem !important
    }

    .mt-lg-9,
    .my-lg-9 {
        margin-top: 3.75rem !important
    }

    .mr-lg-9,
    .mx-lg-9 {
        margin-right: 3.75rem !important
    }

    .mb-lg-9,
    .my-lg-9 {
        margin-bottom: 3.75rem !important
    }

    .ml-lg-9,
    .mx-lg-9 {
        margin-left: 3.75rem !important
    }

    .m-lg-10 {
        margin: 4.375rem !important
    }

    .mt-lg-10,
    .my-lg-10 {
        margin-top: 4.375rem !important
    }

    .mr-lg-10,
    .mx-lg-10 {
        margin-right: 4.375rem !important
    }

    .mb-lg-10,
    .my-lg-10 {
        margin-bottom: 4.375rem !important
    }

    .ml-lg-10,
    .mx-lg-10 {
        margin-left: 4.375rem !important
    }

    .m-lg-11 {
        margin: 5rem !important
    }

    .mt-lg-11,
    .my-lg-11 {
        margin-top: 5rem !important
    }

    .mr-lg-11,
    .mx-lg-11 {
        margin-right: 5rem !important
    }

    .mb-lg-11,
    .my-lg-11 {
        margin-bottom: 5rem !important
    }

    .ml-lg-11,
    .mx-lg-11 {
        margin-left: 5rem !important
    }

    .m-lg-12 {
        margin: 5.625rem !important
    }

    .mt-lg-12,
    .my-lg-12 {
        margin-top: 5.625rem !important
    }

    .mr-lg-12,
    .mx-lg-12 {
        margin-right: 5.625rem !important
    }

    .mb-lg-12,
    .my-lg-12 {
        margin-bottom: 5.625rem !important
    }

    .ml-lg-12,
    .mx-lg-12 {
        margin-left: 5.625rem !important
    }

    .m-lg-13 {
        margin: 6.25rem !important
    }

    .mt-lg-13,
    .my-lg-13 {
        margin-top: 6.25rem !important
    }

    .mr-lg-13,
    .mx-lg-13 {
        margin-right: 6.25rem !important
    }

    .mb-lg-13,
    .my-lg-13 {
        margin-bottom: 6.25rem !important
    }

    .ml-lg-13,
    .mx-lg-13 {
        margin-left: 6.25rem !important
    }

    .m-lg-14 {
        margin: 6.875rem !important
    }

    .mt-lg-14,
    .my-lg-14 {
        margin-top: 6.875rem !important
    }

    .mr-lg-14,
    .mx-lg-14 {
        margin-right: 6.875rem !important
    }

    .mb-lg-14,
    .my-lg-14 {
        margin-bottom: 6.875rem !important
    }

    .ml-lg-14,
    .mx-lg-14 {
        margin-left: 6.875rem !important
    }

    .m-lg-15 {
        margin: 7.5rem !important
    }

    .mt-lg-15,
    .my-lg-15 {
        margin-top: 7.5rem !important
    }

    .mr-lg-15,
    .mx-lg-15 {
        margin-right: 7.5rem !important
    }

    .mb-lg-15,
    .my-lg-15 {
        margin-bottom: 7.5rem !important
    }

    .ml-lg-15,
    .mx-lg-15 {
        margin-left: 7.5rem !important
    }

    .m-lg-16 {
        margin: 9.375rem !important
    }

    .mt-lg-16,
    .my-lg-16 {
        margin-top: 9.375rem !important
    }

    .mr-lg-16,
    .mx-lg-16 {
        margin-right: 9.375rem !important
    }

    .mb-lg-16,
    .my-lg-16 {
        margin-bottom: 9.375rem !important
    }

    .ml-lg-16,
    .mx-lg-16 {
        margin-left: 9.375rem !important
    }

    .m-lg-17 {
        margin: 10.625rem !important
    }

    .mt-lg-17,
    .my-lg-17 {
        margin-top: 10.625rem !important
    }

    .mr-lg-17,
    .mx-lg-17 {
        margin-right: 10.625rem !important
    }

    .mb-lg-17,
    .my-lg-17 {
        margin-bottom: 10.625rem !important
    }

    .ml-lg-17,
    .mx-lg-17 {
        margin-left: 10.625rem !important
    }

    .m-lg-18 {
        margin: 13.75rem !important
    }

    .mt-lg-18,
    .my-lg-18 {
        margin-top: 13.75rem !important
    }

    .mr-lg-18,
    .mx-lg-18 {
        margin-right: 13.75rem !important
    }

    .mb-lg-18,
    .my-lg-18 {
        margin-bottom: 13.75rem !important
    }

    .ml-lg-18,
    .mx-lg-18 {
        margin-left: 13.75rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important
    }

    .p-lg-1 {
        padding: .3125rem !important
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: .3125rem !important
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: .3125rem !important
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .3125rem !important
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: .3125rem !important
    }

    .p-lg-2 {
        padding: .625rem !important
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: .625rem !important
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: .625rem !important
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .625rem !important
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: .625rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important
    }

    .p-lg-4 {
        padding: 1.25rem !important
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.25rem !important
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.25rem !important
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.25rem !important
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 0rem !important
    }

    .p-lg-5 {
        padding: 1.5rem !important
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 1.5rem !important
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 1.5rem !important
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 1.5rem !important
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 1.5rem !important
    }

    .p-lg-6 {
        padding: 1.875rem !important
    }

    .pt-lg-6,
    .py-lg-6 {
        padding-top: 1.875rem !important
    }

    .pr-lg-6,
    .px-lg-6 {
        padding-right: 1.875rem !important
    }

    .pb-lg-6,
    .py-lg-6 {
        padding-bottom: 1.875rem !important
    }

    .pl-lg-6,
    .px-lg-6 {
        padding-left: 1.875rem !important
    }

    .p-lg-7 {
        padding: 2.5rem !important
    }

    .pt-lg-7,
    .py-lg-7 {
        padding-top: 2.5rem !important
    }

    .pr-lg-7,
    .px-lg-7 {
        padding-right: 2.5rem !important
    }

    .pb-lg-7,
    .py-lg-7 {
        padding-bottom: 2.5rem !important
    }

    .pl-lg-7,
    .px-lg-7 {
        padding-left: 2.5rem !important
    }

    .p-lg-8 {
        padding: 3.125rem !important
    }

    .pt-lg-8,
    .py-lg-8 {
        padding-top: 3.125rem !important
    }

    .pr-lg-8,
    .px-lg-8 {
        padding-right: 3.125rem !important
    }

    .pb-lg-8,
    .py-lg-8 {
        padding-bottom: 3.125rem !important
    }

    .pl-lg-8,
    .px-lg-8 {
        padding-left: 3.125rem !important
    }

    .p-lg-9 {
        padding: 3.75rem !important
    }

    .pt-lg-9,
    .py-lg-9 {
        padding-top: 3.75rem !important
    }

    .pr-lg-9,
    .px-lg-9 {
        padding-right: 3.75rem !important
    }

    .pb-lg-9,
    .py-lg-9 {
        padding-bottom: 3.75rem !important
    }

    .pl-lg-9,
    .px-lg-9 {
        padding-left: 3.75rem !important
    }

    .p-lg-10 {
        padding: 4.375rem !important
    }

    .pt-lg-10,
    .py-lg-10 {
        padding-top: 4.375rem !important
    }

    .pr-lg-10,
    .px-lg-10 {
        padding-right: 4.375rem !important
    }

    .pb-lg-10,
    .py-lg-10 {
        padding-bottom: 4.375rem !important
    }

    .pl-lg-10,
    .px-lg-10 {
        padding-left: 4.375rem !important
    }

    .p-lg-11 {
        padding: 5rem !important
    }

    .pt-lg-11,
    .py-lg-11 {
        padding-top: 5rem !important
    }

    .pr-lg-11,
    .px-lg-11 {
        padding-right: 5rem !important
    }

    .pb-lg-11,
    .py-lg-11 {
        padding-bottom: 5rem !important
    }

    .pl-lg-11,
    .px-lg-11 {
        padding-left: 5rem !important
    }

    .p-lg-12 {
        padding: 5.625rem !important
    }

    .pt-lg-12,
    .py-lg-12 {
        padding-top: 5.625rem !important
    }

    .pr-lg-12,
    .px-lg-12 {
        padding-right: 5.625rem !important
    }

    .pb-lg-12,
    .py-lg-12 {
        padding-bottom: 5.625rem !important
    }

    .pl-lg-12,
    .px-lg-12 {
        padding-left: 5.625rem !important
    }

    .p-lg-13 {
        padding: 6.25rem !important
    }

    .pt-lg-13,
    .py-lg-13 {
        padding-top: 6.25rem !important
    }

    .pr-lg-13,
    .px-lg-13 {
        padding-right: 6.25rem !important
    }

    .pb-lg-13,
    .py-lg-13 {
        padding-bottom: 6.25rem !important
    }

    .pl-lg-13,
    .px-lg-13 {
        padding-left: 6.25rem !important
    }

    .p-lg-14 {
        padding: 6.875rem !important
    }

    .pt-lg-14,
    .py-lg-14 {
        padding-top: 6.875rem !important
    }

    .pr-lg-14,
    .px-lg-14 {
        padding-right: 6.875rem !important
    }

    .pb-lg-14,
    .py-lg-14 {
        padding-bottom: 6.875rem !important
    }

    .pl-lg-14,
    .px-lg-14 {
        padding-left: 6.875rem !important
    }

    .p-lg-15 {
        padding: 7.5rem !important
    }

    .pt-lg-15,
    .py-lg-15 {
        padding-top: 7.5rem !important
    }

    .pr-lg-15,
    .px-lg-15 {
        padding-right: 7.5rem !important
    }

    .pb-lg-15,
    .py-lg-15 {
        padding-bottom: 7.5rem !important
    }

    .pl-lg-15,
    .px-lg-15 {
        padding-left: 7.5rem !important
    }

    .p-lg-16 {
        padding: 9.375rem !important
    }

    .pt-lg-16,
    .py-lg-16 {
        padding-top: 9.375rem !important
    }

    .pr-lg-16,
    .px-lg-16 {
        padding-right: 9.375rem !important
    }

    .pb-lg-16,
    .py-lg-16 {
        padding-bottom: 9.375rem !important
    }

    .pl-lg-16,
    .px-lg-16 {
        padding-left: 9.375rem !important
    }

    .p-lg-17 {
        padding: 10.625rem !important
    }

    .pt-lg-17,
    .py-lg-17 {
        padding-top: 10.625rem !important
    }

    .pr-lg-17,
    .px-lg-17 {
        padding-right: 10.625rem !important
    }

    .pb-lg-17,
    .py-lg-17 {
        padding-bottom: 10.625rem !important
    }

    .pl-lg-17,
    .px-lg-17 {
        padding-left: 10.625rem !important
    }

    .p-lg-18 {
        padding: 13.75rem !important
    }

    .pt-lg-18,
    .py-lg-18 {
        padding-top: 13.75rem !important
    }

    .pr-lg-18,
    .px-lg-18 {
        padding-right: 13.75rem !important
    }

    .pb-lg-18,
    .py-lg-18 {
        padding-bottom: 13.75rem !important
    }

    .pl-lg-18,
    .px-lg-18 {
        padding-left: 13.75rem !important
    }

    .m-lg-n1 {
        margin: -.3125rem !important
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -.3125rem !important
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -.3125rem !important
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -.3125rem !important
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -.3125rem !important
    }

    .m-lg-n2 {
        margin: -.625rem !important
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -.625rem !important
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -.625rem !important
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -.625rem !important
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -.625rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.25rem !important
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.25rem !important
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.25rem !important
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.25rem !important
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.25rem !important
    }

    .m-lg-n5 {
        margin: -1.5rem !important
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -1.5rem !important
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -1.5rem !important
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -1.5rem !important
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -1.5rem !important
    }

    .m-lg-n6 {
        margin: -1.875rem !important
    }

    .mt-lg-n6,
    .my-lg-n6 {
        margin-top: -1.875rem !important
    }

    .mr-lg-n6,
    .mx-lg-n6 {
        margin-right: -1.875rem !important
    }

    .mb-lg-n6,
    .my-lg-n6 {
        margin-bottom: -1.875rem !important
    }

    .ml-lg-n6,
    .mx-lg-n6 {
        margin-left: -1.875rem !important
    }

    .m-lg-n7 {
        margin: -2.5rem !important
    }

    .mt-lg-n7,
    .my-lg-n7 {
        margin-top: -2.5rem !important
    }

    .mr-lg-n7,
    .mx-lg-n7 {
        margin-right: -2.5rem !important
    }

    .mb-lg-n7,
    .my-lg-n7 {
        margin-bottom: -2.5rem !important
    }

    .ml-lg-n7,
    .mx-lg-n7 {
        margin-left: -2.5rem !important
    }

    .m-lg-n8 {
        margin: -3.125rem !important
    }

    .mt-lg-n8,
    .my-lg-n8 {
        margin-top: -3.125rem !important
    }

    .mr-lg-n8,
    .mx-lg-n8 {
        margin-right: -3.125rem !important
    }

    .mb-lg-n8,
    .my-lg-n8 {
        margin-bottom: -3.125rem !important
    }

    .ml-lg-n8,
    .mx-lg-n8 {
        margin-left: -3.125rem !important
    }

    .m-lg-n9 {
        margin: -3.75rem !important
    }

    .mt-lg-n9,
    .my-lg-n9 {
        margin-top: -3.75rem !important
    }

    .mr-lg-n9,
    .mx-lg-n9 {
        margin-right: -3.75rem !important
    }

    .mb-lg-n9,
    .my-lg-n9 {
        margin-bottom: -3.75rem !important
    }

    .ml-lg-n9,
    .mx-lg-n9 {
        margin-left: -3.75rem !important
    }

    .m-lg-n10 {
        margin: -4.375rem !important
    }

    .mt-lg-n10,
    .my-lg-n10 {
        margin-top: -4.375rem !important
    }

    .mr-lg-n10,
    .mx-lg-n10 {
        margin-right: -4.375rem !important
    }

    .mb-lg-n10,
    .my-lg-n10 {
        margin-bottom: -4.375rem !important
    }

    .ml-lg-n10,
    .mx-lg-n10 {
        margin-left: -4.375rem !important
    }

    .m-lg-n11 {
        margin: -5rem !important
    }

    .mt-lg-n11,
    .my-lg-n11 {
        margin-top: -5rem !important
    }

    .mr-lg-n11,
    .mx-lg-n11 {
        margin-right: -5rem !important
    }

    .mb-lg-n11,
    .my-lg-n11 {
        margin-bottom: -5rem !important
    }

    .ml-lg-n11,
    .mx-lg-n11 {
        margin-left: -5rem !important
    }

    .m-lg-n12 {
        margin: -5.625rem !important
    }

    .mt-lg-n12,
    .my-lg-n12 {
        margin-top: -5.625rem !important
    }

    .mr-lg-n12,
    .mx-lg-n12 {
        margin-right: -5.625rem !important
    }

    .mb-lg-n12,
    .my-lg-n12 {
        margin-bottom: -5.625rem !important
    }

    .ml-lg-n12,
    .mx-lg-n12 {
        margin-left: -5.625rem !important
    }

    .m-lg-n13 {
        margin: -6.25rem !important
    }

    .mt-lg-n13,
    .my-lg-n13 {
        margin-top: -6.25rem !important
    }

    .mr-lg-n13,
    .mx-lg-n13 {
        margin-right: -6.25rem !important
    }

    .mb-lg-n13,
    .my-lg-n13 {
        margin-bottom: -6.25rem !important
    }

    .ml-lg-n13,
    .mx-lg-n13 {
        margin-left: -6.25rem !important
    }

    .m-lg-n14 {
        margin: -6.875rem !important
    }

    .mt-lg-n14,
    .my-lg-n14 {
        margin-top: -6.875rem !important
    }

    .mr-lg-n14,
    .mx-lg-n14 {
        margin-right: -6.875rem !important
    }

    .mb-lg-n14,
    .my-lg-n14 {
        margin-bottom: -6.875rem !important
    }

    .ml-lg-n14,
    .mx-lg-n14 {
        margin-left: -6.875rem !important
    }

    .m-lg-n15 {
        margin: -7.5rem !important
    }

    .mt-lg-n15,
    .my-lg-n15 {
        margin-top: -7.5rem !important
    }

    .mr-lg-n15,
    .mx-lg-n15 {
        margin-right: -7.5rem !important
    }

    .mb-lg-n15,
    .my-lg-n15 {
        margin-bottom: -7.5rem !important
    }

    .ml-lg-n15,
    .mx-lg-n15 {
        margin-left: -7.5rem !important
    }

    .m-lg-n16 {
        margin: -9.375rem !important
    }

    .mt-lg-n16,
    .my-lg-n16 {
        margin-top: -9.375rem !important
    }

    .mr-lg-n16,
    .mx-lg-n16 {
        margin-right: -9.375rem !important
    }

    .mb-lg-n16,
    .my-lg-n16 {
        margin-bottom: -9.375rem !important
    }

    .ml-lg-n16,
    .mx-lg-n16 {
        margin-left: -9.375rem !important
    }

    .m-lg-n17 {
        margin: -10.625rem !important
    }

    .mt-lg-n17,
    .my-lg-n17 {
        margin-top: -10.625rem !important
    }

    .mr-lg-n17,
    .mx-lg-n17 {
        margin-right: -10.625rem !important
    }

    .mb-lg-n17,
    .my-lg-n17 {
        margin-bottom: -10.625rem !important
    }

    .ml-lg-n17,
    .mx-lg-n17 {
        margin-left: -10.625rem !important
    }

    .m-lg-n18 {
        margin: -13.75rem !important
    }

    .mt-lg-n18,
    .my-lg-n18 {
        margin-top: -13.75rem !important
    }

    .mr-lg-n18,
    .mx-lg-n18 {
        margin-right: -13.75rem !important
    }

    .mb-lg-n18,
    .my-lg-n18 {
        margin-bottom: -13.75rem !important
    }

    .ml-lg-n18,
    .mx-lg-n18 {
        margin-left: -13.75rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important
    }
}

@media(min-width:1200px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important
    }

    .m-xl-1 {
        margin: .3125rem !important
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: .3125rem !important
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .3125rem !important
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .3125rem !important
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .3125rem !important
    }

    .m-xl-2 {
        margin: .625rem !important
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: .625rem !important
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .625rem !important
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .625rem !important
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .625rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important
    }

    .m-xl-4 {
        margin: 1.25rem !important
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.25rem !important
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.25rem !important
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.25rem !important
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.25rem !important
    }

    .m-xl-5 {
        margin: 1.5rem !important
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 1.5rem !important
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 1.5rem !important
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 1.5rem !important
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 1.5rem !important
    }

    .m-xl-6 {
        margin: 1.875rem !important
    }

    .mt-xl-6,
    .my-xl-6 {
        margin-top: 1.875rem !important
    }

    .mr-xl-6,
    .mx-xl-6 {
        margin-right: 1.875rem !important
    }

    .mb-xl-6,
    .my-xl-6 {
        margin-bottom: 1.875rem !important
    }

    .ml-xl-6,
    .mx-xl-6 {
        margin-left: 1.875rem !important
    }

    .m-xl-7 {
        margin: 2.5rem !important
    }

    .mt-xl-7,
    .my-xl-7 {
        margin-top: 2.5rem !important
    }

    .mr-xl-7,
    .mx-xl-7 {
        margin-right: 2.5rem !important
    }

    .mb-xl-7,
    .my-xl-7 {
        margin-bottom: 2.5rem !important
    }

    .ml-xl-7,
    .mx-xl-7 {
        margin-left: 2.5rem !important
    }

    .m-xl-8 {
        margin: 3.125rem !important
    }

    .mt-xl-8,
    .my-xl-8 {
        margin-top: 3.125rem !important
    }

    .mr-xl-8,
    .mx-xl-8 {
        margin-right: 3.125rem !important
    }

    .mb-xl-8,
    .my-xl-8 {
        margin-bottom: 3.125rem !important
    }

    .ml-xl-8,
    .mx-xl-8 {
        margin-left: 3.125rem !important
    }

    .m-xl-9 {
        margin: 3.75rem !important
    }

    .mt-xl-9,
    .my-xl-9 {
        margin-top: 3.75rem !important
    }

    .mr-xl-9,
    .mx-xl-9 {
        margin-right: 3.75rem !important
    }

    .mb-xl-9,
    .my-xl-9 {
        margin-bottom: 3.75rem !important
    }

    .ml-xl-9,
    .mx-xl-9 {
        margin-left: 3.75rem !important
    }

    .m-xl-10 {
        margin: 4.375rem !important
    }

    .mt-xl-10,
    .my-xl-10 {
        margin-top: 4.375rem !important
    }

    .mr-xl-10,
    .mx-xl-10 {
        margin-right: 4.375rem !important
    }

    .mb-xl-10,
    .my-xl-10 {
        margin-bottom: 4.375rem !important
    }

    .ml-xl-10,
    .mx-xl-10 {
        margin-left: 4.375rem !important
    }

    .m-xl-11 {
        margin: 5rem !important
    }

    .mt-xl-11,
    .my-xl-11 {
        margin-top: 5rem !important
    }

    .mr-xl-11,
    .mx-xl-11 {
        margin-right: 5rem !important
    }

    .mb-xl-11,
    .my-xl-11 {
        margin-bottom: 5rem !important
    }

    .ml-xl-11,
    .mx-xl-11 {
        margin-left: 5rem !important
    }

    .m-xl-12 {
        margin: 5.625rem !important
    }

    .mt-xl-12,
    .my-xl-12 {
        margin-top: 5.625rem !important
    }

    .mr-xl-12,
    .mx-xl-12 {
        margin-right: 5.625rem !important
    }

    .mb-xl-12,
    .my-xl-12 {
        margin-bottom: 5.625rem !important
    }

    .ml-xl-12,
    .mx-xl-12 {
        margin-left: 5.625rem !important
    }

    .m-xl-13 {
        margin: 6.25rem !important
    }

    .mt-xl-13,
    .my-xl-13 {
        margin-top: 6.25rem !important
    }

    .mr-xl-13,
    .mx-xl-13 {
        margin-right: 6.25rem !important
    }

    .mb-xl-13,
    .my-xl-13 {
        margin-bottom: 6.25rem !important
    }

    .ml-xl-13,
    .mx-xl-13 {
        margin-left: 6.25rem !important
    }

    .m-xl-14 {
        margin: 6.875rem !important
    }

    .mt-xl-14,
    .my-xl-14 {
        margin-top: 6.875rem !important
    }

    .mr-xl-14,
    .mx-xl-14 {
        margin-right: 6.875rem !important
    }

    .mb-xl-14,
    .my-xl-14 {
        margin-bottom: 6.875rem !important
    }

    .ml-xl-14,
    .mx-xl-14 {
        margin-left: 6.875rem !important
    }

    .m-xl-15 {
        margin: 7.5rem !important
    }

    .mt-xl-15,
    .my-xl-15 {
        margin-top: 7.5rem !important
    }

    .mr-xl-15,
    .mx-xl-15 {
        margin-right: 7.5rem !important
    }

    .mb-xl-15,
    .my-xl-15 {
        margin-bottom: 7.5rem !important
    }

    .ml-xl-15,
    .mx-xl-15 {
        margin-left: 7.5rem !important
    }

    .m-xl-16 {
        margin: 9.375rem !important
    }

    .mt-xl-16,
    .my-xl-16 {
        margin-top: 9.375rem !important
    }

    .mr-xl-16,
    .mx-xl-16 {
        margin-right: 9.375rem !important
    }

    .mb-xl-16,
    .my-xl-16 {
        margin-bottom: 9.375rem !important
    }

    .ml-xl-16,
    .mx-xl-16 {
        margin-left: 9.375rem !important
    }

    .m-xl-17 {
        margin: 10.625rem !important
    }

    .mt-xl-17,
    .my-xl-17 {
        margin-top: 10.625rem !important
    }

    .mr-xl-17,
    .mx-xl-17 {
        margin-right: 10.625rem !important
    }

    .mb-xl-17,
    .my-xl-17 {
        margin-bottom: 10.625rem !important
    }

    .ml-xl-17,
    .mx-xl-17 {
        margin-left: 10.625rem !important
    }

    .m-xl-18 {
        margin: 13.75rem !important
    }

    .mt-xl-18,
    .my-xl-18 {
        margin-top: 13.75rem !important
    }

    .mr-xl-18,
    .mx-xl-18 {
        margin-right: 13.75rem !important
    }

    .mb-xl-18,
    .my-xl-18 {
        margin-bottom: 13.75rem !important
    }

    .ml-xl-18,
    .mx-xl-18 {
        margin-left: 13.75rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important
    }

    .p-xl-1 {
        padding: .3125rem !important
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: .3125rem !important
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: .3125rem !important
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .3125rem !important
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: .3125rem !important
    }

    .p-xl-2 {
        padding: .625rem !important
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: .625rem !important
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: .625rem !important
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .625rem !important
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: .625rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important
    }

    .p-xl-4 {
        padding: 1.25rem !important
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.25rem !important
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.25rem !important
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.25rem !important
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.25rem !important
    }

    .p-xl-5 {
        padding: 1.5rem !important
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 1.5rem !important
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 1.5rem !important
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 1.5rem !important
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 1.5rem !important
    }

    .p-xl-6 {
        padding: 1.875rem !important
    }

    .pt-xl-6,
    .py-xl-6 {
        padding-top: 1.875rem !important
    }

    .pr-xl-6,
    .px-xl-6 {
        padding-right: 1.875rem !important
    }

    .pb-xl-6,
    .py-xl-6 {
        padding-bottom: 1.875rem !important
    }

    .pl-xl-6,
    .px-xl-6 {
        padding-left: 1.875rem !important
    }

    .p-xl-7 {
        padding: 2.5rem !important
    }

    .pt-xl-7,
    .py-xl-7 {
        padding-top: 2.5rem !important
    }

    .pr-xl-7,
    .px-xl-7 {
        padding-right: 2.5rem !important
    }

    .pb-xl-7,
    .py-xl-7 {
        padding-bottom: 2.5rem !important
    }

    .pl-xl-7,
    .px-xl-7 {
        padding-left: 2.5rem !important
    }

    .p-xl-8 {
        padding: 3.125rem !important
    }

    .pt-xl-8,
    .py-xl-8 {
        padding-top: 3.125rem !important
    }

    .pr-xl-8,
    .px-xl-8 {
        padding-right: 3.125rem !important
    }

    .pb-xl-8,
    .py-xl-8 {
        padding-bottom: 3.125rem !important
    }

    .pl-xl-8,
    .px-xl-8 {
        padding-left: 3.125rem !important
    }

    .p-xl-9 {
        padding: 3.75rem !important
    }

    .pt-xl-9,
    .py-xl-9 {
        padding-top: 3.75rem !important
    }

    .pr-xl-9,
    .px-xl-9 {
        padding-right: 3.75rem !important
    }

    .pb-xl-9,
    .py-xl-9 {
        padding-bottom: 3.75rem !important
    }

    .pl-xl-9,
    .px-xl-9 {
        padding-left: 3.75rem !important
    }

    .p-xl-10 {
        padding: 4.375rem !important
    }

    .pt-xl-10,
    .py-xl-10 {
        padding-top: 4.375rem !important
    }

    .pr-xl-10,
    .px-xl-10 {
        padding-right: 4.375rem !important
    }

    .pb-xl-10,
    .py-xl-10 {
        padding-bottom: 4.375rem !important
    }

    .pl-xl-10,
    .px-xl-10 {
        padding-left: 4.375rem !important
    }

    .p-xl-11 {
        padding: 5rem !important
    }

    .pt-xl-11,
    .py-xl-11 {
        padding-top: 5rem !important
    }

    .pr-xl-11,
    .px-xl-11 {
        padding-right: 5rem !important
    }

    .pb-xl-11,
    .py-xl-11 {
        padding-bottom: 5rem !important
    }

    .pl-xl-11,
    .px-xl-11 {
        padding-left: 5rem !important
    }

    .p-xl-12 {
        padding: 5.625rem !important
    }

    .pt-xl-12,
    .py-xl-12 {
        padding-top: 5.625rem !important
    }

    .pr-xl-12,
    .px-xl-12 {
        padding-right: 5.625rem !important
    }

    .pb-xl-12,
    .py-xl-12 {
        padding-bottom: 5.625rem !important
    }

    .pl-xl-12,
    .px-xl-12 {
        padding-left: 5.625rem !important
    }

    .p-xl-13 {
        padding: 6.25rem !important
    }

    .pt-xl-13,
    .py-xl-13 {
        padding-top: 6.25rem !important
    }

    .pr-xl-13,
    .px-xl-13 {
        padding-right: 6.25rem !important
    }

    .pb-xl-13,
    .py-xl-13 {
        padding-bottom: 6.25rem !important
    }

    .pl-xl-13,
    .px-xl-13 {
        padding-left: 6.25rem !important
    }

    .p-xl-14 {
        padding: 6.875rem !important
    }

    .pt-xl-14,
    .py-xl-14 {
        padding-top: 6.875rem !important
    }

    .pr-xl-14,
    .px-xl-14 {
        padding-right: 6.875rem !important
    }

    .pb-xl-14,
    .py-xl-14 {
        padding-bottom: 6.875rem !important
    }

    .pl-xl-14,
    .px-xl-14 {
        padding-left: 6.875rem !important
    }

    .p-xl-15 {
        padding: 7.5rem !important
    }

    .pt-xl-15,
    .py-xl-15 {
        padding-top: 7.5rem !important
    }

    .pr-xl-15,
    .px-xl-15 {
        padding-right: 7.5rem !important
    }

    .pb-xl-15,
    .py-xl-15 {
        padding-bottom: 7.5rem !important
    }

    .pl-xl-15,
    .px-xl-15 {
        padding-left: 7.5rem !important
    }

    .p-xl-16 {
        padding: 9.375rem !important
    }

    .pt-xl-16,
    .py-xl-16 {
        padding-top: 9.375rem !important
    }

    .pr-xl-16,
    .px-xl-16 {
        padding-right: 9.375rem !important
    }

    .pb-xl-16,
    .py-xl-16 {
        padding-bottom: 9.375rem !important
    }

    .pl-xl-16,
    .px-xl-16 {
        padding-left: 9.375rem !important
    }

    .p-xl-17 {
        padding: 10.625rem !important
    }

    .pt-xl-17,
    .py-xl-17 {
        padding-top: 10.625rem !important
    }

    .pr-xl-17,
    .px-xl-17 {
        padding-right: 10.625rem !important
    }

    .pb-xl-17,
    .py-xl-17 {
        padding-bottom: 10.625rem !important
    }

    .pl-xl-17,
    .px-xl-17 {
        padding-left: 10.625rem !important
    }

    .p-xl-18 {
        padding: 13.75rem !important
    }

    .pt-xl-18,
    .py-xl-18 {
        padding-top: 13.75rem !important
    }

    .pr-xl-18,
    .px-xl-18 {
        padding-right: 13.75rem !important
    }

    .pb-xl-18,
    .py-xl-18 {
        padding-bottom: 13.75rem !important
    }

    .pl-xl-18,
    .px-xl-18 {
        padding-left: 13.75rem !important
    }

    .m-xl-n1 {
        margin: -.3125rem !important
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -.3125rem !important
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -.3125rem !important
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -.3125rem !important
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -.3125rem !important
    }

    .m-xl-n2 {
        margin: -.625rem !important
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -.625rem !important
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -.625rem !important
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -.625rem !important
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -.625rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.25rem !important
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.25rem !important
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.25rem !important
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.25rem !important
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.25rem !important
    }

    .m-xl-n5 {
        margin: -1.5rem !important
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -1.5rem !important
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -1.5rem !important
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -1.5rem !important
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -1.5rem !important
    }

    .m-xl-n6 {
        margin: -1.875rem !important
    }

    .mt-xl-n6,
    .my-xl-n6 {
        margin-top: -1.875rem !important
    }

    .mr-xl-n6,
    .mx-xl-n6 {
        margin-right: -1.875rem !important
    }

    .mb-xl-n6,
    .my-xl-n6 {
        margin-bottom: -1.875rem !important
    }

    .ml-xl-n6,
    .mx-xl-n6 {
        margin-left: -1.875rem !important
    }

    .m-xl-n7 {
        margin: -2.5rem !important
    }

    .mt-xl-n7,
    .my-xl-n7 {
        margin-top: -2.5rem !important
    }

    .mr-xl-n7,
    .mx-xl-n7 {
        margin-right: -2.5rem !important
    }

    .mb-xl-n7,
    .my-xl-n7 {
        margin-bottom: -2.5rem !important
    }

    .ml-xl-n7,
    .mx-xl-n7 {
        margin-left: -2.5rem !important
    }

    .m-xl-n8 {
        margin: -3.125rem !important
    }

    .mt-xl-n8,
    .my-xl-n8 {
        margin-top: -3.125rem !important
    }

    .mr-xl-n8,
    .mx-xl-n8 {
        margin-right: -3.125rem !important
    }

    .mb-xl-n8,
    .my-xl-n8 {
        margin-bottom: -3.125rem !important
    }

    .ml-xl-n8,
    .mx-xl-n8 {
        margin-left: -3.125rem !important
    }

    .m-xl-n9 {
        margin: -3.75rem !important
    }

    .mt-xl-n9,
    .my-xl-n9 {
        margin-top: -3.75rem !important
    }

    .mr-xl-n9,
    .mx-xl-n9 {
        margin-right: -3.75rem !important
    }

    .mb-xl-n9,
    .my-xl-n9 {
        margin-bottom: -3.75rem !important
    }

    .ml-xl-n9,
    .mx-xl-n9 {
        margin-left: -3.75rem !important
    }

    .m-xl-n10 {
        margin: -4.375rem !important
    }

    .mt-xl-n10,
    .my-xl-n10 {
        margin-top: -4.375rem !important
    }

    .mr-xl-n10,
    .mx-xl-n10 {
        margin-right: -4.375rem !important
    }

    .mb-xl-n10,
    .my-xl-n10 {
        margin-bottom: -4.375rem !important
    }

    .ml-xl-n10,
    .mx-xl-n10 {
        margin-left: -4.375rem !important
    }

    .m-xl-n11 {
        margin: -5rem !important
    }

    .mt-xl-n11,
    .my-xl-n11 {
        margin-top: -5rem !important
    }

    .mr-xl-n11,
    .mx-xl-n11 {
        margin-right: -5rem !important
    }

    .mb-xl-n11,
    .my-xl-n11 {
        margin-bottom: -5rem !important
    }

    .ml-xl-n11,
    .mx-xl-n11 {
        margin-left: -5rem !important
    }

    .m-xl-n12 {
        margin: -5.625rem !important
    }

    .mt-xl-n12,
    .my-xl-n12 {
        margin-top: -5.625rem !important
    }

    .mr-xl-n12,
    .mx-xl-n12 {
        margin-right: -5.625rem !important
    }

    .mb-xl-n12,
    .my-xl-n12 {
        margin-bottom: -5.625rem !important
    }

    .ml-xl-n12,
    .mx-xl-n12 {
        margin-left: -5.625rem !important
    }

    .m-xl-n13 {
        margin: -6.25rem !important
    }

    .mt-xl-n13,
    .my-xl-n13 {
        margin-top: -6.25rem !important
    }

    .mr-xl-n13,
    .mx-xl-n13 {
        margin-right: -6.25rem !important
    }

    .mb-xl-n13,
    .my-xl-n13 {
        margin-bottom: -6.25rem !important
    }

    .ml-xl-n13,
    .mx-xl-n13 {
        margin-left: -6.25rem !important
    }

    .m-xl-n14 {
        margin: -6.875rem !important
    }

    .mt-xl-n14,
    .my-xl-n14 {
        margin-top: -6.875rem !important
    }

    .mr-xl-n14,
    .mx-xl-n14 {
        margin-right: -6.875rem !important
    }

    .mb-xl-n14,
    .my-xl-n14 {
        margin-bottom: -6.875rem !important
    }

    .ml-xl-n14,
    .mx-xl-n14 {
        margin-left: -6.875rem !important
    }

    .m-xl-n15 {
        margin: -7.5rem !important
    }

    .mt-xl-n15,
    .my-xl-n15 {
        margin-top: -7.5rem !important
    }

    .mr-xl-n15,
    .mx-xl-n15 {
        margin-right: -7.5rem !important
    }

    .mb-xl-n15,
    .my-xl-n15 {
        margin-bottom: -7.5rem !important
    }

    .ml-xl-n15,
    .mx-xl-n15 {
        margin-left: -7.5rem !important
    }

    .m-xl-n16 {
        margin: -9.375rem !important
    }

    .mt-xl-n16,
    .my-xl-n16 {
        margin-top: -9.375rem !important
    }

    .mr-xl-n16,
    .mx-xl-n16 {
        margin-right: -9.375rem !important
    }

    .mb-xl-n16,
    .my-xl-n16 {
        margin-bottom: -9.375rem !important
    }

    .ml-xl-n16,
    .mx-xl-n16 {
        margin-left: -9.375rem !important
    }

    .m-xl-n17 {
        margin: -10.625rem !important
    }

    .mt-xl-n17,
    .my-xl-n17 {
        margin-top: -10.625rem !important
    }

    .mr-xl-n17,
    .mx-xl-n17 {
        margin-right: -10.625rem !important
    }

    .mb-xl-n17,
    .my-xl-n17 {
        margin-bottom: -10.625rem !important
    }

    .ml-xl-n17,
    .mx-xl-n17 {
        margin-left: -10.625rem !important
    }

    .m-xl-n18 {
        margin: -13.75rem !important
    }

    .mt-xl-n18,
    .my-xl-n18 {
        margin-top: -13.75rem !important
    }

    .mr-xl-n18,
    .mx-xl-n18 {
        margin-right: -13.75rem !important
    }

    .mb-xl-n18,
    .my-xl-n18 {
        margin-bottom: -13.75rem !important
    }

    .ml-xl-n18,
    .mx-xl-n18 {
        margin-left: -13.75rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important
    }
}

@media(min-width:1600px) {
    .m-xxl-0 {
        margin: 0 !important
    }

    .mt-xxl-0,
    .my-xxl-0 {
        margin-top: 0 !important
    }

    .mr-xxl-0,
    .mx-xxl-0 {
        margin-right: 0 !important
    }

    .mb-xxl-0,
    .my-xxl-0 {
        margin-bottom: 0 !important
    }

    .ml-xxl-0,
    .mx-xxl-0 {
        margin-left: 0 !important
    }

    .m-xxl-1 {
        margin: .3125rem !important
    }

    .mt-xxl-1,
    .my-xxl-1 {
        margin-top: .3125rem !important
    }

    .mr-xxl-1,
    .mx-xxl-1 {
        margin-right: .3125rem !important
    }

    .mb-xxl-1,
    .my-xxl-1 {
        margin-bottom: .3125rem !important
    }

    .ml-xxl-1,
    .mx-xxl-1 {
        margin-left: .3125rem !important
    }

    .m-xxl-2 {
        margin: .625rem !important
    }

    .mt-xxl-2,
    .my-xxl-2 {
        margin-top: .625rem !important
    }

    .mr-xxl-2,
    .mx-xxl-2 {
        margin-right: .625rem !important
    }

    .mb-xxl-2,
    .my-xxl-2 {
        margin-bottom: .625rem !important
    }

    .ml-xxl-2,
    .mx-xxl-2 {
        margin-left: .625rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .mt-xxl-3,
    .my-xxl-3 {
        margin-top: 1rem !important
    }

    .mr-xxl-3,
    .mx-xxl-3 {
        margin-right: 1rem !important
    }

    .mb-xxl-3,
    .my-xxl-3 {
        margin-bottom: 1rem !important
    }

    .ml-xxl-3,
    .mx-xxl-3 {
        margin-left: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.25rem !important
    }

    .mt-xxl-4,
    .my-xxl-4 {
        margin-top: 1.25rem !important
    }

    .mr-xxl-4,
    .mx-xxl-4 {
        margin-right: 1.25rem !important
    }

    .mb-xxl-4,
    .my-xxl-4 {
        margin-bottom: 1.25rem !important
    }

    .ml-xxl-4,
    .mx-xxl-4 {
        margin-left: 1.25rem !important
    }

    .m-xxl-5 {
        margin: 1.5rem !important
    }

    .mt-xxl-5,
    .my-xxl-5 {
        margin-top: 1.5rem !important
    }

    .mr-xxl-5,
    .mx-xxl-5 {
        margin-right: 1.5rem !important
    }

    .mb-xxl-5,
    .my-xxl-5 {
        margin-bottom: 1.5rem !important
    }

    .ml-xxl-5,
    .mx-xxl-5 {
        margin-left: 1.5rem !important
    }

    .m-xxl-6 {
        margin: 1.875rem !important
    }

    .mt-xxl-6,
    .my-xxl-6 {
        margin-top: 1.875rem !important
    }

    .mr-xxl-6,
    .mx-xxl-6 {
        margin-right: 1.875rem !important
    }

    .mb-xxl-6,
    .my-xxl-6 {
        margin-bottom: 1.875rem !important
    }

    .ml-xxl-6,
    .mx-xxl-6 {
        margin-left: 1.875rem !important
    }

    .m-xxl-7 {
        margin: 2.5rem !important
    }

    .mt-xxl-7,
    .my-xxl-7 {
        margin-top: 2.5rem !important
    }

    .mr-xxl-7,
    .mx-xxl-7 {
        margin-right: 2.5rem !important
    }

    .mb-xxl-7,
    .my-xxl-7 {
        margin-bottom: 2.5rem !important
    }

    .ml-xxl-7,
    .mx-xxl-7 {
        margin-left: 2.5rem !important
    }

    .m-xxl-8 {
        margin: 3.125rem !important
    }

    .mt-xxl-8,
    .my-xxl-8 {
        margin-top: 3.125rem !important
    }

    .mr-xxl-8,
    .mx-xxl-8 {
        margin-right: 3.125rem !important
    }

    .mb-xxl-8,
    .my-xxl-8 {
        margin-bottom: 3.125rem !important
    }

    .ml-xxl-8,
    .mx-xxl-8 {
        margin-left: 3.125rem !important
    }

    .m-xxl-9 {
        margin: 3.75rem !important
    }

    .mt-xxl-9,
    .my-xxl-9 {
        margin-top: 3.75rem !important
    }

    .mr-xxl-9,
    .mx-xxl-9 {
        margin-right: 3.75rem !important
    }

    .mb-xxl-9,
    .my-xxl-9 {
        margin-bottom: 3.75rem !important
    }

    .ml-xxl-9,
    .mx-xxl-9 {
        margin-left: 3.75rem !important
    }

    .m-xxl-10 {
        margin: 4.375rem !important
    }

    .mt-xxl-10,
    .my-xxl-10 {
        margin-top: 4.375rem !important
    }

    .mr-xxl-10,
    .mx-xxl-10 {
        margin-right: 4.375rem !important
    }

    .mb-xxl-10,
    .my-xxl-10 {
        margin-bottom: 4.375rem !important
    }

    .ml-xxl-10,
    .mx-xxl-10 {
        margin-left: 4.375rem !important
    }

    .m-xxl-11 {
        margin: 5rem !important
    }

    .mt-xxl-11,
    .my-xxl-11 {
        margin-top: 5rem !important
    }

    .mr-xxl-11,
    .mx-xxl-11 {
        margin-right: 5rem !important
    }

    .mb-xxl-11,
    .my-xxl-11 {
        margin-bottom: 5rem !important
    }

    .ml-xxl-11,
    .mx-xxl-11 {
        margin-left: 5rem !important
    }

    .m-xxl-12 {
        margin: 5.625rem !important
    }

    .mt-xxl-12,
    .my-xxl-12 {
        margin-top: 5.625rem !important
    }

    .mr-xxl-12,
    .mx-xxl-12 {
        margin-right: 5.625rem !important
    }

    .mb-xxl-12,
    .my-xxl-12 {
        margin-bottom: 5.625rem !important
    }

    .ml-xxl-12,
    .mx-xxl-12 {
        margin-left: 5.625rem !important
    }

    .m-xxl-13 {
        margin: 6.25rem !important
    }

    .mt-xxl-13,
    .my-xxl-13 {
        margin-top: 6.25rem !important
    }

    .mr-xxl-13,
    .mx-xxl-13 {
        margin-right: 6.25rem !important
    }

    .mb-xxl-13,
    .my-xxl-13 {
        margin-bottom: 6.25rem !important
    }

    .ml-xxl-13,
    .mx-xxl-13 {
        margin-left: 6.25rem !important
    }

    .m-xxl-14 {
        margin: 6.875rem !important
    }

    .mt-xxl-14,
    .my-xxl-14 {
        margin-top: 6.875rem !important
    }

    .mr-xxl-14,
    .mx-xxl-14 {
        margin-right: 6.875rem !important
    }

    .mb-xxl-14,
    .my-xxl-14 {
        margin-bottom: 6.875rem !important
    }

    .ml-xxl-14,
    .mx-xxl-14 {
        margin-left: 6.875rem !important
    }

    .m-xxl-15 {
        margin: 7.5rem !important
    }

    .mt-xxl-15,
    .my-xxl-15 {
        margin-top: 7.5rem !important
    }

    .mr-xxl-15,
    .mx-xxl-15 {
        margin-right: 7.5rem !important
    }

    .mb-xxl-15,
    .my-xxl-15 {
        margin-bottom: 7.5rem !important
    }

    .ml-xxl-15,
    .mx-xxl-15 {
        margin-left: 7.5rem !important
    }

    .m-xxl-16 {
        margin: 9.375rem !important
    }

    .mt-xxl-16,
    .my-xxl-16 {
        margin-top: 9.375rem !important
    }

    .mr-xxl-16,
    .mx-xxl-16 {
        margin-right: 9.375rem !important
    }

    .mb-xxl-16,
    .my-xxl-16 {
        margin-bottom: 9.375rem !important
    }

    .ml-xxl-16,
    .mx-xxl-16 {
        margin-left: 9.375rem !important
    }

    .m-xxl-17 {
        margin: 10.625rem !important
    }

    .mt-xxl-17,
    .my-xxl-17 {
        margin-top: 10.625rem !important
    }

    .mr-xxl-17,
    .mx-xxl-17 {
        margin-right: 10.625rem !important
    }

    .mb-xxl-17,
    .my-xxl-17 {
        margin-bottom: 10.625rem !important
    }

    .ml-xxl-17,
    .mx-xxl-17 {
        margin-left: 10.625rem !important
    }

    .m-xxl-18 {
        margin: 13.75rem !important
    }

    .mt-xxl-18,
    .my-xxl-18 {
        margin-top: 13.75rem !important
    }

    .mr-xxl-18,
    .mx-xxl-18 {
        margin-right: 13.75rem !important
    }

    .mb-xxl-18,
    .my-xxl-18 {
        margin-bottom: 13.75rem !important
    }

    .ml-xxl-18,
    .mx-xxl-18 {
        margin-left: 13.75rem !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .pt-xxl-0,
    .py-xxl-0 {
        padding-top: 0 !important
    }

    .pr-xxl-0,
    .px-xxl-0 {
        padding-right: 0 !important
    }

    .pb-xxl-0,
    .py-xxl-0 {
        padding-bottom: 0 !important
    }

    .pl-xxl-0,
    .px-xxl-0 {
        padding-left: 0 !important
    }

    .p-xxl-1 {
        padding: .3125rem !important
    }

    .pt-xxl-1,
    .py-xxl-1 {
        padding-top: .3125rem !important
    }

    .pr-xxl-1,
    .px-xxl-1 {
        padding-right: .3125rem !important
    }

    .pb-xxl-1,
    .py-xxl-1 {
        padding-bottom: .3125rem !important
    }

    .pl-xxl-1,
    .px-xxl-1 {
        padding-left: .3125rem !important
    }

    .p-xxl-2 {
        padding: .625rem !important
    }

    .pt-xxl-2,
    .py-xxl-2 {
        padding-top: .625rem !important
    }

    .pr-xxl-2,
    .px-xxl-2 {
        padding-right: .625rem !important
    }

    .pb-xxl-2,
    .py-xxl-2 {
        padding-bottom: .625rem !important
    }

    .pl-xxl-2,
    .px-xxl-2 {
        padding-left: .625rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .pt-xxl-3,
    .py-xxl-3 {
        padding-top: 1rem !important
    }

    .pr-xxl-3,
    .px-xxl-3 {
        padding-right: 1rem !important
    }

    .pb-xxl-3,
    .py-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pl-xxl-3,
    .px-xxl-3 {
        padding-left: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.25rem !important
    }

    .pt-xxl-4,
    .py-xxl-4 {
        padding-top: 1.25rem !important
    }

    .pr-xxl-4,
    .px-xxl-4 {
        padding-right: 1.25rem !important
    }

    .pb-xxl-4,
    .py-xxl-4 {
        padding-bottom: 1.25rem !important
    }

    .pl-xxl-4,
    .px-xxl-4 {
        padding-left: 1.25rem !important
    }

    .p-xxl-5 {
        padding: 1.5rem !important
    }

    .pt-xxl-5,
    .py-xxl-5 {
        padding-top: 1.5rem !important
    }

    .pr-xxl-5,
    .px-xxl-5 {
        padding-right: 1.5rem !important
    }

    .pb-xxl-5,
    .py-xxl-5 {
        padding-bottom: 1.5rem !important
    }

    .pl-xxl-5,
    .px-xxl-5 {
        padding-left: 1.5rem !important
    }

    .p-xxl-6 {
        padding: 1.875rem !important
    }

    .pt-xxl-6,
    .py-xxl-6 {
        padding-top: 1.875rem !important
    }

    .pr-xxl-6,
    .px-xxl-6 {
        padding-right: 1.875rem !important
    }

    .pb-xxl-6,
    .py-xxl-6 {
        padding-bottom: 1.875rem !important
    }

    .pl-xxl-6,
    .px-xxl-6 {
        padding-left: 1.875rem !important
    }

    .p-xxl-7 {
        padding: 2.5rem !important
    }

    .pt-xxl-7,
    .py-xxl-7 {
        padding-top: 2.5rem !important
    }

    .pr-xxl-7,
    .px-xxl-7 {
        padding-right: 2.5rem !important
    }

    .pb-xxl-7,
    .py-xxl-7 {
        padding-bottom: 2.5rem !important
    }

    .pl-xxl-7,
    .px-xxl-7 {
        padding-left: 2.5rem !important
    }

    .p-xxl-8 {
        padding: 3.125rem !important
    }

    .pt-xxl-8,
    .py-xxl-8 {
        padding-top: 3.125rem !important
    }

    .pr-xxl-8,
    .px-xxl-8 {
        padding-right: 3.125rem !important
    }

    .pb-xxl-8,
    .py-xxl-8 {
        padding-bottom: 3.125rem !important
    }

    .pl-xxl-8,
    .px-xxl-8 {
        padding-left: 3.125rem !important
    }

    .p-xxl-9 {
        padding: 3.75rem !important
    }

    .pt-xxl-9,
    .py-xxl-9 {
        padding-top: 3.75rem !important
    }

    .pr-xxl-9,
    .px-xxl-9 {
        padding-right: 3.75rem !important
    }

    .pb-xxl-9,
    .py-xxl-9 {
        padding-bottom: 3.75rem !important
    }

    .pl-xxl-9,
    .px-xxl-9 {
        padding-left: 3.75rem !important
    }

    .p-xxl-10 {
        padding: 4.375rem !important
    }

    .pt-xxl-10,
    .py-xxl-10 {
        padding-top: 4.375rem !important
    }

    .pr-xxl-10,
    .px-xxl-10 {
        padding-right: 4.375rem !important
    }

    .pb-xxl-10,
    .py-xxl-10 {
        padding-bottom: 4.375rem !important
    }

    .pl-xxl-10,
    .px-xxl-10 {
        padding-left: 4.375rem !important
    }

    .p-xxl-11 {
        padding: 5rem !important
    }

    .pt-xxl-11,
    .py-xxl-11 {
        padding-top: 5rem !important
    }

    .pr-xxl-11,
    .px-xxl-11 {
        padding-right: 5rem !important
    }

    .pb-xxl-11,
    .py-xxl-11 {
        padding-bottom: 5rem !important
    }

    .pl-xxl-11,
    .px-xxl-11 {
        padding-left: 5rem !important
    }

    .p-xxl-12 {
        padding: 5.625rem !important
    }

    .pt-xxl-12,
    .py-xxl-12 {
        padding-top: 5.625rem !important
    }

    .pr-xxl-12,
    .px-xxl-12 {
        padding-right: 5.625rem !important
    }

    .pb-xxl-12,
    .py-xxl-12 {
        padding-bottom: 5.625rem !important
    }

    .pl-xxl-12,
    .px-xxl-12 {
        padding-left: 5.625rem !important
    }

    .p-xxl-13 {
        padding: 6.25rem !important
    }

    .pt-xxl-13,
    .py-xxl-13 {
        padding-top: 6.25rem !important
    }

    .pr-xxl-13,
    .px-xxl-13 {
        padding-right: 6.25rem !important
    }

    .pb-xxl-13,
    .py-xxl-13 {
        padding-bottom: 6.25rem !important
    }

    .pl-xxl-13,
    .px-xxl-13 {
        padding-left: 6.25rem !important
    }

    .p-xxl-14 {
        padding: 6.875rem !important
    }

    .pt-xxl-14,
    .py-xxl-14 {
        padding-top: 6.875rem !important
    }

    .pr-xxl-14,
    .px-xxl-14 {
        padding-right: 6.875rem !important
    }

    .pb-xxl-14,
    .py-xxl-14 {
        padding-bottom: 6.875rem !important
    }

    .pl-xxl-14,
    .px-xxl-14 {
        padding-left: 6.875rem !important
    }

    .p-xxl-15 {
        padding: 7.5rem !important
    }

    .pt-xxl-15,
    .py-xxl-15 {
        padding-top: 7.5rem !important
    }

    .pr-xxl-15,
    .px-xxl-15 {
        padding-right: 7.5rem !important
    }

    .pb-xxl-15,
    .py-xxl-15 {
        padding-bottom: 7.5rem !important
    }

    .pl-xxl-15,
    .px-xxl-15 {
        padding-left: 7.5rem !important
    }

    .p-xxl-16 {
        padding: 9.375rem !important
    }

    .pt-xxl-16,
    .py-xxl-16 {
        padding-top: 9.375rem !important
    }

    .pr-xxl-16,
    .px-xxl-16 {
        padding-right: 9.375rem !important
    }

    .pb-xxl-16,
    .py-xxl-16 {
        padding-bottom: 9.375rem !important
    }

    .pl-xxl-16,
    .px-xxl-16 {
        padding-left: 9.375rem !important
    }

    .p-xxl-17 {
        padding: 10.625rem !important
    }

    .pt-xxl-17,
    .py-xxl-17 {
        padding-top: 10.625rem !important
    }

    .pr-xxl-17,
    .px-xxl-17 {
        padding-right: 10.625rem !important
    }

    .pb-xxl-17,
    .py-xxl-17 {
        padding-bottom: 10.625rem !important
    }

    .pl-xxl-17,
    .px-xxl-17 {
        padding-left: 10.625rem !important
    }

    .p-xxl-18 {
        padding: 13.75rem !important
    }

    .pt-xxl-18,
    .py-xxl-18 {
        padding-top: 13.75rem !important
    }

    .pr-xxl-18,
    .px-xxl-18 {
        padding-right: 13.75rem !important
    }

    .pb-xxl-18,
    .py-xxl-18 {
        padding-bottom: 13.75rem !important
    }

    .pl-xxl-18,
    .px-xxl-18 {
        padding-left: 13.75rem !important
    }

    .m-xxl-n1 {
        margin: -.3125rem !important
    }

    .mt-xxl-n1,
    .my-xxl-n1 {
        margin-top: -.3125rem !important
    }

    .mr-xxl-n1,
    .mx-xxl-n1 {
        margin-right: -.3125rem !important
    }

    .mb-xxl-n1,
    .my-xxl-n1 {
        margin-bottom: -.3125rem !important
    }

    .ml-xxl-n1,
    .mx-xxl-n1 {
        margin-left: -.3125rem !important
    }

    .m-xxl-n2 {
        margin: -.625rem !important
    }

    .mt-xxl-n2,
    .my-xxl-n2 {
        margin-top: -.625rem !important
    }

    .mr-xxl-n2,
    .mx-xxl-n2 {
        margin-right: -.625rem !important
    }

    .mb-xxl-n2,
    .my-xxl-n2 {
        margin-bottom: -.625rem !important
    }

    .ml-xxl-n2,
    .mx-xxl-n2 {
        margin-left: -.625rem !important
    }

    .m-xxl-n3 {
        margin: -1rem !important
    }

    .mt-xxl-n3,
    .my-xxl-n3 {
        margin-top: -1rem !important
    }

    .mr-xxl-n3,
    .mx-xxl-n3 {
        margin-right: -1rem !important
    }

    .mb-xxl-n3,
    .my-xxl-n3 {
        margin-bottom: -1rem !important
    }

    .ml-xxl-n3,
    .mx-xxl-n3 {
        margin-left: -1rem !important
    }

    .m-xxl-n4 {
        margin: -1.25rem !important
    }

    .mt-xxl-n4,
    .my-xxl-n4 {
        margin-top: -1.25rem !important
    }

    .mr-xxl-n4,
    .mx-xxl-n4 {
        margin-right: -1.25rem !important
    }

    .mb-xxl-n4,
    .my-xxl-n4 {
        margin-bottom: -1.25rem !important
    }

    .ml-xxl-n4,
    .mx-xxl-n4 {
        margin-left: -1.25rem !important
    }

    .m-xxl-n5 {
        margin: -1.5rem !important
    }

    .mt-xxl-n5,
    .my-xxl-n5 {
        margin-top: -1.5rem !important
    }

    .mr-xxl-n5,
    .mx-xxl-n5 {
        margin-right: -1.5rem !important
    }

    .mb-xxl-n5,
    .my-xxl-n5 {
        margin-bottom: -1.5rem !important
    }

    .ml-xxl-n5,
    .mx-xxl-n5 {
        margin-left: -1.5rem !important
    }

    .m-xxl-n6 {
        margin: -1.875rem !important
    }

    .mt-xxl-n6,
    .my-xxl-n6 {
        margin-top: -1.875rem !important
    }

    .mr-xxl-n6,
    .mx-xxl-n6 {
        margin-right: -1.875rem !important
    }

    .mb-xxl-n6,
    .my-xxl-n6 {
        margin-bottom: -1.875rem !important
    }

    .ml-xxl-n6,
    .mx-xxl-n6 {
        margin-left: -1.875rem !important
    }

    .m-xxl-n7 {
        margin: -2.5rem !important
    }

    .mt-xxl-n7,
    .my-xxl-n7 {
        margin-top: -2.5rem !important
    }

    .mr-xxl-n7,
    .mx-xxl-n7 {
        margin-right: -2.5rem !important
    }

    .mb-xxl-n7,
    .my-xxl-n7 {
        margin-bottom: -2.5rem !important
    }

    .ml-xxl-n7,
    .mx-xxl-n7 {
        margin-left: -2.5rem !important
    }

    .m-xxl-n8 {
        margin: -3.125rem !important
    }

    .mt-xxl-n8,
    .my-xxl-n8 {
        margin-top: -3.125rem !important
    }

    .mr-xxl-n8,
    .mx-xxl-n8 {
        margin-right: -3.125rem !important
    }

    .mb-xxl-n8,
    .my-xxl-n8 {
        margin-bottom: -3.125rem !important
    }

    .ml-xxl-n8,
    .mx-xxl-n8 {
        margin-left: -3.125rem !important
    }

    .m-xxl-n9 {
        margin: -3.75rem !important
    }

    .mt-xxl-n9,
    .my-xxl-n9 {
        margin-top: -3.75rem !important
    }

    .mr-xxl-n9,
    .mx-xxl-n9 {
        margin-right: -3.75rem !important
    }

    .mb-xxl-n9,
    .my-xxl-n9 {
        margin-bottom: -3.75rem !important
    }

    .ml-xxl-n9,
    .mx-xxl-n9 {
        margin-left: -3.75rem !important
    }

    .m-xxl-n10 {
        margin: -4.375rem !important
    }

    .mt-xxl-n10,
    .my-xxl-n10 {
        margin-top: -4.375rem !important
    }

    .mr-xxl-n10,
    .mx-xxl-n10 {
        margin-right: -4.375rem !important
    }

    .mb-xxl-n10,
    .my-xxl-n10 {
        margin-bottom: -4.375rem !important
    }

    .ml-xxl-n10,
    .mx-xxl-n10 {
        margin-left: -4.375rem !important
    }

    .m-xxl-n11 {
        margin: -5rem !important
    }

    .mt-xxl-n11,
    .my-xxl-n11 {
        margin-top: -5rem !important
    }

    .mr-xxl-n11,
    .mx-xxl-n11 {
        margin-right: -5rem !important
    }

    .mb-xxl-n11,
    .my-xxl-n11 {
        margin-bottom: -5rem !important
    }

    .ml-xxl-n11,
    .mx-xxl-n11 {
        margin-left: -5rem !important
    }

    .m-xxl-n12 {
        margin: -5.625rem !important
    }

    .mt-xxl-n12,
    .my-xxl-n12 {
        margin-top: -5.625rem !important
    }

    .mr-xxl-n12,
    .mx-xxl-n12 {
        margin-right: -5.625rem !important
    }

    .mb-xxl-n12,
    .my-xxl-n12 {
        margin-bottom: -5.625rem !important
    }

    .ml-xxl-n12,
    .mx-xxl-n12 {
        margin-left: -5.625rem !important
    }

    .m-xxl-n13 {
        margin: -6.25rem !important
    }

    .mt-xxl-n13,
    .my-xxl-n13 {
        margin-top: -6.25rem !important
    }

    .mr-xxl-n13,
    .mx-xxl-n13 {
        margin-right: -6.25rem !important
    }

    .mb-xxl-n13,
    .my-xxl-n13 {
        margin-bottom: -6.25rem !important
    }

    .ml-xxl-n13,
    .mx-xxl-n13 {
        margin-left: -6.25rem !important
    }

    .m-xxl-n14 {
        margin: -6.875rem !important
    }

    .mt-xxl-n14,
    .my-xxl-n14 {
        margin-top: -6.875rem !important
    }

    .mr-xxl-n14,
    .mx-xxl-n14 {
        margin-right: -6.875rem !important
    }

    .mb-xxl-n14,
    .my-xxl-n14 {
        margin-bottom: -6.875rem !important
    }

    .ml-xxl-n14,
    .mx-xxl-n14 {
        margin-left: -6.875rem !important
    }

    .m-xxl-n15 {
        margin: -7.5rem !important
    }

    .mt-xxl-n15,
    .my-xxl-n15 {
        margin-top: -7.5rem !important
    }

    .mr-xxl-n15,
    .mx-xxl-n15 {
        margin-right: -7.5rem !important
    }

    .mb-xxl-n15,
    .my-xxl-n15 {
        margin-bottom: -7.5rem !important
    }

    .ml-xxl-n15,
    .mx-xxl-n15 {
        margin-left: -7.5rem !important
    }

    .m-xxl-n16 {
        margin: -9.375rem !important
    }

    .mt-xxl-n16,
    .my-xxl-n16 {
        margin-top: -9.375rem !important
    }

    .mr-xxl-n16,
    .mx-xxl-n16 {
        margin-right: -9.375rem !important
    }

    .mb-xxl-n16,
    .my-xxl-n16 {
        margin-bottom: -9.375rem !important
    }

    .ml-xxl-n16,
    .mx-xxl-n16 {
        margin-left: -9.375rem !important
    }

    .m-xxl-n17 {
        margin: -10.625rem !important
    }

    .mt-xxl-n17,
    .my-xxl-n17 {
        margin-top: -10.625rem !important
    }

    .mr-xxl-n17,
    .mx-xxl-n17 {
        margin-right: -10.625rem !important
    }

    .mb-xxl-n17,
    .my-xxl-n17 {
        margin-bottom: -10.625rem !important
    }

    .ml-xxl-n17,
    .mx-xxl-n17 {
        margin-left: -10.625rem !important
    }

    .m-xxl-n18 {
        margin: -13.75rem !important
    }

    .mt-xxl-n18,
    .my-xxl-n18 {
        margin-top: -13.75rem !important
    }

    .mr-xxl-n18,
    .mx-xxl-n18 {
        margin-right: -13.75rem !important
    }

    .mb-xxl-n18,
    .my-xxl-n18 {
        margin-bottom: -13.75rem !important
    }

    .ml-xxl-n18,
    .mx-xxl-n18 {
        margin-left: -13.75rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mt-xxl-auto,
    .my-xxl-auto {
        margin-top: auto !important
    }

    .mr-xxl-auto,
    .mx-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-auto,
    .my-xxl-auto {
        margin-bottom: auto !important
    }

    .ml-xxl-auto,
    .mx-xxl-auto {
        margin-left: auto !important
    }
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: transparent
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, liberation mono, courier new, monospace !important
}

.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media(min-width:576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media(min-width:768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media(min-width:992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media(min-width:1200px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

@media(min-width:1600px) {
    .text-xxl-left {
        text-align: left !important
    }

    .text-xxl-right {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-weight-bolder {
    font-weight: bolder !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: var(--primary) !important
}

a.text-primary:hover,
a.text-primary:focus {
    color: #09838d !important
}

.text-secondary {
    color: #1e1d85 !important
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #100f46 !important
}

.text-success {
    color: #28a745 !important
}

a.text-success:hover,
a.text-success:focus {
    color: #19692c !important
}

.text-info {
    color: #17a2b8 !important
}

a.text-info:hover,
a.text-info:focus {
    color: #0f6674 !important
}

.text-warning {
    color: #f6b500 !important
}

a.text-warning:hover,
a.text-warning:focus {
    color: #aa7d00 !important
}

.text-danger {
    color: #dc3545 !important
}

a.text-danger:hover,
a.text-danger:focus {
    color: #a71d2a !important
}

.text-light {
    color: #f8f9fa !important
}

a.text-light:hover,
a.text-light:focus {
    color: #cbd3da !important
}

.text-dark {
    color: #252525 !important
}

a.text-dark:hover,
a.text-dark:focus {
    color: #000 !important
}

.text-accent {
    color: #eff6f7 !important
}

a.text-accent:hover,
a.text-accent:focus {
    color: #bcd9de !important
}

.text-orange {
    color: #ff6935 !important
}

a.text-orange:hover,
a.text-orange:focus {
    color: #e83c00 !important
}

.text-white {
    color: #fff !important
}

a.text-white:hover,
a.text-white:focus {
    color: #d9d9d9 !important
}

.text-indigo {
    color: #6969eb !important
}

a.text-indigo:hover,
a.text-indigo:focus {
    color: #2626e2 !important
}

.text-body {
    color: #696969 !important
}

.text-muted {
    color: #ababab !important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, .5) !important
}

.text-white-60 {
    color: rgba(255, 255, 255, .6) !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none !important
}

.text-break {
    word-wrap: break-word !important
}

.text-reset {
    color: inherit !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media print {

    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    abbr[title]::after {
        content: " (" attr(title) ")"
    }

    pre {
        white-space: pre-wrap !important
    }

    pre,
    blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    tr,
    img {
        page-break-inside: avoid
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    body {
        min-width: 992px !important
    }

    .container {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #dee2e6 !important
    }

    .table-dark {
        color: inherit
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody+tbody {
        border-color: #eee
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #eee
    }
}

.table thead th {
    font-weight: 500
}

.table .thead-sm {
    font-size: 13px;
    line-height: 2
}

.table .thead-black {
    color: #333
}

.table-border-gray {
    border: 1px solid #f5f5f5 !important
}

.table-border-gray th,
.table-border-gray td {
    border: 1px solid #f5f5f5 !important
}

.table-align-middle th,
.table-align-middle td {
    vertical-align: text-top !important
}

.table-p-6 th,
.table-p-6 td {
    padding: 1.875rem
}

.table-p-4 th,
.table-p-4 td {
    padding: 1.25rem
}

.table-th-min-width-300 th {
    min-width: 300px
}

/* .dropright .dropdown-toggle::after,
.dropdown-toggle::after {
    content: "\f107";
    font-family: "font awesome 5 pro";
    font-weight: 300;
    border: 0;
    vertical-align: 0
}

.dropright .dropdown-toggle::after {
    content: "\f105"
}

.dropright .dropdown-link {
    display: -ms-flexbox;
    display: flex
} */

.dropright .dropdown-link::after {
    margin-left: auto
}

.dropdown-item a {
    color: inherit;
    text-decoration: none
}

.dropdown-sm {
    min-width: 7rem
}

.dropdown-menu-md {
    min-width: 20rem
}

.dropdown-menu-lg {
    min-width: 30rem
}

.dropdown-menu-xl {
    min-width: 40rem
}

.dropdown-menu-xxl {
    min-width: 50rem
}

.dropdown-menu-full {
    width: 100%
}

.dropdown-header {
    display: inline-block;
    font-size: .875rem
}

.no-caret .dropdown-toggle::after {
    display: none
}

.sortby div.dropdown-menu {
    left: auto !important;
    right: 0 !important
}

.nav-link {
    font-weight: 500
}

.nav-tabs .nav-link {
    background-color: #eee;
    color: inherit;
    padding: .5rem 1.875rem
}

.nav-tabs .nav-link.active {
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1);
    position: relative
}

.nav-tabs .nav-link.active:before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    height: 11px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1
}

.nav-tabs .nav-item:not(.active)+.nav-item {
    margin-left: 3px
}

.tab-content {
    padding: 30px;
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1);
    border-radius: 0 .3125rem .3125rem .3125rem
}

.nav-pills-01 .nav-link {
    color: rgba(51, 51, 51, .4);
    position: relative
}

.nav-pills-01 .nav-link::before {
    display: none
}

.nav-pills-01 .nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: auto;
    width: 0;
    height: 2px;
    background-color: var(--primary);
    transition: all .5s
}

.nav-pills-01 .nav-link:hover,
.nav-pills-01 .nav-link.active {
    color: #333
}

.nav-pills-01 .nav-link:hover::after,
.nav-pills-01 .nav-link.active::after {
    width: 90%
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    padding-left: 15px !important;
    padding-right: 15px !important
}

.sticky-area {
    transition: all .5s;
    height: auto;
    background-color: var(--nav-footer-bcolor);
}

.sticky-area-wrap {
    position: relative;
    transition: all .5s;
    z-index: 1000
}

.sticky .sticky-area {
    width: 100%;
    z-index: 1050
}

.header-hidden {
    -webkit-transform: translate(0, -100%);
    transform: translate(0, -100%);
    box-shadow: none !important
}

.navbar-light .sticky-area {
    background-color:var(--nav-footer-bcolor);
}

.navbar-light .sticky .sticky-area {
    box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15)
}

.main-header .dropdown-menu {
    position: absolute
}

.main-header .sticky-logo,
.main-header .sticky-button-icon {
    display: none
}

.main-header .normal-logo,
.main-header .normal-button-icon {
    display: inline-block
}

.main-header .navbar {
    /* padding-top: 10px;
    padding-bottom: 10px */
}

.main-header .navbar .nav-link {
    display: inline-block;
    font-size: .875rem;
    line-height: 26px
}

.main-header .navbar .nav-link:focus {
    outline: none
}

.main-header .navbar .dropdown-link:focus,
.main-header .navbar .dropdown-item:focus {
    color: currentColor;
    outline: none
}

.main-header .navbar .dropdown-menu-docs .dropdown-item:focus i,
.main-header .navbar .dropdown-menu-docs .dropdown-item:focus small,
.main-header .navbar .dropdown-menu-docs .dropdown-item:focus span {
    color: #fff
}

.main-header.navbar-dark .btn {
    /* border-color: rgba(255, 255, 255, .5); */
    background-color: var(--button-back-color);
}

.main-header .main-menu .nav-link,
.main-header .main-menu .dropdown-header {
    position: relative
}

.main-header .main-menu .nav-link::before,
.main-header .main-menu .dropdown-header::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    background-color: var(--primary);
    transition: width .5s
}

.main-header .main-menu .nav-item {
    transition: all .5s
}

.main-header .main-menu .nav-item .nav-link::before {
    width: 0
}

.main-header .main-menu .nav-item:hover .nav-link::before {
    width: 100%
}

.main-header .main-menu .dropdown-header::before {
    left: 1.5rem;
    bottom: 0;
    width: 30%
}

.main-header:not(.header-02) .dropdown-menu-listing {
    left: -150%
}

.main-header .badge-absolute {
    top: -3px;
    right: -3px;
    padding-top: 4px;
    padding-bottom: 4px
}

.main-header .divider {
    content: '';
    width: 1px;
    height: 15px;
    opacity: .7;
    background-color: #fff
}

.main-header .dropdown-menu-docs {
    min-width: 250px
}

.main-header .dropdown-menu-docs i,
.main-header .dropdown-menu-docs small {
    color: #ababab
}

.main-header.header-landing .nav-link.active::before {
    width: 100% !important
}

@media(min-width:992px) {
    .main-header.header-landing .sticky-area {
        background-color: transparent
    }

    .main-header.header-landing .sticky .sticky-area {
        background-color: #fff
    }
}

@media(max-width:1199.98px) {
    .main-header.header-mobile-xl {
        position: static !important
    }

    .main-header.header-mobile-xl .sticky-area {
        background-color: var(--nav-footer-bcolor) !important;
    }

    .main-header.header-mobile-xl .main-menu {
        max-height: 500px;
        overflow-y: auto
    }

    .main-header.header-mobile-xl .main-menu .dropdown-menu {
        position: static;
        box-shadow: none;
        padding-top: 0;
        background-color: transparent
    }

    .main-header.header-mobile-xl .main-menu .dropdown-menu .dropdown-link,
    .main-header.header-mobile-xl .main-menu .dropdown-menu .dropdown-item,
    .main-header.header-mobile-xl .main-menu .dropdown-menu .dropdown-header {
        color: #fff !important
    }

    .main-header.header-mobile-xl .main-menu .dropdown-menu .dropdown-item:not(.dropdown):hover,
    .main-header.header-mobile-xl .main-menu .dropdown-menu .dropdown-item:not(.dropdown):focus,
    .main-header.header-mobile-xl .main-menu .dropdown-menu .dropdown-item:not(.dropdown).active {
        background-color: var(--primary)
    }

    .main-header.header-mobile-xl .main-menu .dropdown-menu .dropdown-item.dropdown:hover,
    .main-header.header-mobile-xl .main-menu .dropdown-menu .dropdown-item.dropdown:focus,
    .main-header.header-mobile-xl .main-menu .dropdown-menu .dropdown-item.dropdown.active {
        background-color: transparent
    }

    .main-header.header-mobile-xl .main-menu .dropright .dropdown-toggle {
        display: inline-block
    }

    .main-header.header-mobile-xl .main-menu .dropright .dropdown-toggle::after {
        content: '\f107' !important;
        margin-left: 10px
    }

    .main-header.header-mobile-xl .navbar-nav>.nav-item>.nav-link {
        color: #fff
    }

    .main-header.header-mobile-xl .sticky-logo {
        display: none !important
    }

    .main-header.header-mobile-xl .normal-logo {
        display: inline-block
    }

    .main-header.header-mobile-xl.navbar-light-sticky .normal-logo {
        display: inline-block !important
    }

    .main-header.header-mobile-xl .dropdown-menu-docs i,
    .main-header.header-mobile-xl .dropdown-menu-docs small {
        color: #fff
    }
}

@media(max-width:991.98px) {
    .main-header.header-mobile-lg {
        position: static !important
    }

    .main-header.header-mobile-lg .sticky-area {
        background-color: #1e1d85 !important
    }

    .main-header.header-mobile-lg .main-menu {
        max-height: 500px;
        overflow-y: auto
    }

    .main-header.header-mobile-lg .main-menu .dropdown-menu {
        position: static;
        box-shadow: none;
        padding-top: 0;
        background-color: transparent
    }

    .main-header.header-mobile-lg .main-menu .dropdown-menu .dropdown-link,
    .main-header.header-mobile-lg .main-menu .dropdown-menu .dropdown-item,
    .main-header.header-mobile-lg .main-menu .dropdown-menu .dropdown-header {
        color: #fff !important
    }

    .main-header.header-mobile-lg .main-menu .dropdown-menu .dropdown-item:not(.dropdown):hover,
    .main-header.header-mobile-lg .main-menu .dropdown-menu .dropdown-item:not(.dropdown):focus,
    .main-header.header-mobile-lg .main-menu .dropdown-menu .dropdown-item:not(.dropdown).active {
        background-color: var(--primary)
    }

    .main-header.header-mobile-lg .main-menu .dropdown-menu .dropdown-item.dropdown:hover,
    .main-header.header-mobile-lg .main-menu .dropdown-menu .dropdown-item.dropdown:focus,
    .main-header.header-mobile-lg .main-menu .dropdown-menu .dropdown-item.dropdown.active {
        background-color: transparent
    }

    .main-header.header-mobile-lg .main-menu .dropright .dropdown-toggle {
        display: inline-block
    }

    .main-header.header-mobile-lg .main-menu .dropright .dropdown-toggle::after {
        content: '\f107' !important;
        margin-left: 10px
    }

    .main-header.header-mobile-lg .navbar-nav>.nav-item>.nav-link {
        color: #fff
    }

    .main-header.header-mobile-lg .sticky-logo {
        display: none !important
    }

    .main-header.header-mobile-lg .normal-logo {
        display: inline-block
    }

    .main-header.header-mobile-lg.navbar-light-sticky .normal-logo {
        display: inline-block !important
    }

    .main-header.header-mobile-lg .dropdown-menu-docs i,
    .main-header.header-mobile-lg .dropdown-menu-docs small {
        color: #fff
    }
}

.sticky .sticky-logo,
.sticky .sticky-button-icon {
    display: inline-block
}

.sticky .normal-logo,
.sticky .normal-button-icon {
    display: none
}

.sticky .main-menu .nav-item {
    padding-top: 10px !important;
    padding-bottom: 10px !important
}

.navbar-light-sticky .btn.btn-outline-light {
    /* color: #333 !important;
    border-color: #ababab !important */
    color: white;
    background-color: var(--button-back-color);
}

.navbar-light-sticky .btn.btn-outline-light:hover {
    /* background-color: #eff6f7 !important */
}

.card-img-overlay {
    z-index: 1
}

.card-title {
    color: inherit
}

.breadcrumb-item {
    color: #ababab;
    letter-spacing: 1px;
    text-transform: uppercase
}

.breadcrumb-item a {
    color: inherit;
    text-decoration: none
}

.breadcrumb-item:hover {
    color: #333;
    text-decoration: none
}

.breadcrumb.text-light .breadcrumb-item {
    color: #b3b3b3
}

.breadcrumb.text-light .breadcrumb-item.active {
    color: #fff
}

.pagination.rounded-active .page-item .page-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -ms-flex-pack: center;
    justify-content: center
}

.pagination.rounded-active .page-item {
    font-size: 16px;
    font-weight: 600
}

.pagination.rounded-active .page-item:not(:last-child) {
    margin-right: 15px
}

.badge-circle {
    border-radius: 100% !important
}

.badge-absolute {
    position: absolute;
    right: -2px;
    top: 10px
}

.badge {
    line-height: 1.15;
    letter-spacing: .87px;
    text-transform: uppercase
}

.badge-blue {
    background-color: rgba(78, 132, 250, .06);
    border: 1px solid rgba(78, 132, 250, .2);
    color: #4e84fa
}

.badge-green {
    background-color: rgba(108, 189, 126, .06);
    border: 1px solid rgba(108, 189, 126, .2);
    color: #6cbd7e
}

.badge-yellow {
    background-color: rgba(246, 181, 0, .06);
    border: 1px solid rgba(246, 181, 0, .2);
    color: #f6b500
}

.badge-pink {
    border: 1px solid rgba(231, 68, 91, .2);
    background-color: rgba(231, 68, 91, .06);
    color: var(--primary)
}

.list-group .list-group-item.active h1,
.list-group .list-group-item.active h2,
.list-group .list-group-item.active h3,
.list-group .list-group-item.active h4,
.list-group .list-group-item.active h5,
.list-group .list-group-item.active h6,
.list-group .list-group-item.active .h1,
.list-group .list-group-item.active .h2,
.list-group .list-group-item.active .h3,
.list-group .list-group-item.active .h4,
.list-group .list-group-item.active .h5,
.list-group .list-group-item.active .h6 {
    color: inherit
}

.popover {
    top: -11px !important
}

.popover .arrow::before,
.popover .arrow::after {
    border-top-color: #fff
}

.heading-divider {
    display: block;
    width: 75px;
    height: 3px;
    margin-top: 19px;
    margin-bottom: 21px;
    background-color: var(--primary)
}

.form-search-01 .form-control,
.form-search-01 .btn {
    width: 127px
}

.page-title.bg-white-overlay {
    position: relative
}

.page-title.bg-white-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .5);
    z-index: 1
}

.page-title .bootstrap-select .btn:focus,
.page-title .bootstrap-select .btn:hover {
    border: 1px solid #ababab;
    background-color: transparent !important
}

.page-title.bg-overlay .container,
.page-title.bg-white-overlay .container {
    position: relative;
    z-index: 2
}

.page-title.shadow {
    box-shadow: 0 7px 10px -5px rgba(51, 51, 51, .05) !important
}

.page-title.bg-patten {
    background-image: url('/public/images/bg-title-01.png'), url("/public/images/bg-title-02.png");
    background-position: left bottom, right top;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto
}

.form-search-02 .form-control,
.form-search-02 .input-group-text {
    border-width: 0 0 2px
}

.form-search-02 .form-control:focus {
    box-shadow: none;
    border-color: #eee;
    background-color: transparent !important
}

.form-search-02 .bootstrap-select .btn {
    border-width: 0 0 2px;
    border-style: solid;
    border-color: #eee
}

.form-search-02 .bootstrap-select .btn:hover,
.form-search-02 .bootstrap-select .btn:focus {
    background-color: transparent !important
}

.bootstrap-select .btn:focus {
    outline: none !important;
    box-shadow: none !important
}

.bootstrap-select .dropdown-item:focus {
    outline: none !important
}

.footer .divider {
    content: '';
    vertical-align: middle;
    height: 16px;
    width: 2px;
    border: solid 1px #fff;
    opacity: .5
}

.footer .dropdown .nav-link {
    letter-spacing: 1.73px
}

.footer hr {
    border-color: #fff;
    opacity: .2
}

.re-capchar {
    max-width: 307px;
    border-color: #d6d6d6;
    background-color: #fafafa
}

.login-register-modal .nav-link {
    color: #696969;
    border-radius: 0
}

.login-register-modal .nav-link:not(.active),
.login-register-modal .nav-item:last-child {
    background-color: #f8f8f8
}

.login-register-modal .nav-link.active {
    box-shadow: none;
    background-color: #fff;
    color: #000 !important
}

.login-register-modal .nav-link.active::before {
    display: none
}

@media(min-width:576px) {
    .login-register-modal .nav-link.active+.nav-item {
        border-bottom-left-radius: 16px
    }

    .login-register-modal .nav-link:not(.active):first-child {
        border-bottom-right-radius: 16px
    }
}

.login-register .facebook {
    background: #3b5998
}

.login-register .twitter {
    background: #00aced
}

.login-register .google {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .1)
}

.login-register .divider {
    position: relative
}

.login-register .divider::after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #eee
}

.login-register .divider .text {
    position: relative;
    z-index: 2
}

.bottom-bar-action {
    box-shadow: 0 2px 32px 0 rgba(0, 0, 0, .24)
}

.bottom-bar-action-01 img {
    width: 56px;
    height: 56px
}

.slick-slider {
    margin-left: -15px;
    margin-right: -15px
}

.slick-slider .box {
    background-color: transparent !important;
    font-size: .875rem
}

.slick-slider .box:focus {
    outline: none
}

.slick-slider img {
    max-width: 100%;
    width: auto
}

.slick-slider :focus {
    outline: none
}

.slick-slide {
    height: inherit;
    padding-left: 15px;
    padding-right: 15px;
    position: relative
}

.slick-track {
    display: -ms-flexbox;
    display: flex
}

.slick-vertical {
    height: auto
}

.slick-vertical .slick-track {
    display: block
}

.slick-vertical .slick-dots {
    right: 50px;
    left: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.slick-vertical .slick-dots li:not(:last-child) {
    margin-bottom: 30px
}

.slick-arrow {
    text-align: center;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #fff;
    width: 52px;
    height: 52px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07);
    line-height: 52px;
    font-size: 28px;
    border-radius: 50%;
    color: #696969;
    z-index: 100;
    cursor: pointer
}

.slick-arrow i {
    font-weight: 300
}

.slick-arrow:hover {
    color: #fff;
    background-color: var(--primary)
}

.slick-arrow.slick-disabled {
    cursor: no-drop
}

.slick-arrow.slick-prev {
    left: 0;
    right: auto
}

.slick-arrow.slick-next {
    left: auto;
    right: 0
}

.slick-arrow i {
    line-height: inherit
}

.arrow-haft-inner .slick-prev {
    left: -26px;
    right: auto
}

.arrow-haft-inner .slick-next {
    right: -26px;
    left: auto
}

.arrow-inner .slick-prev {
    left: 30px;
    right: auto
}

.arrow-inner .slick-next {
    right: 30px;
    left: auto
}

.arrow-outer .slick-prev {
    left: -82px;
    right: auto
}

.arrow-outer .slick-next {
    right: -82px;
    left: auto
}

.arrow-hide-disable .slick-disabled {
    display: none !important
}

.slick-dots {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    margin-top: 30px
}

.slick-dots li {
    cursor: pointer;
    text-align: center;
    padding: 0 15px
}

.slick-dots span {
    display: inline-block;
    line-height: 12px;
    width: 8px;
    height: 8px;
    background: #333;
    opacity: .3;
    border-radius: 50%
}

.slick-dots li.slick-active,
.slick-dots li:hover,
.slick-arrow:active,
.slick-dots li:active,
.slick-arrow:focus,
.slick-dots li:focus {
    opacity: 1
}

.slick-dots li.slick-active span {
    background: #333;
    opacity: 1
}

.dots-white .slick-dots {
    margin-top: -30px
}

.dots-white .slick-dots span {
    background: #fff;
    opacity: .5
}

.dots-white .slick-dots li.slick-active span {
    background: #fff
}

.custom-slider-1 .slick-arrow {
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    box-shadow: none;
    color: #333;
    font-size: 28px
}

.custom-slider-1 .slick-arrow:hover {
    color: #fff
}

.custom-slider-1 .slick-prev {
    left: auto;
    right: 62px
}

.custom-slider-1 .slick-next {
    right: 0;
    left: auto
}

.custom-slider-1 .slick-dots {
    -ms-flex-pack: start;
    justify-content: flex-start
}

.custom-slider-1 .slick-dots li:not(.slick-active) {
    display: none
}

.custom-slider-1 .slick-dots .slick-active {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0
}

.custom-slider-1 .slick-dots .dot,
.custom-slider-1 .slick-dots .dot-divider {
    height: auto;
    width: auto;
    background-color: transparent
}

.custom-slider-1 .slick-dots .dot {
    font-size: 15px;
    font-weight: 600;
    color: #333
}

.custom-slider-1 .slick-dots .dot-divider {
    display: inline-block;
    content: '';
    width: 50px;
    height: 1px;
    border-bottom: 1px solid #ababab;
    margin: 0 15px
}

.custom-vertical {
    margin: 0
}

.custom-vertical .slick-slide {
    position: relative
}

.custom-vertical .slick-slide.slick-cloned {
    z-index: -1
}

.custom-vertical .slick-slide:not(.slick-active) {
    opacity: .4
}

.custom-vertical .slick-slide:not(.slick-active) .card {
    -webkit-transform: scale(.8);
    transform: scale(.8);
    margin: 0 auto;
    transition: all .2s
}

.custom-vertical .slick-slide.slick-active {
    z-index: 2;
    height: auto
}

.custom-vertical .slick-slide.prev {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    top: -110px;
    z-index: 1
}

.custom-vertical .slick-slide.slick-active+.slick-slide {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    bottom: -120px;
    z-index: 1
}

.custom-slider-2 .slick-list {
    padding-right: 24.3% !important
}

@media(max-width:767.98px) {
    .custom-slider-2 .slick-list {
        padding-right: 0 !important
    }
}

.custom-slider-2 .slick-active~* {
    opacity: .3
}

.custom-slider-2 .slick-arrow {
    bottom: -13px;
    right: 0;
    top: auto;
    left: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    box-shadow: none;
    color: #333;
    font-size: 28px;
    border: solid 1px #e7e7e7
}

.custom-slider-2 .slick-arrow:hover {
    color: #fff
}

.custom-slider-2 .slick-prev {
    left: 40%;
    right: auto
}

@media(max-width:991.98px) {
    .custom-slider-2 .slick-prev {
        left: 35%
    }
}

@media(max-width:767.98px) {
    .custom-slider-2 .slick-prev {
        left: 25%
    }
}

.custom-slider-2 .slick-next {
    right: 40%;
    left: auto
}

@media(max-width:991.98px) {
    .custom-slider-2 .slick-next {
        right: 35%
    }
}

@media(max-width:767.98px) {
    .custom-slider-2 .slick-next {
        right: 25%
    }
}

.custom-slider-2 .card {
    height: 100%
}

.custom-slider-2 .slick-dots {
    margin-top: 0
}

.custom-slider-2 .slick-dots li:not(.slick-active) {
    display: none
}

.custom-slider-2 .slick-dots .dot {
    font-size: 15px;
    font-weight: 600;
    color: #333;
    width: auto;
    height: auto;
    background-color: transparent
}

.custom-slick-slide-pd-20 .slick-slide {
    padding-left: 10px;
    padding-right: 10px
}

@media(min-width:1441px) {
    .custom-arrow-spacing-30 .slick-arrow.slick-next {
        right: -70px
    }

    .custom-arrow-spacing-30 .slick-arrow.slick-prev {
        left: -70px
    }
}

@media(max-width:1440px) and (min-width:1200px) {
    .custom-arrow-spacing-30 .slick-arrow {
        display: none !important
    }

    .custom-arrow-xxl-hide .slick-arrow {
        display: none !important
    }
}

.slick-dots-mt-0 .slick-dots {
    margin-top: 0 !important
}

.custom-dots .slick-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #1e1d85;
    padding: 28px 50px;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.custom-dots .slick-dots li {
    font-size: 32px;
    font-weight: 500;
    color: #fff;
    opacity: .5
}

.custom-dots .slick-dots li.slick-active {
    opacity: 1
}

@media(min-width:1200px) {
    .custom-arrow-center .slick-arrow {
        right: 50%;
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
        margin-top: 165px;
        margin-right: 68px;
        left: auto;
        width: 32px;
        height: 32px;
        font-size: 18px;
        line-height: 32px
    }

    .custom-arrow-center .slick-arrow.slick-next {
        margin-right: 25px
    }

    .custom-arrow-center .slick-dots {
        max-width: 1170px;
        margin-left: auto;
        margin-right: auto;
        -ms-flex-pack: start;
        justify-content: start;
        padding-left: 100px;
        position: absolute;
        left: 0;
        right: 0;
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        margin-top: 232px;
        top: 50%
    }

    .custom-arrow-center .slick-dots span {
        background: #fff
    }

    .custom-arrow-center .slick-dots.slick-active span {
        opacity: 1
    }
}

@media(min-width:992px) {
    .item-nth-2-active-lg:not(:hover) .slick-active:nth-child(2)>div {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .item-nth-2-active-lg:not(:hover) .slick-active:nth-child(2)>div .hover-image {
        opacity: 1 !important
    }
}

@media(max-width:575px) {
    .custom-dots .slick-dots {
        padding: 20px 30px
    }

    .custom-dots .slick-dots li {
        font-size: 18px
    }
}

.calendar .slick-arrow {
    width: 40px;
    height: 40px;
    line-height: 40px
}

.calendar .slick-prev {
    left: -20px;
    right: auto
}

.calendar .slick-next {
    right: -20px;
    left: auto
}

.custom-slider-arrow-20 .slick-prev {
    left: 35px
}

.custom-slider-arrow-20 .slick-next {
    right: 35px
}

.custom-slider-arrow-20 .slick-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 18px
}

.custom-slider-arrow-20 .slick-dots span {
    background: #fff
}

.custom-dots-center .slick-dots {
    margin-top: 70px;
    -ms-flex-pack: center;
    justify-content: center
}

.custom-dots-center .slick-dots .dot {
    font-size: 20px;
    font-weight: 500;
    color: #1e1d85
}

.custom-dots-center.text-heading .slick-dots .dot {
    color: #333
}

.custom-vh-100 {
    min-height: 100vh
}

.custom-vh-01 {
    min-height: calc(100vh - 97px)
}

.custom-vh-02 {
    min-height: calc(100vh - 94px)
}

.custom-vh-03 {
    min-height: calc(100vh - 194px)
}

.custom-vh-04 {
    min-height: calc(100vh - 154px)
}

@media(max-width:991px) {

    .custom-vh-01,
    .custom-vh-02,
    .custom-vh-100 {
        min-height: calc(100vh - 72px)
    }

    .custom-vh-03,
    .custom-vh-04 {
        min-height: calc(100vh - 184px)
    }
}

@media(min-width:576px) {
    .custom-slider-arrow-top .slick-arrow {
        background-color: transparent;
        box-shadow: none;
        width: auto;
        height: auto;
        -webkit-transform: none;
        transform: none;
        right: 15px;
        top: -65px;
        font-size: 35px
    }

    .custom-slider-arrow-top .slick-arrow.slick-next {
        right: 15px
    }

    .custom-slider-arrow-top .slick-arrow.slick-prev {
        right: 45px;
        left: auto
    }

    .custom-slider-arrow-top .slick-arrow:hover {
        color: var(--primary)
    }
}

.info-box-1 .box {
    padding-top: 30px;
    padding-bottom: 30px
}

.card {
    transition: all .2s
}

.card:focus {
    outline: none
}

.info-box-3 {
    max-width: 972px
}

.info-box-3 .card {
    margin-left: -1px
}

.info-box-3 .card-img-top {
    border-color: rgba(255, 255, 255, .4) !important;
    max-height: 68px
}

.info-box-3 .card:hover .card-title {
    color: #252525 !important
}

.info-box-4 .card-body span {
    height: 32px;
    opacity: 0;
    transition: all .5s
}

.info-box-4 .card-body .card-title {
    transition: all .5s
}

@media(min-width:576px) {
    .info-box-4 .card-body .card-title {
        margin-left: 42px
    }
}

.info-box-4 .card:hover .card-body span {
    opacity: 1
}

.info-box-4 .card:hover .card-title {
    margin-left: 0
}

.testimonials .divider {
    display: block;
    content: '';
    width: 40px;
    height: 2px;
    background-color: var(--primary)
}

.partners .item {
    max-height: 85px
}

.partners .item .image {
    opacity: .4
}

.partners .item:not(.hover-change-image):hover .image {
    opacity: 1
}

.galleries .item {
    position: relative;
    height: 100%
}

.galleries .card {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.galleries .card-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    border-radius: 5px
}

.galleries .item-size-1-1:after {
    content: '';
    display: block;
    padding-top: 100%
}

.galleries .item-size-2-1:after {
    content: '';
    display: block;
    padding-top: 50%
}

.galleries .item-size-4-3:after {
    content: '';
    display: block;
    padding-top: 75%
}

.galleries .item-size-3-2:after {
    content: '';
    display: block;
    padding-top: 53%
}

.galleries .custom-size-1:after {
    content: '';
    display: block;
    padding-top: 62%
}

.galleries .slider-nav .box {
    cursor: pointer
}

.button-video-white:hover {
    border-color: #fff !important
}

.button-video-white:hover span {
    background-color: var(--primary) !important;
    color: #fff !important
}

@media(max-width:767.98px) {
    .button-video-white span {
        width: 52px !important;
        height: 52px !important
    }
}

.nav-gallery .nav-link.active {
    background-color: var(--primary) !important;
    color: #fff !important
}

.category-widget input[type=checkbox]:checked+label {
    color: #333
}

.city-widget .bg-img::after {
    content: '';
    display: block;
    padding-top: 71%
}

.property-widget .slick-arrow {
    top: -36px;
    right: 0;
    left: auto;
    width: 38.3px;
    height: 38.2px;
    line-height: 38.2px;
    font-size: 18px
}

.property-widget .slick-prev {
    right: 48.5px;
    left: auto
}

.property-widget .slick-dots {
    margin-top: 20px
}

.widget-request-tour .nav-item:not(:last-child) {
    margin-right: -2px
}

.widget-request-tour .card.active {
    border: 2px solid rgba(14, 198, 213, .57) !important
}

.bootstrap-timepicker-widget {
    min-width: 320px
}

.bootstrap-timepicker-widget input {
    width: 80px !important;
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 16px
}

.bootstrap-timepicker-widget a {
    padding: 0 !important
}

.rate-bg-blue {
    background-color: #1678e7
}

.accordion .btn {
    transition: all .3s
}

.accordion .btn:focus {
    box-shadow: none !important
}

.accordion .btn:focus,
.accordion .btn:hover {
    text-decoration: none
}

.accordion .btn:before {
    position: absolute;
    font-family: "font awesome 5 pro"
}

.accordion-01 .btn:before {
    right: 1.5rem;
    font-size: 18px
}

.accordion-01 .btn.collapsed {
    color: #252525;
    background-color: #fff
}

.accordion-01 .btn.collapsed:before {
    content: "\f107"
}

.accordion-01 .btn:not(.collapsed) {
    color: #fff;
    background-color: #1e1d85
}

.accordion-01 .btn:not(.collapsed)::before {
    content: "\f106"
}

.accordion-02 .btn:before {
    left: 0;
    font-size: 22px
}

.accordion-02 .btn.collapsed {
    color: #ababab
}

.accordion-02 .btn.collapsed:before {
    content: "\f32d"
}

.accordion-02 .btn:not(.collapsed) {
    color: #333
}

.accordion-02 .btn:not(.collapsed)::before {
    color: var(--primary);
    content: "\f331"
}

.accordion-03 .heading {
    cursor: pointer
}

.accordion-03 .heading:before {
    position: absolute;
    font-family: "font awesome 5 pro";
    right: 30px;
    font-size: 18px
}

.accordion-03 .heading.collapsed:before {
    content: "\f107"
}

.accordion-03 .heading:not(.collapsed) {
    background-color: #fff !important
}

.accordion-03 .heading:not(.collapsed)::before {
    content: "\f106"
}

.accordion-03 .heading.bg-gray-01 {
    background-color: #f8f8f8 !important
}

.tabs-01 a.nav-link {
    color: rgba(37, 37, 37, .4)
}

.tabs-01 a.active,
.tabs-01 a.nav-link:hover,
.tabs-01 a::before {
    background-color: #f8f8f8 !important;
    color: #1e1d85 !important
}

.tabs-01+.tab-content .collapse-parent:not(.collapsed) {
    background-color: #e6e6e6 !important
}

.tabs-02 a.active,
.tabs-02 a.nav-link:hover {
    background-color: #fff !important;
    color: var(--primary) !important;
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
}

.tabs-02+.tab-content .collapse-parent:not(.collapsed) {
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1);
    background-color: #fff !important
}

.tabs-03 a.nav-link {
    position: relative;
    color: rgba(37, 37, 37, .4)
}

.tabs-03 a.nav-link:before {
    content: "";
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    position: absolute;
    transition: width .3s;
    background-color: var(--primary)
}

.tabs-03 a.nav-link:hover:before {
    width: 100%
}

.tabs-03.text-dark a.active,
.tabs-03.text-dark a.nav-link:hover {
    color: #252525 !important
}

.tabs-03 a.active,
.tabs-03 a.nav-link:hover {
    color: #696969 !important
}

.tabs-03 a.active {
    background-color: transparent !important
}

.tabs-03 a.active:before {
    width: 100%
}

.tabs-04 li {
    position: relative;
    margin-left: 0 !important
}

.tabs-04 li a.nav-link {
    padding: 7px 15px;
    font-weight: 400;
    border-radius: 0 !important
}

.tabs-04 li a.nav-link.active {
    font-weight: 600;
    color: #696969 !important;
    box-shadow: none
}

.tabs-04 li a.nav-link:before {
    position: absolute;
    left: 0;
    background-color: #696969;
    height: 28px;
    width: 1px;
    content: '';
    top: 7px;
    opacity: .2
}

.tabs-04 li:first-child a.nav-link {
    border-top-left-radius: 5px !important
}

.tabs-04 li:first-child a.nav-link:before {
    content: none
}

.tabs-04 li:last-child a.nav-link {
    border-top-right-radius: 5px !important
}

@media(min-width:768px) {
    .bg-white-md {
        background-color: #fff
    }
}

@media(max-width:767px) {
    .collapse-tabs .tab-pane.fade {
        opacity: 1
    }
}

.our-team .list-inline-item:nth-child(1) {
    transition-delay: .06667s
}

.our-team .list-inline-item:nth-child(2) {
    transition-delay: .13333s
}

.our-team .list-inline-item:nth-child(3) {
    transition-delay: .2s
}

.our-team .list-inline-item:nth-child(4) {
    transition-delay: .26667s
}

.our-team .list-inline-item:nth-child(5) {
    transition-delay: .33333s
}

.our-team .list-inline-item:nth-child(6) {
    transition-delay: .4s
}

.our-team .list-inline-item:nth-child(7) {
    transition-delay: .46667s
}

.our-team .list-inline-item:nth-child(8) {
    transition-delay: .53333s
}

.our-team .list-inline-item:nth-child(9) {
    transition-delay: .6s
}

.our-team .list-inline-item:nth-child(10) {
    transition-delay: .66667s
}

.our-team .list-inline-item {
    transition: all .4s .1s ease-out;
    opacity: 0;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    visibility: hidden
}

.our-team:hover .list-inline-item {
    -webkit-transform: translateY(-55px);
    transform: translateY(-55px);
    visibility: visible;
    opacity: 1
}

.our-team:hover .bg-hover-overlay:before {
    background-color: rgba(0, 0, 0, .4);
    z-index: 1
}

.slider-range .ui-slider {
    height: 3px;
    border: none
}

.slider-range .ui-slider-handle {
    width: 18px;
    height: 18px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .22);
    border: none;
    border-radius: 50%;
    top: -9px;
    margin-left: 0;
    background-color: #fff
}

.slider-range .ui-slider-handle:focus {
    border: none;
    outline: none
}

.slider-range .amount:focus {
    border: none;
    outline: none
}

.slider-range-secondary .ui-slider {
    background-color: #ededed
}

.slider-range-secondary .ui-slider-range {
    background-color: #6969eb
}

.slider-range-primary .ui-slider {
    background-color: #fff
}

.slider-range-primary .ui-slider-range {
    background-color: var(--primary)
}

.agent-3:hover {
    border-color: transparent
}

.agent-3:hover .card-footer {
    margin: -1px;
    background-color: var(--primary)
}

.agent-3:hover .card-footer a,
.agent-3:hover .card-footer .icon {
    color: #fff !important
}

.agent-3:hover .card-footer .badge {
    color: #333 !important
}

.agent-details-tabs {
    background-color: #eee;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.agent-details-tabs .nav-link {
    border-radius: 0;
    color: #ababab
}

.agent-details-tabs .nav-link.active {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.agent-details-tabs .nav-item:first-child .nav-link {
    border-top-left-radius: 5px
}

.agent-details-tabs .nav-item:last-child .nav-link {
    border-top-right-radius: 5px
}

.agent-details-tabs .nav-item:not(:last-child) .nav-link:not(.active) {
    position: relative
}

.agent-details-tabs .nav-item:not(:last-child) .nav-link:not(.active)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 24px;
    background-color: #dfdfdf
}

.listing-agents .card {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
}

@media(max-width:991.98px) {
    .listing-agents .card {
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%
    }
}

@media(max-width:767.98px) {
    .listing-agents .card {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }
}

@media(max-width:575.98px) {
    .listing-agents .card {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }
}

form input:focus {
    outline: none
}

.property-search .btn.advanced-search:after {
    font-family: "font awesome 5 pro";
    font-size: 24px;
    font-weight: 400;
    margin-left: 15px
}

.property-search .btn.advanced-search:not(.collapsed):after {
    content: "\f056"
}

.property-search .btn.advanced-search.collapsed:after {
    content: "\f055"
}

.property-search .other-feature:before {
    font-family: "font awesome 5 pro";
    font-size: 20px;
    margin-right: 5px
}

.property-search .other-feature:not(.collapsed):before {
    content: "\f146"
}

.property-search .other-feature.collapsed:before {
    content: "\f0fe"
}

.custom-switch-right.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    content: '\f00c';
    font-family: "font awesome 5 pro";
    line-height: 18px;
    font-size: 13px;
    text-align: center;
    color: var(--primary);
    right: 0;
    left: unset
}

.custom-switch-right.custom-switch .custom-control-input~.custom-control-label::before,
.custom-switch-right.custom-switch .custom-control-input~.custom-control-label::after {
    left: -1.359375rem
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff
}

.custom-switch .custom-control-label::after {
    background-color: var(--primary)
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
    content: '\f00c';
    font-family: "font awesome 5 pro";
    top: .01rem;
    left: -1.75rem;
    color: #fff
}

.custom-checkbox .custom-control-label {
    cursor: pointer
}

.form-control::-webkit-input-placeholder {
    color: #ababab
}

.form-control::-moz-placeholder {
    color: #ababab
}

.form-control:-ms-input-placeholder {
    color: #ababab
}

.form-control::-ms-input-placeholder {
    color: #ababab
}

.form-control::placeholder {
    color: #ababab
}

#map {
    height: 430px
}

#map:focus,
#map .mapboxgl-canvas {
    outline: none
}

.map-point-animate .marker:before,
.map-point-animate .marker:after {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-clip: padding-box;
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: gel-map-animationSignal linear 2s;
    animation: gel-map-animationSignal linear 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    display: block;
    box-shadow: inset 0 0 35px 10px var(--primary);
    border-color: var(--primary);
    content: ''
}

.map-point-animate .marker:before {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.map-point-animate .marker:after {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

@-webkit-keyframes gel-map-animationSignal {
    0% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(.3);
        transform: translate(-50%, -50%) scale(.3)
    }

    1% {
        -webkit-transform: translate(-50%, -50%) scale(.32);
        transform: translate(-50%, -50%) scale(.32);
        opacity: .1
    }

    20% {
        opacity: .5;
        -webkit-transform: translate(-50%, -50%) scale(0 0.45);
        transform: translate(-50%, -50%) scale(0 0.45)
    }

    60% {
        -webkit-transform: translate(-50%, -50%) scale(.75);
        transform: translate(-50%, -50%) scale(.75);
        opacity: .35
    }
}

@keyframes gel-map-animationSignal {
    0% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(.3);
        transform: translate(-50%, -50%) scale(.3)
    }

    1% {
        -webkit-transform: translate(-50%, -50%) scale(.32);
        transform: translate(-50%, -50%) scale(.32);
        opacity: .1
    }

    20% {
        opacity: .5;
        -webkit-transform: translate(-50%, -50%) scale(0 0.45);
        transform: translate(-50%, -50%) scale(0 0.45)
    }

    60% {
        -webkit-transform: translate(-50%, -50%) scale(.75);
        transform: translate(-50%, -50%) scale(.75);
        opacity: .35
    }
}

.popup-map-property,
.popup-map-property-02 {
    width: 100%
}

.popup-map-property .mapboxgl-popup-content,
.popup-map-property-02 .mapboxgl-popup-content {
    padding: 0;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, .3);
    height: 100%
}

.popup-map-property .mapboxgl-popup-close-button {
    font-size: 20px;
    z-index: 10
}

.popup-map-property-02 .mapboxgl-popup-content {
    border-radius: 5px
}

.popup-map-property-02 .mapboxgl-popup-close-button {
    font-size: 0
}

.popup-map-property-02 .badge {
    position: absolute;
    top: -12px;
    right: 0
}

.dashboard-wrapper .main-header .badge-absolute {
    top: 20px;
    right: 17px
}

@media(max-width:1199.98px) {
    .dashboard-wrapper .main-header .navbar {
        background-color: transparent !important
    }
}

@media(max-width:1199.98px) {
    .dashboard-wrapper .main-header {
        position: static !important
    }

    .dashboard-wrapper .main-header .dropdown-menu {
        position: absolute
    }
}

.dashboard-wrapper .db-sidebar {
    /* -ms-flex: 0 1 320px;
    flex: 0 1 320px;
    max-width: 320px;
    position : fixed; */
    /* height: 100%; */
    /* background-color: #1c4d6b !important; */
    /* position: fixed; */
     /* or "sticky" */
  /* top: 0;  */
  /* Adjust as needed based on your layout */
  /* left: 0; */
   /* Adjust as needed based on your layout */
  /* width: 304px;  */
  /* Adjust the width of the sidebar as needed */
  /* height: 100%;  */
    
}

@media(max-width:1199.98px) {
    .dashboard-wrapper .db-sidebar {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        max-width: 100%
    }
}

.dashboard-wrapper .db-sidebar .sidebar-item .sidebar-item-number {
    opacity: 0
}


.dashboard-wrapper .db-sidebar .sidebar-item-text-active{
    color:#ababab !important;
}

.dashboard-wrapper .db-sidebar .sidebar-item:hover,
.dashboard-wrapper .db-sidebar .sidebar-item.active {
    background-color: #f8f8f8;
    color: #333
}

.dashboard-wrapper .db-sidebar .sidebar-item:hover .sidebar-item-icon,
.dashboard-wrapper .db-sidebar .sidebar-item.active .sidebar-item-icon {
    color: var(--primary) !important
}

.dashboard-wrapper .db-sidebar .sidebar-item:hover .sidebar-item-number,
.dashboard-wrapper .db-sidebar .sidebar-item.active .sidebar-item-number {
    opacity: 1
}

.dashboard-wrapper .db-sidebar .sticky-area {
    background-color: #fff !important;
    /* display: none; */
    overflow-y:auto;
    width: 300px;
        height: 100vh !important;
}

@media(min-width:1200px) {
    .dashboard-wrapper .db-sidebar .sticky-area {
        /* position: relative; */
        /* width: 300px;
        height: 100vh !important; */
        /* overflow-y:auto; */
        /* -webkit-transform: translate(0, 0) !important;
        transform: translate(0, 0) !important; */
    }
}

@media(max-width:1199.98px) {
    .dashboard-wrapper .db-sidebar .sticky-area {
        width: 100%
    }
}

@media(max-width:1199.98px) {
    .dashboard-wrapper .db-sidebar .navbar-collapse {
        max-height: 500px;
        /* overflow-y: auto */
    }
}

/* .dashboard-wrapper .page-content {
    width: calc(100% - 320px);
    max-width: 100%;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-left: 300px;
} */

/* @media(max-width:1199.98px) {
    .dashboard-wrapper .page-content {
        width: 100%;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%
    }
} */

.dashboard-wrapper .new-property-step .nav-link {
    color: #696969;
    border: 1px solid transparent
}

.dashboard-wrapper .new-property-step .nav-link.active,
.dashboard-wrapper .new-property-step .nav-link:hover {
    color: var(--primary);
    background-color: #fff !important;
    border-color: #e7e7e7
}

.upload-file {
    border: 3px dashed #e7e7e7;
    border-radius: 3px
}

.upload-file .upload-icon {
    font-size: 85px;
    color: #eee
}

.upload-file .dz-image {
    margin: auto;
    overflow: visible !important
}

.table.dataTable thead th {
    border-bottom: none;
    border-top: none;
    padding: .75rem;
    font-weight: 400
}

@media(max-width:767.98px) {
    .add-new-invoice .item-table thead {
        display: none
    }

    .add-new-invoice .item-table td {
        border: none;
        padding-left: 7px;
        padding-right: 7px;
        vertical-align: middle
    }

    .add-new-invoice .item-table tr:not(:first-child) .delete-item-row {
        border-top: 1px solid #eee
    }
}

.bg-single-image {
    background-image: url("/public/images/single-image-02.png"), url("/public/images/single-image-03.png");
    background-position: left, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto
}

.bg-properties-creative {
    background-image: url("/public/images/properties-creative-01.jpg");
    background-repeat: no-repeat;
    background-size: cover
}

.bg-single-image-02 {
    background-image: url("/public/images/single-image-04.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto
}

.bg-single-image-03 {
    background-image: url("/public/images/BG7.png"), url("/public/images/BG8.png");
    background-position: left, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto
}

.bg-landing-listing {
    background-color: #e9edf2
}

@media(min-width:992px) {
    .bg-landing-listing {
        background-image: url("/public/images/bg-listing-landing-left.png"), url("/public/images/bg-listing-landing-right.png");
        background-position: left center, right center;
        background-repeat: no-repeat, no-repeat;
        background-size: auto, auto
    }
}

#compare {
    position: fixed;
    right: -1px;
    top: 50%;
    z-index: 999999;
    transition: all .5s;
    -webkit-transform: translateX(100%) translateY(-50%);
    transform: translateX(100%) translateY(-50%);
    max-width: 138px
}

#compare.show {
    -webkit-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%)
}

#compare.show .btn-open:before {
    content: "\f105"
}

#compare:not(.show) .btn-open:before {
    content: "\f104"
}

#compare .btn-open {
    position: absolute;
    right: 100%;
    top: 50%;
    display: block;
    -webkit-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%)
}

#compare .btn-open:before {
    font-family: "font awesome 5 pro";
    font-size: 22px;
    font-weight: 400
}

.primary-font {
    font-family: libre baskerville, serif !important
}

.fs-12 {
    font-size: 12px !important
}

.fs-13 {
    font-size: 13px !important
}

.fs-14 {
    font-size: 14px !important
}

.fs-15 {
    font-size: 15px !important
}

.fs-16 {
    font-size: 16px !important
}

.fs-17 {
    font-size: 17px !important
}

.fs-18 {
    font-size: 18px !important
}

.fs-20 {
    font-size: 20px !important
}

.fs-22 {
    font-size: 22px !important
}

.fs-23 {
    font-size: 23px !important
}

.fs-24 {
    font-size: 24px !important
}

.fs-25 {
    font-size: 25px !important
}

.fs-26 {
    font-size: 26px !important
}

.fs-30 {
    font-size: 30px !important
}

.fs-32 {
    font-size: 32px !important
}

.fs-34 {
    font-size: 34px !important
}

.fs-35 {
    font-size: 35px !important
}

.fs-36 {
    font-size: 36px !important
}

.fs-40 {
    font-size: 40px !important
}

.fs-42 {
    font-size: 42px !important
}

.fs-44 {
    font-size: 44px !important
}

.fs-45 {
    font-size: 45px !important
}

.fs-48 {
    font-size: 48px !important
}

.fs-52 {
    font-size: 52px !important
}

.fs-54 {
    font-size: 54px !important
}

.fs-56 {
    font-size: 56px !important
}

.fs-60 {
    font-size: 60px !important
}

.fs-64 {
    font-size: 64px !important
}

.fs-65 {
    font-size: 65px !important
}

.fs-90 {
    font-size: 90px !important
}

.fs-109 {
    font-size: 109px !important
}

.fs-200 {
    font-size: 200px !important
}

.fs-250 {
    font-size: 250px !important
}

@media(min-width:576px) {
    .fs-sm-12 {
        font-size: 12px !important
    }

    .fs-sm-13 {
        font-size: 13px !important
    }

    .fs-sm-14 {
        font-size: 14px !important
    }

    .fs-sm-15 {
        font-size: 15px !important
    }

    .fs-sm-16 {
        font-size: 16px !important
    }

    .fs-sm-17 {
        font-size: 17px !important
    }

    .fs-sm-18 {
        font-size: 18px !important
    }

    .fs-sm-20 {
        font-size: 20px !important
    }

    .fs-sm-22 {
        font-size: 22px !important
    }

    .fs-sm-23 {
        font-size: 23px !important
    }

    .fs-sm-24 {
        font-size: 24px !important
    }

    .fs-sm-25 {
        font-size: 25px !important
    }

    .fs-sm-26 {
        font-size: 26px !important
    }

    .fs-sm-30 {
        font-size: 30px !important
    }

    .fs-sm-32 {
        font-size: 32px !important
    }

    .fs-sm-34 {
        font-size: 34px !important
    }

    .fs-sm-35 {
        font-size: 35px !important
    }

    .fs-sm-36 {
        font-size: 36px !important
    }

    .fs-sm-40 {
        font-size: 40px !important
    }

    .fs-sm-42 {
        font-size: 42px !important
    }

    .fs-sm-44 {
        font-size: 44px !important
    }

    .fs-sm-45 {
        font-size: 45px !important
    }

    .fs-sm-48 {
        font-size: 48px !important
    }

    .fs-sm-52 {
        font-size: 52px !important
    }

    .fs-sm-54 {
        font-size: 54px !important
    }

    .fs-sm-56 {
        font-size: 56px !important
    }

    .fs-sm-60 {
        font-size: 60px !important
    }

    .fs-sm-64 {
        font-size: 64px !important
    }

    .fs-sm-65 {
        font-size: 65px !important
    }

    .fs-sm-90 {
        font-size: 90px !important
    }

    .fs-sm-109 {
        font-size: 109px !important
    }

    .fs-sm-200 {
        font-size: 200px !important
    }

    .fs-sm-250 {
        font-size: 250px !important
    }
}

@media(min-width:768px) {
    .fs-md-12 {
        font-size: 12px !important
    }

    .fs-md-13 {
        font-size: 13px !important
    }

    .fs-md-14 {
        font-size: 14px !important
    }

    .fs-md-15 {
        font-size: 15px !important
    }

    .fs-md-16 {
        font-size: 16px !important
    }

    .fs-md-17 {
        font-size: 17px !important
    }

    .fs-md-18 {
        font-size: 18px !important
    }

    .fs-md-20 {
        font-size: 20px !important
    }

    .fs-md-22 {
        font-size: 22px !important
    }

    .fs-md-23 {
        font-size: 23px !important
    }

    .fs-md-24 {
        font-size: 24px !important
    }

    .fs-md-25 {
        font-size: 25px !important
    }

    .fs-md-26 {
        font-size: 26px !important
    }

    .fs-md-30 {
        font-size: 30px !important
    }

    .fs-md-32 {
        font-size: 32px !important
    }

    .fs-md-34 {
        font-size: 34px !important
    }

    .fs-md-35 {
        font-size: 35px !important
    }

    .fs-md-36 {
        font-size: 36px !important
    }

    .fs-md-40 {
        font-size: 40px !important
    }

    .fs-md-42 {
        font-size: 42px !important
    }

    .fs-md-44 {
        font-size: 44px !important
    }

    .fs-md-45 {
        font-size: 45px !important
    }

    .fs-md-48 {
        font-size: 48px !important
    }

    .fs-md-52 {
        font-size: 52px !important
    }

    .fs-md-54 {
        font-size: 54px !important
    }

    .fs-md-56 {
        font-size: 56px !important
    }

    .fs-md-60 {
        font-size: 60px !important
    }

    .fs-md-64 {
        font-size: 64px !important
    }

    .fs-md-65 {
        font-size: 65px !important
    }

    .fs-md-90 {
        font-size: 90px !important
    }

    .fs-md-109 {
        font-size: 109px !important
    }

    .fs-md-200 {
        font-size: 200px !important
    }

    .fs-md-250 {
        font-size: 250px !important
    }
}

@media(min-width:992px) {
    .fs-lg-12 {
        font-size: 12px !important
    }

    .fs-lg-13 {
        font-size: 13px !important
    }

    .fs-lg-14 {
        font-size: 14px !important
    }

    .fs-lg-15 {
        font-size: 15px !important
    }

    .fs-lg-16 {
        font-size: 16px !important
    }

    .fs-lg-17 {
        font-size: 17px !important
    }

    .fs-lg-18 {
        font-size: 18px !important
    }

    .fs-lg-20 {
        font-size: 20px !important
    }

    .fs-lg-22 {
        font-size: 22px !important
    }

    .fs-lg-23 {
        font-size: 23px !important
    }

    .fs-lg-24 {
        font-size: 24px !important
    }

    .fs-lg-25 {
        font-size: 25px !important
    }

    .fs-lg-26 {
        font-size: 26px !important
    }

    .fs-lg-30 {
        font-size: 30px !important
    }

    .fs-lg-32 {
        font-size: 32px !important
    }

    .fs-lg-34 {
        font-size: 34px !important
    }

    .fs-lg-35 {
        font-size: 35px !important
    }

    .fs-lg-36 {
        font-size: 36px !important
    }

    .fs-lg-40 {
        font-size: 40px !important
    }

    .fs-lg-42 {
        font-size: 42px !important
    }

    .fs-lg-44 {
        font-size: 44px !important
    }

    .fs-lg-45 {
        font-size: 45px !important
    }

    .fs-lg-48 {
        font-size: 48px !important
    }

    .fs-lg-52 {
        font-size: 52px !important
    }

    .fs-lg-54 {
        font-size: 54px !important
    }

    .fs-lg-56 {
        font-size: 56px !important
    }

    .fs-lg-60 {
        font-size: 60px !important
    }

    .fs-lg-64 {
        font-size: 64px !important
    }

    .fs-lg-65 {
        font-size: 65px !important
    }

    .fs-lg-90 {
        font-size: 90px !important
    }

    .fs-lg-109 {
        font-size: 109px !important
    }

    .fs-lg-200 {
        font-size: 200px !important
    }

    .fs-lg-250 {
        font-size: 250px !important
    }
}

@media(min-width:1200px) {
    .fs-xl-12 {
        font-size: 12px !important
    }

    .fs-xl-13 {
        font-size: 13px !important
    }

    .fs-xl-14 {
        font-size: 14px !important
    }

    .fs-xl-15 {
        font-size: 15px !important
    }

    .fs-xl-16 {
        font-size: 16px !important
    }

    .fs-xl-17 {
        font-size: 17px !important
    }

    .fs-xl-18 {
        font-size: 18px !important
    }

    .fs-xl-20 {
        font-size: 20px !important
    }

    .fs-xl-22 {
        font-size: 22px !important
    }

    .fs-xl-23 {
        font-size: 23px !important
    }

    .fs-xl-24 {
        font-size: 24px !important
    }

    .fs-xl-25 {
        font-size: 25px !important
    }

    .fs-xl-26 {
        font-size: 26px !important
    }

    .fs-xl-30 {
        font-size: 30px !important
    }

    .fs-xl-32 {
        font-size: 32px !important
    }

    .fs-xl-34 {
        font-size: 34px !important
    }

    .fs-xl-35 {
        font-size: 35px !important
    }

    .fs-xl-36 {
        font-size: 36px !important
    }

    .fs-xl-40 {
        font-size: 40px !important
    }

    .fs-xl-42 {
        font-size: 42px !important
    }

    .fs-xl-44 {
        font-size: 44px !important
    }

    .fs-xl-45 {
        font-size: 45px !important
    }

    .fs-xl-48 {
        font-size: 48px !important
    }

    .fs-xl-52 {
        font-size: 52px !important
    }

    .fs-xl-54 {
        font-size: 54px !important
    }

    .fs-xl-56 {
        font-size: 56px !important
    }

    .fs-xl-60 {
        font-size: 60px !important
    }

    .fs-xl-64 {
        font-size: 64px !important
    }

    .fs-xl-65 {
        font-size: 65px !important
    }

    .fs-xl-90 {
        font-size: 90px !important
    }

    .fs-xl-109 {
        font-size: 109px !important
    }

    .fs-xl-200 {
        font-size: 200px !important
    }

    .fs-xl-250 {
        font-size: 250px !important
    }
}

@media(min-width:1600px) {
    .fs-xxl-12 {
        font-size: 12px !important
    }

    .fs-xxl-13 {
        font-size: 13px !important
    }

    .fs-xxl-14 {
        font-size: 14px !important
    }

    .fs-xxl-15 {
        font-size: 15px !important
    }

    .fs-xxl-16 {
        font-size: 16px !important
    }

    .fs-xxl-17 {
        font-size: 17px !important
    }

    .fs-xxl-18 {
        font-size: 18px !important
    }

    .fs-xxl-20 {
        font-size: 20px !important
    }

    .fs-xxl-22 {
        font-size: 22px !important
    }

    .fs-xxl-23 {
        font-size: 23px !important
    }

    .fs-xxl-24 {
        font-size: 24px !important
    }

    .fs-xxl-25 {
        font-size: 25px !important
    }

    .fs-xxl-26 {
        font-size: 26px !important
    }

    .fs-xxl-30 {
        font-size: 30px !important
    }

    .fs-xxl-32 {
        font-size: 32px !important
    }

    .fs-xxl-34 {
        font-size: 34px !important
    }

    .fs-xxl-35 {
        font-size: 35px !important
    }

    .fs-xxl-36 {
        font-size: 36px !important
    }

    .fs-xxl-40 {
        font-size: 40px !important
    }

    .fs-xxl-42 {
        font-size: 42px !important
    }

    .fs-xxl-44 {
        font-size: 44px !important
    }

    .fs-xxl-45 {
        font-size: 45px !important
    }

    .fs-xxl-48 {
        font-size: 48px !important
    }

    .fs-xxl-52 {
        font-size: 52px !important
    }

    .fs-xxl-54 {
        font-size: 54px !important
    }

    .fs-xxl-56 {
        font-size: 56px !important
    }

    .fs-xxl-60 {
        font-size: 60px !important
    }

    .fs-xxl-64 {
        font-size: 64px !important
    }

    .fs-xxl-65 {
        font-size: 65px !important
    }

    .fs-xxl-90 {
        font-size: 90px !important
    }

    .fs-xxl-109 {
        font-size: 109px !important
    }

    .fs-xxl-200 {
        font-size: 200px !important
    }

    .fs-xxl-250 {
        font-size: 250px !important
    }
}

.border-0 {
    border: none !important
}

.shadow-none {
    box-shadow: none !important
}

.border {
    border: 1px solid #eee !important
}

.border-top {
    border-top: 1px solid #eee !important
}

.border-right {
    border-right: 1px solid #eee !important
}

.border-bottom {
    /* border: 1px solid #eee !important; */
   
}

.border-left {
    border-left: 1px solid #eee !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

@media(min-width:576px) {
    .w-sm-100 {
        width: 100% !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .border-sm-0 {
        border: none !important
    }

    .shadow-sm-none {
        box-shadow: none !important
    }

    .border-sm {
        border: 1px solid #eee !important
    }

    .border-sm-top {
        border-top: 1px solid #eee !important
    }

    .border-sm-right {
        border-right: 1px solid #eee !important
    }

    .border-sm-bottom {
        border-bottom: 1px solid #eee !important
    }

    .border-sm-left {
        border-left: 1px solid #eee !important
    }

    .border-sm-top-0 {
        border-top: 0 !important
    }

    .border-sm-right-0 {
        border-right: 0 !important
    }

    .border-sm-bottom-0 {
        border-bottom: 0 !important
    }

    .border-sm-left-0 {
        border-left: 0 !important
    }
}

@media(min-width:768px) {
    .w-md-100 {
        width: 100% !important
    }

    .w-md-auto {
        width: auto !important
    }

    .border-md-0 {
        border: none !important
    }

    .shadow-md-none {
        box-shadow: none !important
    }

    .border-md {
        border: 1px solid #eee !important
    }

    .border-md-top {
        border-top: 1px solid #eee !important
    }

    .border-md-right {
        border-right: 1px solid #eee !important
    }

    .border-md-bottom {
        border-bottom: 1px solid #eee !important
    }

    .border-md-left {
        border-left: 1px solid #eee !important
    }

    .border-md-top-0 {
        border-top: 0 !important
    }

    .border-md-right-0 {
        border-right: 0 !important
    }

    .border-md-bottom-0 {
        border-bottom: 0 !important
    }

    .border-md-left-0 {
        border-left: 0 !important
    }
}

@media(min-width:992px) {
    .w-lg-100 {
        width: 100% !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .border-lg-0 {
        border: none !important
    }

    .shadow-lg-none {
        box-shadow: none !important
    }

    .border-lg {
        border: 1px solid #eee !important
    }

    .border-lg-top {
        border-top: 1px solid #eee !important
    }

    .border-lg-right {
        border-right: 1px solid #eee !important
    }

    .border-lg-bottom {
        border-bottom: 1px solid #eee !important
    }

    .border-lg-left {
        border-left: 1px solid #eee !important
    }

    .border-lg-top-0 {
        border-top: 0 !important
    }

    .border-lg-right-0 {
        border-right: 0 !important
    }

    .border-lg-bottom-0 {
        border-bottom: 0 !important
    }

    .border-lg-left-0 {
        border-left: 0 !important
    }
}

@media(min-width:1200px) {
    .w-xl-100 {
        width: 100% !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .border-xl-0 {
        border: none !important
    }

    .shadow-xl-none {
        box-shadow: none !important
    }

    .border-xl {
        border: 1px solid #eee !important
    }

    .border-xl-top {
        border-top: 1px solid #eee !important
    }

    .border-xl-right {
        border-right: 1px solid #eee !important
    }

    .border-xl-bottom {
        border-bottom: 1px solid #eee !important
    }

    .border-xl-left {
        border-left: 1px solid #eee !important
    }

    .border-xl-top-0 {
        border-top: 0 !important
    }

    .border-xl-right-0 {
        border-right: 0 !important
    }

    .border-xl-bottom-0 {
        border-bottom: 0 !important
    }

    .border-xl-left-0 {
        border-left: 0 !important
    }
}

@media(min-width:1600px) {
    .w-xxl-100 {
        width: 100% !important
    }

    .w-xxl-auto {
        width: auto !important
    }

    .border-xxl-0 {
        border: none !important
    }

    .shadow-xxl-none {
        box-shadow: none !important
    }

    .border-xxl {
        border: 1px solid #eee !important
    }

    .border-xxl-top {
        border-top: 1px solid #eee !important
    }

    .border-xxl-right {
        border-right: 1px solid #eee !important
    }

    .border-xxl-bottom {
        border-bottom: 1px solid #eee !important
    }

    .border-xxl-left {
        border-left: 1px solid #eee !important
    }

    .border-xxl-top-0 {
        border-top: 0 !important
    }

    .border-xxl-right-0 {
        border-right: 0 !important
    }

    .border-xxl-bottom-0 {
        border-bottom: 0 !important
    }

    .border-xxl-left-0 {
        border-left: 0 !important
    }
}

.lh-1 {
    line-height: 1 !important
}

.lh-114 {
    line-height: 1.14 !important
}

.lh-12 {
    line-height: 1.2 !important
}

.lh-13 {
    line-height: 1.3 !important
}

.lh-143 {
    line-height: 1.43 !important
}

.lh-15 {
    line-height: 1.5 !important
}

.lh-16 {
    line-height: 1.6 !important
}

.lh-1625 {
    line-height: 1.625 !important
}

.lh-163 {
    line-height: 1.63 !important
}

.lh-164 {
    line-height: 1.64 !important
}

.lh-17 {
    line-height: 1.7 !important
}

.lh-173 {
    line-height: 1.73 !important
}

.lh-182 {
    line-height: 1.82 !important
}

.lh-184 {
    line-height: 1.84 !important
}

.lh-186 {
    line-height: 1.86 !important
}

.lh-187 {
    line-height: 1.87 !important
}

.lh-188 {
    line-height: 1.88 !important
}

.lh-192 {
    line-height: 1.92 !important
}

.lh-194 {
    line-height: 1.94 !important
}

.lh-2 {
    line-height: 2 !important
}

.lh-213 {
    line-height: 2.13 !important
}

.lh-214 {
    line-height: 2.14 !important
}

.lh-22 {
    line-height: 2.2 !important
}

.lh-238 {
    line-height: 2.38 !important
}

.lh-26 {
    line-height: 2.6 !important
}

.lh-29 {
    line-height: 2.9 !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-hover-0:hover {
    opacity: 0 !important
}

.opacity-1 {
    opacity: .1 !important
}

.opacity-hover-1:hover {
    opacity: .1 !important
}

.opacity-2 {
    opacity: .2 !important
}

.opacity-hover-2:hover {
    opacity: .2 !important
}

.opacity-3 {
    opacity: .3 !important
}

.opacity-hover-3:hover {
    opacity: .3 !important
}

.opacity-4 {
    opacity: .4 !important
}

.opacity-hover-4:hover {
    opacity: .4 !important
}

.opacity-5 {
    opacity: .5 !important
}

.opacity-hover-5:hover {
    opacity: .5 !important
}

.opacity-6 {
    opacity: .6 !important
}

.opacity-hover-6:hover {
    opacity: .6 !important
}

.opacity-7 {
    opacity: .7 !important
}

.opacity-hover-7:hover {
    opacity: .7 !important
}

.opacity-8 {
    opacity: .8 !important
}

.opacity-hover-8:hover {
    opacity: .8 !important
}

.opacity-9 {
    opacity: .9 !important
}

.opacity-hover-9:hover {
    opacity: .9 !important
}

.opacity-10 {
    opacity: 1 !important
}

.opacity-hover-10:hover {
    opacity: 1 !important
}

.z-index-1 {
    z-index: 1 !important
}

.z-index-2 {
    z-index: 2 !important
}

.z-index-3 {
    z-index: 3 !important
}

.z-index-4 {
    z-index: 4 !important
}

.z-index-5 {
    z-index: 5 !important
}

.z-index-6 {
    z-index: 6 !important
}

.z-index-7 {
    z-index: 7 !important
}

.z-index-8 {
    z-index: 8 !important
}

.z-index-9 {
    z-index: 9 !important
}

.z-index-10 {
    z-index: 10 !important
}

.bg-gradient-1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 76%, #000000 108%) !important
}

.bg-overlay-gradient-1 {
    position: relative
}

.bg-overlay-gradient-1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 76%, #000000 108%) !important;
    z-index: 1;
    border-radius: inherit
}

.bg-hover-overlay-gradient-1:hover:before {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 76%, #000000 108%) !important
}

.bg-gradient-2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0), #000000) !important
}

.bg-overlay-gradient-2 {
    position: relative
}

.bg-overlay-gradient-2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), #000000) !important;
    z-index: 1;
    border-radius: inherit
}

.bg-hover-overlay-gradient-2:hover:before {
    background-image: linear-gradient(rgba(0, 0, 0, 0), #000000) !important
}

.bg-gradient-3 {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 5%, #000000 109%) !important
}

.bg-overlay-gradient-3 {
    position: relative
}

.bg-overlay-gradient-3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 5%, #000000 109%) !important;
    z-index: 1;
    border-radius: inherit
}

.bg-hover-overlay-gradient-3:hover:before {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 5%, #000000 109%) !important
}

.bg-gradient-4 {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 11%, #000000 99%) !important
}

.bg-overlay-gradient-4 {
    position: relative
}

.bg-overlay-gradient-4::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 11%, #000000 99%) !important;
    z-index: 1;
    border-radius: inherit
}

.bg-hover-overlay-gradient-4:hover:before {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 11%, #000000 99%) !important
}

.bg-gradient-5 {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 20%, #000000 125%) !important
}

.bg-overlay-gradient-5 {
    position: relative
}

.bg-overlay-gradient-5::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 20%, #000000 125%) !important;
    z-index: 1;
    border-radius: inherit
}

.bg-hover-overlay-gradient-5:hover:before {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 20%, #000000 125%) !important
}

.bg-gradient-6 {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 42%, #000000) !important
}

.bg-overlay-gradient-6 {
    position: relative
}

.bg-overlay-gradient-6::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 42%, #000000) !important;
    z-index: 1;
    border-radius: inherit
}

.bg-hover-overlay-gradient-6:hover:before {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 42%, #000000) !important
}

.bg-gradient-7 {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 72%, #000000 109%) !important
}

.bg-overlay-gradient-7 {
    position: relative
}

.bg-overlay-gradient-7::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 72%, #000000 109%) !important;
    z-index: 1;
    border-radius: inherit
}

.bg-hover-overlay-gradient-7:hover:before {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 72%, #000000 109%) !important
}

.bg-gradient-8 {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, #000000 125%) !important
}

.bg-overlay-gradient-8 {
    position: relative
}

.bg-overlay-gradient-8::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, #000000 125%) !important;
    z-index: 1;
    border-radius: inherit
}

.bg-hover-overlay-gradient-8:hover:before {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, #000000 125%) !important
}

.shadow-xxs-1 {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
}

.shadow-hover-xxs-1:hover {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
}

.shadow-xxs-2 {
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
}

.shadow-hover-xxs-2:hover {
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
}

.shadow-xxs-3 {
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
}

.shadow-hover-xxs-3:hover {
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
}

.shadow-xxs-4 {
    box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
}

.shadow-hover-xxs-4:hover {
    box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
}

.shadow-xxs-5 {
    box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
}

.shadow-hover-xxs-5:hover {
    box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
}

.shadow-xs-1 {
    box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
}

.shadow-hover-xs-1:hover {
    box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
}

.shadow-xs-2 {
    box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
}

.shadow-hover-xs-2:hover {
    box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
}

.shadow-xs-3 {
    box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
}

.shadow-hover-xs-3:hover {
    box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
}

.shadow-xs-4 {
    box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
}

.shadow-hover-xs-4:hover {
    box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
}

.shadow-xs-5 {
    box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
}

.shadow-hover-xs-5:hover {
    box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
}

.shadow-xs-6 {
    box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
}

.shadow-hover-xs-6:hover {
    box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
}

.shadow-sm-1 {
    box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
}

.shadow-hover-sm-1:hover {
    box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
}

.shadow-sm-2 {
    box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
}

.shadow-hover-sm-2:hover {
    box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
}

.shadow-1 {
    box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
}

.shadow-hover-1:hover {
    box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
}

.shadow-2 {
    box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
}

.shadow-hover-2:hover {
    box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
}

.shadow-3 {
    box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
}

.shadow-hover-3:hover {
    box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
}

.shadow-4 {
    box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
}

.shadow-hover-4:hover {
    box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
}

.shadow-5 {
    box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
}

.shadow-hover-5:hover {
    box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
}

.shadow-lg-1 {
    box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
}

.shadow-hover-lg-1:hover {
    box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
}

.shadow-lg-2 {
    box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
}

.shadow-hover-lg-2:hover {
    box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
}

.shadow-lg-3 {
    box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
}

.shadow-hover-lg-3:hover {
    box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
}

.shadow-lg-4 {
    box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
}

.shadow-hover-lg-4:hover {
    box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
}

.shadow-lg-5 {
    box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
}

.shadow-hover-lg-5:hover {
    box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
}

.shadow-lg-6 {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
}

.shadow-hover-lg-6:hover {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
}

@media(min-width:576px) {
    .shadow-sm-xxs-1 {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
    }

    .shadow-hover-sm-xxs-1:hover {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
    }

    .shadow-sm-xxs-2 {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-sm-xxs-2:hover {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-sm-xxs-3 {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-sm-xxs-3:hover {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-sm-xxs-4 {
        box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-sm-xxs-4:hover {
        box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-sm-xxs-5 {
        box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
    }

    .shadow-hover-sm-xxs-5:hover {
        box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
    }

    .shadow-sm-xs-1 {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-hover-sm-xs-1:hover {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-sm-xs-2 {
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-hover-sm-xs-2:hover {
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-sm-xs-3 {
        box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-sm-xs-3:hover {
        box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-sm-xs-4 {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-sm-xs-4:hover {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-sm-xs-5 {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-sm-xs-5:hover {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-sm-xs-6 {
        box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
    }

    .shadow-hover-sm-xs-6:hover {
        box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
    }

    .shadow-sm-sm-1 {
        box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-hover-sm-sm-1:hover {
        box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-sm-sm-2 {
        box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
    }

    .shadow-hover-sm-sm-2:hover {
        box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
    }

    .shadow-sm-1 {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-sm-1:hover {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-sm-2 {
        box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
    }

    .shadow-hover-sm-2:hover {
        box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
    }

    .shadow-sm-3 {
        box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-hover-sm-3:hover {
        box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-sm-4 {
        box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-hover-sm-4:hover {
        box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-sm-5 {
        box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-hover-sm-5:hover {
        box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-sm-lg-1 {
        box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-sm-lg-1:hover {
        box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-sm-lg-2 {
        box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-sm-lg-2:hover {
        box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-sm-lg-3 {
        box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
    }

    .shadow-hover-sm-lg-3:hover {
        box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
    }

    .shadow-sm-lg-4 {
        box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
    }

    .shadow-hover-sm-lg-4:hover {
        box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
    }

    .shadow-sm-lg-5 {
        box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-hover-sm-lg-5:hover {
        box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-sm-lg-6 {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-hover-sm-lg-6:hover {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
    }
}

@media(min-width:768px) {
    .shadow-md-xxs-1 {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
    }

    .shadow-hover-md-xxs-1:hover {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
    }

    .shadow-md-xxs-2 {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-md-xxs-2:hover {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-md-xxs-3 {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-md-xxs-3:hover {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-md-xxs-4 {
        box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-md-xxs-4:hover {
        box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-md-xxs-5 {
        box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
    }

    .shadow-hover-md-xxs-5:hover {
        box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
    }

    .shadow-md-xs-1 {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-hover-md-xs-1:hover {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-md-xs-2 {
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-hover-md-xs-2:hover {
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-md-xs-3 {
        box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-md-xs-3:hover {
        box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-md-xs-4 {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-md-xs-4:hover {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-md-xs-5 {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-md-xs-5:hover {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-md-xs-6 {
        box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
    }

    .shadow-hover-md-xs-6:hover {
        box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
    }

    .shadow-md-sm-1 {
        box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-hover-md-sm-1:hover {
        box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-md-sm-2 {
        box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
    }

    .shadow-hover-md-sm-2:hover {
        box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
    }

    .shadow-md-1 {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-md-1:hover {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-md-2 {
        box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
    }

    .shadow-hover-md-2:hover {
        box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
    }

    .shadow-md-3 {
        box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-hover-md-3:hover {
        box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-md-4 {
        box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-hover-md-4:hover {
        box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-md-5 {
        box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-hover-md-5:hover {
        box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-md-lg-1 {
        box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-md-lg-1:hover {
        box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-md-lg-2 {
        box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-md-lg-2:hover {
        box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-md-lg-3 {
        box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
    }

    .shadow-hover-md-lg-3:hover {
        box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
    }

    .shadow-md-lg-4 {
        box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
    }

    .shadow-hover-md-lg-4:hover {
        box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
    }

    .shadow-md-lg-5 {
        box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-hover-md-lg-5:hover {
        box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-md-lg-6 {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-hover-md-lg-6:hover {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
    }
}

@media(min-width:992px) {
    .shadow-lg-xxs-1 {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
    }

    .shadow-hover-lg-xxs-1:hover {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
    }

    .shadow-lg-xxs-2 {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-lg-xxs-2:hover {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-lg-xxs-3 {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-lg-xxs-3:hover {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-lg-xxs-4 {
        box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-lg-xxs-4:hover {
        box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-lg-xxs-5 {
        box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
    }

    .shadow-hover-lg-xxs-5:hover {
        box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
    }

    .shadow-lg-xs-1 {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-hover-lg-xs-1:hover {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-lg-xs-2 {
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-hover-lg-xs-2:hover {
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-lg-xs-3 {
        box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-lg-xs-3:hover {
        box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-lg-xs-4 {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-lg-xs-4:hover {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-lg-xs-5 {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-lg-xs-5:hover {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-lg-xs-6 {
        box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
    }

    .shadow-hover-lg-xs-6:hover {
        box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
    }

    .shadow-lg-sm-1 {
        box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-hover-lg-sm-1:hover {
        box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-lg-sm-2 {
        box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
    }

    .shadow-hover-lg-sm-2:hover {
        box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
    }

    .shadow-lg-1 {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-lg-1:hover {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-lg-2 {
        box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
    }

    .shadow-hover-lg-2:hover {
        box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
    }

    .shadow-lg-3 {
        box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-hover-lg-3:hover {
        box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-lg-4 {
        box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-hover-lg-4:hover {
        box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-lg-5 {
        box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-hover-lg-5:hover {
        box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-lg-lg-1 {
        box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-lg-lg-1:hover {
        box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-lg-lg-2 {
        box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-lg-lg-2:hover {
        box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-lg-lg-3 {
        box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
    }

    .shadow-hover-lg-lg-3:hover {
        box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
    }

    .shadow-lg-lg-4 {
        box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
    }

    .shadow-hover-lg-lg-4:hover {
        box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
    }

    .shadow-lg-lg-5 {
        box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-hover-lg-lg-5:hover {
        box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-lg-lg-6 {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-hover-lg-lg-6:hover {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
    }
}

@media(min-width:1200px) {
    .shadow-xl-xxs-1 {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
    }

    .shadow-hover-xl-xxs-1:hover {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
    }

    .shadow-xl-xxs-2 {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-xl-xxs-2:hover {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-xl-xxs-3 {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-xl-xxs-3:hover {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-xl-xxs-4 {
        box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-xl-xxs-4:hover {
        box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-xl-xxs-5 {
        box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
    }

    .shadow-hover-xl-xxs-5:hover {
        box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
    }

    .shadow-xl-xs-1 {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-hover-xl-xs-1:hover {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-xl-xs-2 {
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-hover-xl-xs-2:hover {
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-xl-xs-3 {
        box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-xl-xs-3:hover {
        box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-xl-xs-4 {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-xl-xs-4:hover {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-xl-xs-5 {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-xl-xs-5:hover {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-xl-xs-6 {
        box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
    }

    .shadow-hover-xl-xs-6:hover {
        box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
    }

    .shadow-xl-sm-1 {
        box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-hover-xl-sm-1:hover {
        box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-xl-sm-2 {
        box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
    }

    .shadow-hover-xl-sm-2:hover {
        box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
    }

    .shadow-xl-1 {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-xl-1:hover {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-xl-2 {
        box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
    }

    .shadow-hover-xl-2:hover {
        box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
    }

    .shadow-xl-3 {
        box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-hover-xl-3:hover {
        box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-xl-4 {
        box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-hover-xl-4:hover {
        box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-xl-5 {
        box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-hover-xl-5:hover {
        box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-xl-lg-1 {
        box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-xl-lg-1:hover {
        box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-xl-lg-2 {
        box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-xl-lg-2:hover {
        box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-xl-lg-3 {
        box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
    }

    .shadow-hover-xl-lg-3:hover {
        box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
    }

    .shadow-xl-lg-4 {
        box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
    }

    .shadow-hover-xl-lg-4:hover {
        box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
    }

    .shadow-xl-lg-5 {
        box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-hover-xl-lg-5:hover {
        box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-xl-lg-6 {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-hover-xl-lg-6:hover {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
    }
}

@media(min-width:1600px) {
    .shadow-xxl-xxs-1 {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
    }

    .shadow-hover-xxl-xxs-1:hover {
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .07) !important
    }

    .shadow-xxl-xxs-2 {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-xxl-xxs-2:hover {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-xxl-xxs-3 {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-xxl-xxs-3:hover {
        box-shadow: 0 0 10px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-xxl-xxs-4 {
        box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-xxl-xxs-4:hover {
        box-shadow: 0 0 20px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-xxl-xxs-5 {
        box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
    }

    .shadow-hover-xxl-xxs-5:hover {
        box-shadow: 0 0 20px 0 rgba(51, 51, 20, .1) !important
    }

    .shadow-xxl-xs-1 {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-hover-xxl-xs-1:hover {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-xxl-xs-2 {
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-hover-xxl-xs-2:hover {
        box-shadow: 0 5px 15px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-xxl-xs-3 {
        box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-xxl-xs-3:hover {
        box-shadow: 0 11px 19px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-xxl-xs-4 {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-xxl-xs-4:hover {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-xxl-xs-5 {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-xxl-xs-5:hover {
        box-shadow: 0 2px 15px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-xxl-xs-6 {
        box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
    }

    .shadow-hover-xxl-xs-6:hover {
        box-shadow: 0 2px 16px 0 rgba(14, 198, 213, .34) !important
    }

    .shadow-xxl-sm-1 {
        box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-hover-xxl-sm-1:hover {
        box-shadow: 0 5px 20px 0 rgba(51, 51, 51, .08) !important
    }

    .shadow-xxl-sm-2 {
        box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
    }

    .shadow-hover-xxl-sm-2:hover {
        box-shadow: 0 5px 30px 0 rgba(51, 51, 51, .2) !important
    }

    .shadow-xxl-1 {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-hover-xxl-1:hover {
        box-shadow: 0 11px 30px 0 rgba(51, 51, 51, .15) !important
    }

    .shadow-xxl-2 {
        box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
    }

    .shadow-hover-xxl-2:hover {
        box-shadow: 0 20px 15px 0 rgba(51, 51, 51, .03) !important
    }

    .shadow-xxl-3 {
        box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-hover-xxl-3:hover {
        box-shadow: 0 20px 30px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-xxl-4 {
        box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-hover-xxl-4:hover {
        box-shadow: 0 19px 34px 0 rgba(51, 51, 51, .06) !important
    }

    .shadow-xxl-5 {
        box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-hover-xxl-5:hover {
        box-shadow: 0 7px 15px 0 rgba(51, 51, 51, .05) !important
    }

    .shadow-xxl-lg-1 {
        box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-xxl-lg-1:hover {
        box-shadow: 0 30px 50px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-xxl-lg-2 {
        box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-hover-xxl-lg-2:hover {
        box-shadow: 0 18px 40px 0 rgba(51, 51, 51, .1) !important
    }

    .shadow-xxl-lg-3 {
        box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
    }

    .shadow-hover-xxl-lg-3:hover {
        box-shadow: 0 14px 59px 0 rgba(0, 0, 0, .12) !important
    }

    .shadow-xxl-lg-4 {
        box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
    }

    .shadow-hover-xxl-lg-4:hover {
        box-shadow: 0 19px 34px 0 rgba(94, 122, 183, .14) !important
    }

    .shadow-xxl-lg-5 {
        box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-hover-xxl-lg-5:hover {
        box-shadow: 0 0 29px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-xxl-lg-6 {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
    }

    .shadow-hover-xxl-lg-6:hover {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .05) !important
    }
}

.letter-spacing-02 {
    letter-spacing: .2px !important
}

.letter-spacing-023 {
    letter-spacing: .23px !important
}

.letter-spacing-087 {
    letter-spacing: .87px !important
}

.letter-spacing-093 {
    letter-spacing: .93px !important
}

.letter-spacing-1 {
    letter-spacing: 1px !important
}

.letter-spacing-163 {
    letter-spacing: 1.63px !important
}

.letter-spacing-173 {
    letter-spacing: 1.73px !important
}

.letter-spacing-263 {
    letter-spacing: 2.63px !important
}

.letter-spacing-367 {
    letter-spacing: 3.67px !important
}

.letter-spacing-34 {
    letter-spacing: 3.4px !important
}

.letter-spacing-5 {
    letter-spacing: 5px !important
}

.bg-hover-gradient:hover {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 5%, #000000 109%) !important
}

.line-height-base {
    line-height: 1.86 !important
}

.fs-large-1 {
    font-size: 1.125rem !important
}

.fs-large-2 {
    font-size: 1.25rem !important
}

.fs-large-3 {
    font-size: 1.375rem !important
}

.fs-large-4 {
    font-size: 1.5rem !important
}

.fs-large-5 {
    font-size: 1.625rem !important
}

.fs-large-6 {
    font-size: 1.75rem !important
}

.fs-large-7 {
    font-size: 1.875rem !important
}

.fs-large-8 {
    font-size: 2rem !important
}

.fs-large-9 {
    font-size: 2.125rem !important
}

.border-1x {
    border-width: 1px !important
}

.border-2x {
    border-width: 2px !important
}

.border-3x {
    border-width: 3px !important
}

.border-4x {
    border-width: 4px !important
}

.border-5x {
    border-width: 5px !important
}

.border-6x {
    border-width: 6px !important
}

.text-lighter {
    color: #dadada !important
}

.text-gray-lighter {
    color: #b3b3b3 !important
}

.text-gray-light {
    color: #9b9b9b !important
}

.text-gray {
    color: #7e7e7e !important
}

.text-heading {
    color: #333 !important
}

.text-border {
    color: #eee !important
}

.text-landing {
    color: #656565 !important
}

.text-active-secondary.active,
.text-active-secondary:hover.active,
.text-active-secondary:focus.active {
    color: #1e1d85 !important
}

.text-active-white.active,
.text-active-white:hover.active,
.text-active-white:focus.active {
    color: #fff !important
}

button.text-btn-focus-secondary:focus {
    color: #1e1d85 !important
}

.bg-active-primary.active,
.bg-active-primary:focus.active {
    background-color: var(--primary) !important
}

.btn-active-primary.active {
    background-color: var(--primary) !important
}

.placeholder-light::-webkit-input-placeholder {
    color: #fff !important;
    opacity: .7
}

.placeholder-light::-moz-placeholder {
    color: #fff !important;
    opacity: .7
}

.placeholder-light:-ms-input-placeholder {
    color: #fff !important;
    opacity: .7
}

.placeholder-light::-ms-input-placeholder {
    color: #fff !important;
    opacity: .7
}

.placeholder-light::placeholder {
    color: #fff !important;
    opacity: .7
}

.placeholder-muted::-webkit-input-placeholder {
    color: #ababab !important
}

.placeholder-muted::-moz-placeholder {
    color: #ababab !important
}

.placeholder-muted:-ms-input-placeholder {
    color: #ababab !important
}

.placeholder-muted::-ms-input-placeholder {
    color: #ababab !important
}

.placeholder-muted::placeholder {
    color: #ababab !important
}

.placeholder-body::-webkit-input-placeholder {
    color: #696969 !important
}

.placeholder-body::-moz-placeholder {
    color: #696969 !important
}

.placeholder-body:-ms-input-placeholder {
    color: #696969 !important
}

.placeholder-body::-ms-input-placeholder {
    color: #696969 !important
}

.placeholder-body::placeholder {
    color: #696969 !important
}

.bg-gray-01 {
    background-color: #f8f8f8 !important
}

.bg-gray-02 {
    background-color: #fafafa !important
}

.bg-gray-03 {
    background-color: #f5f5f5 !important
}

.bg-gray-04 {
    background-color: #eee !important
}

.bg-input {
    background-color: #f8f8f8 !important
}

.bg-dark-opacity-04 {
    background-color: rgba(0, 0, 0, .4) !important
}

.bg-dark-opacity-06 {
    background-color: rgba(0, 0, 0, .6) !important
}

.bg-dark-opacity-05 {
    background-color: rgba(0, 0, 0, .5)
}

.bg-dark-opacity-03 {
    background-color: rgba(0, 0, 0, .3) !important
}

.border-white-opacity-03 {
    border-color: rgba(255, 255, 255, .3) !important
}

.bg-white-opacity-01 {
    background-color: rgba(255, 255, 255, .1) !important
}

.bg-white-hover-opacity-03:hover {
    background-color: rgba(255, 255, 255, .3) !important
}

.border-input-opacity-02 {
    border-color: rgba(237, 237, 237, .2) !important
}

.border-opacity-025 {
    border-color: rgba(237, 237, 237, .25) !important
}

.opacity-005 {
    opacity: .05
}

.opacity-42 {
    opacity: .42
}

.rounded-15 {
    border-radius: 15px
}

.border-color-input {
    border-color: #ededed !important
}

.rounded-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}

.rounded-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.rounded-top-right {
    border-top-right-radius: .25rem !important
}

.rounded-bottom-right-lg {
    border-bottom-left-radius: 5px !important
}

.bg-overlay {
    position: relative
}

.bg-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .47);
    z-index: 1;
    border-radius: inherit
}

.bg-overlay-secondary {
    position: relative
}

.bg-overlay-secondary::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1e1d85;
    opacity: .34;
    z-index: 1;
    border-radius: inherit
}

.bg-overlay-opacity-44:before {
    opacity: .44
}

.bg-overlay-opacity-74:before {
    opacity: .74
}

.bg-active-white.active,
.button.bg-active-white:hover,
button.bg-active-white:focus {
    background-color: #fff !important
}

.bg-hover-overlay {
    position: relative
}

.bg-hover-overlay::before {
    transition: all .3s;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: inherit
}

.bg-hover-overlay:hover::before {
    background-color: rgba(0, 0, 0, .4)
}

.bg-hover-overlay-white {
    position: relative
}

.bg-hover-overlay-white::before {
    transition: all .3s;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: inherit
}

.bg-hover-overlay-white:hover::before {
    background-color: rgba(255, 255, 255, .4)
}

.bg-img-cover-center {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.overflow-x-auto {
    overflow-x: auto !important
}

.overflow-y-auto {
    overflow-y: auto !important
}

.font-weight-500 {
    font-weight: 500 !important
}

.font-weight-600 {
    font-weight: 600 !important
}


.list-group-no-border .list-group-item-active {
    border: none !important;
    background-color:#1c4d6b !important;
    color:white !important;
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    background-color: #fff;
    
}

.icon-primary:before,
.icon-primary:after {
    color: var(--primary) !important
}

.icon-secondary:before,
.icon-secondary:after {
    color: #1e1d85 !important
}

.pointer {
    cursor: pointer
}

.hover-primary:hover {
    color: var(--primary) !important
}

.hover-secondary:hover {
    color: #1e1d85 !important
}

.hover-success:hover {
    color: #28a745 !important
}

.hover-info:hover {
    color: #17a2b8 !important
}

.hover-warning:hover {
    color: #f6b500 !important
}

.hover-danger:hover {
    color: #dc3545 !important
}

.hover-light:hover {
    color: #f8f9fa !important
}

.hover-dark:hover {
    color: #252525 !important
}

.hover-accent:hover {
    color: #eff6f7 !important
}

.hover-orange:hover {
    color: #ff6935 !important
}

.hover-white:hover {
    color: #fff !important
}

.hover-indigo:hover {
    color: #6969eb !important
}

a.hover-primary:hover {
    color: var(--primary) !important
}

a.hover-primary:focus {
    color: var(--primary) !important
}

a.hover-secondary:hover {
    color: #1e1d85 !important
}

a.hover-secondary:focus {
    color: #1e1d85 !important
}

a.hover-success:hover {
    color: #28a745 !important
}

a.hover-success:focus {
    color: #28a745 !important
}

a.hover-info:hover {
    color: #17a2b8 !important
}

a.hover-info:focus {
    color: #17a2b8 !important
}

a.hover-warning:hover {
    color: #f6b500 !important
}

a.hover-warning:focus {
    color: #f6b500 !important
}

a.hover-danger:hover {
    color: #dc3545 !important
}

a.hover-danger:focus {
    color: #dc3545 !important
}

a.hover-light:hover {
    color: #f8f9fa !important
}

a.hover-light:focus {
    color: #f8f9fa !important
}

a.hover-dark:hover {
    color: #252525 !important
}

a.hover-dark:focus {
    color: #252525 !important
}

a.hover-accent:hover {
    color: #eff6f7 !important
}

a.hover-accent:focus {
    color: #eff6f7 !important
}

a.hover-orange:hover {
    color: #ff6935 !important
}

a.hover-orange:focus {
    color: #ff6935 !important
}

a.hover-white:hover {
    color: #fff !important
}

a.hover-white:focus {
    color: #fff !important
}

a.hover-indigo:hover {
    color: #6969eb !important
}

a.hover-indigo:focus {
    color: #6969eb !important
}

.bg-hover-primary:hover {
    background-color: var(--primary) !important
}

.bg-hover-secondary:hover {
    background-color: #1e1d85 !important
}

.bg-hover-success:hover {
    background-color: #28a745 !important
}

.bg-hover-info:hover {
    background-color: #17a2b8 !important
}

.bg-hover-warning:hover {
    background-color: #f6b500 !important
}

.bg-hover-danger:hover {
    background-color: #dc3545 !important
}

.bg-hover-light:hover {
    background-color: #f8f9fa !important
}

.bg-hover-dark:hover {
    background-color: #252525 !important
}

.bg-hover-accent:hover {
    background-color: #eff6f7 !important
}

.bg-hover-orange:hover {
    background-color: #ff6935 !important
}

.bg-hover-white:hover {
    background-color: #fff !important
}

.bg-hover-indigo:hover {
    background-color: #6969eb !important
}

a.bg-hover-primary:hover {
    background-color: var(--primary) !important
}

a.bg-hover-primary:focus {
    background-color: var(--primary) !important
}

a.bg-hover-secondary:hover {
    background-color: #1e1d85 !important
}

a.bg-hover-secondary:focus {
    background-color: #1e1d85 !important
}

a.bg-hover-success:hover {
    background-color: #28a745 !important
}

a.bg-hover-success:focus {
    background-color: #28a745 !important
}

a.bg-hover-info:hover {
    background-color: #17a2b8 !important
}

a.bg-hover-info:focus {
    background-color: #17a2b8 !important
}

a.bg-hover-warning:hover {
    background-color: #f6b500 !important
}

a.bg-hover-warning:focus {
    background-color: #f6b500 !important
}

a.bg-hover-danger:hover {
    background-color: #dc3545 !important
}

a.bg-hover-danger:focus {
    background-color: #dc3545 !important
}

a.bg-hover-light:hover {
    background-color: #f8f9fa !important
}

a.bg-hover-light:focus {
    background-color: #f8f9fa !important
}

a.bg-hover-dark:hover {
    background-color: #252525 !important
}

a.bg-hover-dark:focus {
    background-color: #252525 !important
}

a.bg-hover-accent:hover {
    background-color: #eff6f7 !important
}

a.bg-hover-accent:focus {
    background-color: #eff6f7 !important
}

a.bg-hover-orange:hover {
    background-color: #ff6935 !important
}

a.bg-hover-orange:focus {
    background-color: #ff6935 !important
}

a.bg-hover-white:hover {
    background-color: #fff !important
}

a.bg-hover-white:focus {
    background-color: #fff !important
}

a.bg-hover-indigo:hover {
    background-color: #6969eb !important
}

a.bg-hover-indigo:focus {
    background-color: #6969eb !important
}

.border-primary {
    border-color: var(--primary) !important
}

.border-secondary {
    border-color: #1e1d85 !important
}

.border-success {
    border-color: #28a745 !important
}

.border-info {
    border-color: #17a2b8 !important
}

.border-warning {
    border-color: #f6b500 !important
}

.border-danger {
    border-color: #dc3545 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #252525 !important
}

.border-accent {
    border-color: #eff6f7 !important
}

.border-orange {
    border-color: #ff6935 !important
}

.border-white {
    border-color: #fff !important
}

.border-indigo {
    border-color: #6969eb !important
}

.border-hover-primary:hover {
    border-color: var(--primary) !important
}

.border-hover-secondary:hover {
    border-color: #1e1d85 !important
}

.border-hover-success:hover {
    border-color: #28a745 !important
}

.border-hover-info:hover {
    border-color: #17a2b8 !important
}

.border-hover-warning:hover {
    border-color: #f6b500 !important
}

.border-hover-danger:hover {
    border-color: #dc3545 !important
}

.border-hover-light:hover {
    border-color: #f8f9fa !important
}

.border-hover-dark:hover {
    border-color: #252525 !important
}

.border-hover-accent:hover {
    border-color: #eff6f7 !important
}

.border-hover-orange:hover {
    border-color: #ff6935 !important
}

.border-hover-white:hover {
    border-color: #fff !important
}

.border-hover-indigo:hover {
    border-color: #6969eb !important
}

@media screen and (max-width:1366px) and (min-width:1200px) {
    .custom-col-3-xl-to-xxl {
        max-width: 25% !important;
        -ms-flex: 0 0 25% !important;
        flex: 0 0 25% !important
    }

    .custom-col-5-xl-to-xxl {
        -ms-flex: 0 0 41.66667% !important;
        flex: 0 0 41.66667% !important;
        max-width: 41.66667% !important
    }

    .custom-col-7-xl-to-xxl {
        -ms-flex: 0 0 58.33333% !important;
        flex: 0 0 58.33333% !important;
        max-width: 58.33333% !important
    }
}

@media(min-width:1200px) {
    .w-xl-200 {
        width: 200px !important
    }

    .xl-vh-100 {
        height: 100vh !important
    }
}

@media(min-width:992px) {
    .h-lg-100 {
        height: 100px !important
    }

    .w-lg-200 {
        width: 200px !important
    }

    .h-lg-80 {
        height: 80px !important
    }

    .rounded-lg-top-left {
        border-top-left-radius: .25rem !important
    }

    .custom-lg-map-height-full {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0
    }

    .h-lg-full {
        height: 100% !important
    }

    .opacity-lg-0 {
        opacity: 0 !important
    }

    .top-lg-n50px {
        top: -50px
    }

    .position-lg-absolute {
        position: absolute !important
    }
}

@media(min-width:576px) {
    .w-sm-90 {
        width: 90px !important
    }

    .w-sm-170 {
        width: 170px !important
    }
}

.border-hover-none:hover {
    border: none !important
}

.border-hover {
    border-color: transparent !important
}

.border-hover:hover {
    border-color: #eee !important
}

@media screen and (min-width:1600px) {
    .container-xxl {
        max-width: 1560px
    }
}

.min-w-unset {
    min-width: unset !important
}

.mxw-180 {
    max-width: 180px
}

.mxw-243 {
    max-width: 243px
}

.mxw-253 {
    max-width: 253px
}

.mxw-411 {
    max-width: 411px
}

.mxw-470 {
    max-width: 470px
}

.mxw-751 {
    max-width: 751px;
    margin: 0 auto
}

.mxw-774 {
    max-width: 774px;
    margin: 0 auto
}

.mxw-571 {
    max-width: 571px;
    margin: 0 auto
}

.mxw-589 {
    max-width: 589px;
    margin: 0 auto
}

.mxw-630 {
    max-width: 670px;
    margin: 0 auto
}

.mxw-670 {
    max-width: 670px;
    margin: 0 auto
}

.mxw-740 {
    max-width: 740px;
    margin: 0 auto
}

.mxw-940 {
    max-width: 940px;
    margin: 0 auto
}

.mxw-478 {
    max-width: 478px;
    margin: 0 auto
}

.mxw-521 {
    max-width: 510px;
    margin: 0 auto
}

.mxw-504 {
    max-width: 510px;
    margin: 0 auto
}

.mxw-545 {
    max-width: 545px
}

.w-18px {
    width: 18px !important
}

.w-24px {
    width: 24px !important
}

.h-2 {
    height: 2px !important
}

.h-18 {
    height: 18px !important
}

.h-24 {
    height: 24px !important
}

.w-25 {
    width: 25% !important
}

.w-28px {
    width: 28px !important
}

.h-28 {
    height: 28px !important
}

.w-30px {
    width: 30px !important
}

.h-30 {
    height: 30px !important
}

.w-32px {
    width: 32px !important
}

.h-32 {
    height: 32px !important
}

.w-40px {
    width: 40px !important
}

.h-40 {
    height: 40px !important
}

.w-46px {
    width: 46px !important
}

.h-46 {
    height: 46px !important
}

.w-48px {
    width: 48px !important
}

.w-57px {
    width: 57px !important
}

.h-48 {
    height: 48px !important
}

.w-52px {
    width: 52px !important
}

.h-52 {
    height: 52px !important
}

.w-60px {
    width: 60px !important
}

.h-60 {
    height: 60px !important
}

.h-64 {
    height: 64px !important
}

.w-64px {
    width: 64px !important
}

.w-70px {
    width: 70px !important
}

.h-70 {
    height: 70px !important
}

.w-78px {
    width: 78px !important
}

.h-78 {
    height: 78px !important
}

.w-82px {
    width: 82px !important
}

.h-82 {
    height: 82px !important
}

.w-83px {
    width: 83px !important
}

.h-83 {
    height: 83px !important
}

.w-85px {
    width: 85px !important
}

.h-85 {
    height: 85px !important
}

.h-90 {
    height: 90px !important
}

.w-96px {
    width: 96px !important
}

.w-100px {
    width: 100px !important
}

.w-120px {
    width: 120px !important
}

.w-110px {
    width: 120px !important
}

.h-120 {
    height: 120px !important
}

.w-140px {
    width: 140px !important
}

.h-140 {
    height: 140px !important
}

.h-145 {
    height: 145px !important
}

.w-160px {
    width: 160px !important
}

.h-230 {
    height: 230px !important
}

.h-250 {
    height: 250px !important
}

.pos-fixed-top {
    top: 0;
    left: 0
}

.flex-basis-102px {
    -ms-flex: 0 0 102px;
    flex: 0 0 102px
}

@media(min-width:768px) {
    .flex-md-1 {
        -ms-flex: 1 !important;
        flex: 1 !important
    }

    .flex-md-3 {
        -ms-flex: 3 !important;
        flex: 3 !important
    }

    .rounded-right-md-0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important
    }

    .rounded-left-md-0 {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important
    }

    .rounded-md-top-left-0 {
        border-top-left-radius: 0 !important
    }

    .w-md-200 {
        width: 200px !important
    }
}

@media(min-width:576px) {
    .mh-sm-243 {
        min-height: 243px !important
    }
}

.border-radius-10 {
    border-radius: 10px
}

.pos-fixed-top-center {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.pos-fixed-top-right {
    top: 0;
    left: auto;
    right: 0
}

.pos-fixed-center-right {
    top: 50%;
    left: auto;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.pos-fixed-bottom {
    bottom: 0;
    left: 0
}

.pos-fixed-bottom-right {
    bottom: 0;
    right: 0
}

.pos-fixed-center {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.pos-fixed-left-center {
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.pos-fixed-right-center {
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.bg-patten-01 {
    background-image: url("/public/images/pattern-01.png"), url("/public/images/pattern-02.png");
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto
}

.bg-patten-02 {
    background-image: url("/public/images/pattern-03.png"), url("/public/images/pattern-04.png");
    background-position: left bottom, right top;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto
}

.bg-patten-03 {
    background-image: url("/public/images/pattern-05.png"), url("/public/images/pattern-06.png");
    background-position: left bottom, right top;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto
}

.bg-patten-04 {
    background-image: url("/public/images/pattern-07.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: auto
}

.bg-patten-05 {
    background-image: url("/public/images/pattern-08.png"), url("/public/images/pattern-09.png");
    background-position: right top, left bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto
}

.hover-to-top {
    transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
    transition: transform .4s ease-in-out, opacity .4s ease-in-out;
    transition: transform .4s ease-in-out, opacity .4s ease-in-out, -webkit-transform .4s ease-in-out
}

.hover-to-top:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
}

.hover-change-image .hover-image,
.hover-change-image .image {
    transition: all .3s
}

.hover-change-image .hover-image {
    opacity: 0
}

.hover-change-image:hover .image {
    opacity: 0 !important
}

.hover-change-image:hover .hover-image {
    opacity: 1 !important
}

.play-animation:before,
.play-animation:after {
    -webkit-animation: play-animation 1600ms ease-in-out 0ms;
    animation: play-animation 1600ms ease-in-out 0ms;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    width: 100%;
    height: 100%;
    content: "";
    right: 0;
    top: 0;
    position: absolute;
    border-radius: 50%;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
}

.play-animation:after {
    -webkit-animation-delay: 800ms;
    animation-delay: 800ms
}

@-webkit-keyframes play-animation {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        box-shadow: 0 0 0 0 #fff;
        opacity: .6
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        box-shadow: 0 0 0 4px #fff;
        opacity: .3
    }

    100% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        box-shadow: 0 0 0 8px #fff;
        opacity: 0
    }
}

@keyframes play-animation {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        box-shadow: 0 0 0 0 #fff;
        opacity: .6
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        box-shadow: 0 0 0 4px #fff;
        opacity: .3
    }

    100% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        box-shadow: 0 0 0 8px #fff;
        opacity: 0
    }
}

.hover-zoom-in {
    overflow: hidden
}

.hover-zoom-in img,
.hover-zoom-in .img,
.hover-zoom-in .card-img {
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: 1s ease-in-out;
    width: 100%
}

.hover-zoom-in:hover img,
.hover-zoom-in:hover .img,
.hover-zoom-in:hover .card-img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
}

.hover-opacity {
    overflow: hidden
}

.hover-opacity img,
.hover-opacity .img,
.hover-opacity .card-img {
    opacity: 1;
    transition: 1s ease-in-out
}

.hover-opacity:hover img,
.hover-opacity:hover .img,
.hover-opacity:hover .card-img {
    opacity: .5
}

.hover-shine {
    position: relative;
    overflow: hidden
}

.hover-shine::before {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg)
}

.hover-shine:hover::before {
    -webkit-animation: shine 1s;
    animation: shine 1s
}

.custom-packages {
    position: absolute;
    right: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 18px
}

.custom-packages:before {
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 50px solid var(--primary);
    content: '';
    position: absolute;
    z-index: -1;
    top: -28px;
    right: -21px;
    left: auto
}

.rounded-top-33 {
    border-top-left-radius: 33px !important;
    border-top-right-radius: 33px !important
}

.rounded-bottom-33 {
    border-bottom-right-radius: 33px !important;
    border-bottom-left-radius: 33px !important
}

@-webkit-keyframes shine {
    100% {
        left: 125%
    }
}

@keyframes shine {
    100% {
        left: 125%
    }
}

.hover-flash:hover img,
.hover-flash:hover .card-img,
.hover-flash:hover .img {
    opacity: 1;
    -webkit-animation: g5-flash 1.5s;
    animation: g5-flash 1.5s
}

@-webkit-keyframes g5-flash {
    0% {
        opacity: .4
    }

    100% {
        opacity: 1
    }
}

@keyframes g5-flash {
    0% {
        opacity: .4
    }

    100% {
        opacity: 1
    }
}

.custom-pos-icon {
    top: -32px;
    left: -32px
}

.pt-75 {
    padding-top: 75% !important
}

@media(max-width:767px) {
    .custom-pos-icon {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    .display-1,
    .display-2 {
        font-size: 2.5rem
    }
}

@media(max-width:991px) {
    .custom-lg-size-1-1:after {
        content: '';
        display: block;
        padding-top: 100%
    }
}

@media(max-width:575px) {
    .w-575-auto {
        width: auto !important
    }
}

@media(min-width:992px) {
    .cusstom-bg-slider-gray:before {
        content: '';
        position: absolute;
        background-color: #f3f6fa;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        max-width: 770px;
        margin-left: auto;
        width: 100%
    }
}

.bg-cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}

@media(min-width:768px) {
    .arrow-box {
        position: relative
    }

    .arrow-box:before {
        position: absolute;
        right: 100%;
        top: 50%;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent #fff transparent transparent;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 2
    }

    .arrow-box.arrow-box.arrow-right:before {
        left: 100%;
        right: auto;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent #fff
    }
}

.overlay-opacity-02:before {
    background-color: rgba(0, 0, 0, .2)
}

@media(max-width:575px) {
    .position-relative-sm {
        position: relative
    }
}

.custom-switch-right.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    content: '\f00c';
    font-family: "font awesome 5 pro";
    line-height: 18px;
    font-size: 13px;
    text-align: center;
    color: var(--primary);
    right: 0;
    left: unset
}

.custom-switch-right.custom-switch .custom-control-input~.custom-control-label::before,
.custom-switch-right.custom-switch .custom-control-input~.custom-control-label::after {
    left: -1.359375rem
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff
}

.custom-switch .custom-control-label::after {
    background-color: var(--primary)
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url(https://png.pngtree.com/png-vector/20190909/ourmid/pngtree-cecklist-icon-isolated-png-image_1727901.jpg);
    content: '\f00c';
    font-family: "font awesome 5 pro";
    top: .01rem;
    left: -1.75rem;
    color: #fff
}

.custom-checkbox .custom-control-label {
    cursor: pointer
}

.form-control::-webkit-input-placeholder {
    color: #ababab
}

.form-control::-moz-placeholder {
    color: #ababab
}

.form-control:-ms-input-placeholder {
    color: #ababab
}

.form-control::-ms-input-placeholder {
    color: #ababab
}

.form-control::placeholder {
    color: #ababab
}

@media print {

    .db-sidebar,
    .invoice-preview-button,
    .main-header {
        display: none !important
    }
}

a {
    transition: all .2s
}

a:hover {
    text-decoration: none
}

img {
    max-width: 100%;
    height: auto
}

svg.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor
}

button:focus {
    outline: none
}

.map-info {
    max-width: 370px;
    bottom: 72px
}

/* [data-animate] {
    opacity: 0
} */

[data-animate].animated {
    opacity: 1
}

.custom-input-group .form-control,
.custom-input-group .btn {
    height: 62px;
    line-height: 20px
}

.list-layout .active {
    color: #252525 !important
}

.rating-progress {
    height: 4px;
    background-color: #ededed
}

.rate-input {
    direction: rtl
}

.rate-input input {
    display: none
}

.rate-input label {
    margin-left: -2px;
    margin-right: -2px;
    color: #eee;
    font-size: 20px
}

.rate-input label:hover,
.rate-input label:hover~label {
    color: #f6b500
}

.rate-input input:checked~label {
    color: #f6b500
}

.rate-input:hover>input:checked~label {
    color: #f6b500
}

.gtf-back-to-top {
    opacity: 0;
    transition: opacity .5s
}

.gtf-back-to-top.in {
    opacity: 1
}

.custom-img-ft-landing {
    top: -35px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.landing-banner {
    background-image: url("/public/images/bg-banner-landing-02.png"), url("/public/images/bg-banner-landing-01.png");
    background-position: left bottom, right top;
    background-repeat: no-repeat
}

@media(max-width:1199.98px) {
    .landing-banner {
        height: calc(100vh - 72px) !important
    }
}

@media(max-width:991.98px) {
    .landing-banner .display-3 {
        font-size: 30px
    }
}

.landing-title-light {
    color: #eaeff6
}

.bg-landing-featur-01 {
    background-image: url("/public/images/bg-landing-feature.png");
    background-color: #e9edf2;
    background-position: center;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto
}

@media(min-width:1200px) {
    .powerful-dashboard-img {
        position: absolute;
        top: -100px;
        left: -50px;
        max-width: 450px
    }
}

@media(min-width:1600px) {
    .powerful-dashboard-img {
        position: absolute;
        top: -200px;
        left: -150px;
        max-width: 520px
    }
}

@media(min-width:1200px) {
    .lading-dashboard-img {
        position: relative;
        right: -9%
    }
}